<template>
  <section class="pricing-plane-sec">
    <div class="container">
      <div class="section-head-fourteen">
        <h2>Pricing <span> Plan </span></h2>
        <p>No Extra charges, No Surprise Fees</p>
      </div>
      <div class="price-sec-main">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="price-plane-card">
              <div class="price-head">
                <h5>For individuals</h5>
                <h4>Free</h4>
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.
                </p>
                <h3>$99 <span> /monthly</span></h3>
              </div>
              <div class="plane-includes">
                <h5>What’s included</h5>
                <ul>
                  <li><i class="fa-solid fa-circle-check"></i>Emergency support</li>
                  <li>
                    <i class="fa-solid fa-circle-check"></i>In-person care manager visits
                  </li>
                  <li><i class="fa-solid fa-circle-check"></i>Healthcare support</li>
                  <li><i class="fa-solid fa-circle-check"></i>Home Care (as needed)</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Safety & Security</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Engagement assistance</li>
                  <li>
                    <i class="fa-solid fa-circle-xmark"></i>Physiotherapy/ counselor
                  </li>
                </ul>
              </div>
              <div class="plane-btn">
                <router-link to="login-email" class="btn btn-primary w-100"
                  >Choose Plans</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="price-plane-card active">
              <div class="price-head">
                <h5>For 2 Persons</h5>
                <h4>Basic</h4>
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.
                </p>
                <h3>$120 <span> /monthly</span></h3>
              </div>
              <div class="plane-includes">
                <h5>What’s included</h5>
                <ul>
                  <li><i class="fa-solid fa-circle-check"></i>Emergency support</li>
                  <li>
                    <i class="fa-solid fa-circle-check"></i>In-person care manager visits
                  </li>
                  <li><i class="fa-solid fa-circle-check"></i>Healthcare support</li>
                  <li><i class="fa-solid fa-circle-check"></i>Home Care (as needed)</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Safety & Security</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Engagement assistance</li>
                  <li>
                    <i class="fa-solid fa-circle-xmark"></i>Physiotherapy/ counselor
                  </li>
                </ul>
              </div>
              <div class="plane-btn">
                <router-link to="login-email" class="btn btn-primary w-100"
                  >Choose Plans</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="price-plane-card">
              <div class="price-head">
                <h5>For individuals</h5>
                <h4>Premium</h4>
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.
                </p>
                <h3>$199 <span> /monthly</span></h3>
              </div>
              <div class="plane-includes">
                <h5>What’s included</h5>
                <ul>
                  <li><i class="fa-solid fa-circle-check"></i>Emergency support</li>
                  <li>
                    <i class="fa-solid fa-circle-check"></i>In-person care manager visits
                  </li>
                  <li><i class="fa-solid fa-circle-check"></i>Healthcare support</li>
                  <li><i class="fa-solid fa-circle-check"></i>Home Care (as needed)</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Safety & Security</li>
                  <li><i class="fa-solid fa-circle-xmark"></i>Engagement assistance</li>
                  <li>
                    <i class="fa-solid fa-circle-xmark"></i>Physiotherapy/ counselor
                  </li>
                </ul>
              </div>
              <div class="plane-btn">
                <router-link to="login-email" class="btn btn-primary w-100"
                  >Choose Plans</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
