<template>
  <div class="main-wrapper home-ten">
      <!-- <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" /> -->
      <!-- <breadcrumb :title="title" :text="text" :text1="text1" /> -->
      <div class="page-wrapper">
          <section class="banner-section" style="padding: 0;">
              <div class="banner-slider" style="max-height: 90vh;">
                  <img
                      src="@/assets/img/phuongthanh/banner-news.jpg" alt="Image" style="width: 100%;"
                  />
              </div>
          </section>
          <div class="container" style="padding-top: 30px ;">
              <div class="row intro-nhakhoa">
                  <div class="col-lg-12 col-md-12 item-intro">
                      <div style="padding: 0 20px;">
                          <p class="decs-intro font-regular text-center">
                              Hiện nay có nhiều hãng khay niềng răng trong suốt gia nhập thị trường giúp khách hàng dễ dàng tiếp cận giải pháp làm đẹp nụ cười, cải thiện sức khỏe răng miệng. Tuy nhiên trong số đó, đâu là lựa chọn an toàn, tối ưu và phù hợp? Đừng lo lắng, Trung tâm nha khoa Phương Thành sẽ cung cấp cho bạn những thông tin hữu ích về phương pháp niềng răng trong suốt thông minh, chất lượng và an toàn. 
                          </p>
                      </div>
                  </div>
              </div>
          </div>

          <section class="section-define">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-7">
                          <div class="box-content">
                              <div class="box-heading">
                                  <h2 style="text-align: justify">
                                      Cơ Chế Hoạt Động Của Niềng Răng Trong Suốt
                                  </h2>
                                  <p style="text-align: justify"><span style="font-weight: 400">Niềng răng trong suốt là phương pháp sử dụng một chuỗi các khay niềng răng trong suốt được làm từ chất liệu nhựa sinh học đặc biệt, đảm bảo an toàn.</span></p>
                                  <p style="text-align: justify"><span style="font-weight: 400">Khay niềng trong suốt được thiết kế cá nhân hóa theo khuôn răng của khách hàng và hoạt động dựa trên cơ chế tác dụng lực để dịch chuyển răng theo kế hoạch của bác sĩ chỉnh nha. Thông thường, bệnh nhân cần mang từ 20-48 khay tùy vào mức độ sai lệch. Theo đó, mỗi khay được mang trong khoảng 7-10 ngày và tối thiểu 20-22 giờ/ngày để dịch chuyển răng về đúng vị trí mong muốn trên cung hàm.</span></p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-5">
                          <div class="box-image">
                              <noscript>
                                  <img width="600" height="486" src="@/assets/img/phuongthanh/dichvu01.jpg" alt="">
                              </noscript>
                              <img class=" ls-is-cached lazyloaded" width="600" height="486" src="@/assets/img/phuongthanh/dichvu01.jpg" alt="">
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section class="section-who">
              <div class="container">
                  <div class="row align-items-center">
                      <div class="col-lg-6">
                          <div class="box-image">
                              <noscript>
                                  <img width="518" height="478" src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="">
                              </noscript>
                              <img class=" ls-is-cached lazyloaded" width="518" height="478" src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="">
                          </div>
                      </div>
                      <div class="col-lg-6">
                          <div class="box-content">
                              <div class="box-heading">
                                  <h1>Những Ai Nên Niềng Răng Trong Suốt?</h1>
                                  <p>
                                      <span style="font-weight: 400">
                                          Niềng răng trong suốt phù hợp với cả trẻ em và người lớn,
                                          đặc biệt có thể giúp điều trị các trường hợp sau đây: 
                                      </span>
                                  </p>
                              </div>
                              <div class="list-item">
                                  <div class="box-item">
                                      <div class="box-icon">
                                          <noscript>
                                              <img width="37" height="46" alt="">
                                          </noscript>
                                          <i class="fa-solid fa-check icon-check"></i>
                                          <!-- <img class=" ls-is-cached lazyloaded" width="37" height="46" alt=""> -->
                                      </div>
                                      <div class="box-item-content">
                                          <p style="text-align: justify">
                                              <span style="font-weight: 400">
                                                  Người đang gặp tình trạng hô, móm, lệch lạc, sai khớp cắn,… mong muốn có được khớp cắn chuẩn và hàm răng đều đặn, cải thiện thẩm mỹ gương mặt.
                                              </span>
                                          </p>
                                      </div>
                                  </div>
                                  <div class="box-item">
                                      <div class="box-icon">
                                          <noscript>
                                              <img width="37" height="46"alt="">
                                          </noscript>
                                          <i class="fa-solid fa-check icon-check"></i>
                                      </div>
                                      <div class="box-item-content">
                                          <p><span style="font-weight: 400">
                                              Người cần chỉnh nha nhưng có bệnh lý đặc biệt, bị dị ứng với kim loại.
                                          </span></p>
                                      </div>
                                  </div>
                                  <div class="box-item">
                                      <div class="box-icon">
                                          <noscript><img width="37" height="46" alt=""></noscript>
                                          <i class="fa-solid fa-check icon-check"></i>
                                      </div>
                                      <div class="box-item-content">
                                          <p style="text-align: justify">
                                              <span style="font-weight: 400">
                                                  Người có yêu cầu thẩm mỹ cao, cần giao tiếp nhiều trong 
                                                  quá trình niềng răng.
                                              </span>
                                          </p>
                                      </div>
                                  </div>
                                  <div class="box-item">
                                      <div class="box-icon">
                                          <noscript>
                                              <img width="37" height="46" alt="">
                                          </noscript>
                                          <i class="fa-solid fa-check icon-check"></i>
                                      </div>
                                      <div class="box-item-content">
                                          <p>
                                              <span style="font-weight: 400">
                                                  Người có công việc phải dịch chuyển thường xuyên, không có nhiều thời gian thăm khám.
                                              </span>
                                          </p>
                                      </div>
                                  </div>
                                  <div class="box-item">
                                      <div class="box-icon">
                                          <noscript>
                                              <img width="37" height="46" alt="">
                                          </noscript>
                                          <i class="fa-solid fa-check icon-check"></i>
                                      </div>
                                      <div class="box-item-content">
                                          <p style="text-align: justify">
                                              <span style="font-weight: 400">
                                                  Các trường hợp khác theo chỉ định bác sĩ chỉnh nha.
                                              </span>
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <!-- <section class="faq-section faq-section-ten">
              <div class="container">
                  <div class="row align-items-center">
                  <div class="col-md-12 text-center aos mb-2" data-aos="fade-up">
                      <div class="section-header-one text-center">
                      <h2 class="section-title">
                          <span style="font-family: 'Nunito Bold';">Lợi Ích Của Khay Niềng Trong Suốt</span>
                      </h2>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-12 aos" data-aos="fade-up">
                      <div class="faq-img">
                      <img src="@/assets/img/phuongthanh/core/home.jpg" class="img-fluid" alt="img" />
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                      <div class="faq-info aos" data-aos="fade-up">
                      <div class="accordion" id="faq-details">
                          <div class="accordion-item" v-for="item in IndexfiveFaq" :key="item.id">
                          <h2 class="accordion-header" :id="item.faqid">
                              <a
                              href="javascript:void(0);"
                              :class="item.accordion"
                              data-bs-toggle="collapse"
                              :data-bs-target="item.target"
                              :aria-expanded="item.expanded"
                              :aria-controls="item.targetid"
                              style="font-family: 'Nunito Medium'"
                              >
                              {{ item.question }}
                              </a>
                          </h2>
                          <div
                              :id="item.targetid"
                              :class="item.class"
                              :aria-labelledby="item.faqid"
                              data-bs-parent="#faq-details"
                          >
                              <div class="accordion-body">
                              <div class="accordion-content">
                                  <p style="font-family: 'Nunito Regular'">{{ item.answer }}</p>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
          </section> -->

          <section class="faq-section faq-section-ten" style="margin-bottom: 30px;">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-12 col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                          <div class="section-header-one aos aos-init aos-animate text-center" data-aos="fade-up">
                              <h2 class="section-title mb-0" style="font-family: 'Nunito Bold'; color: #d4a04c;">Câu hỏi thường gặp?</h2>
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-12 aos aos-init aos-animate" data-aos="fade-up" style="height: 600px">
                          <div class="faq-img">
                              <img src="@/assets/img/phuongthanh/doctor/BS_Thanh-removebg.png" class="img-fluid" alt="img">
                              <div class="faq-user">
                                  <div class="user-info">
                                      <span>
                                          <img src="@/assets/img/phuongthanh/avatar01.jpeg" class="img-fluid" alt="img">
                                      </span>
                                      <div class="flex-1">
                                          <p>Tiến sĩ, Bác sĩ</p>
                                          <h6>Phạm Kim Thành</h6>
                                      </div>
                                  </div>
                                  <div class="rating">
                                      <i class="fa-solid fa-star filled"></i>
                                      <i class="fa-solid fa-star filled"></i>
                                      <i class="fa-solid fa-star filled"></i>
                                      <i class="fa-solid fa-star filled"></i>
                                      <i class="fa-solid fa-star filled"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                          <div class="faq-info aos aos-init aos-animate" data-aos="fade-up">
                              <div class="accordion" id="faq-details">

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingThree">
                                          <a href="javascript:void(0);" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faqone" aria-expanded="false" aria-controls="faqone">
                                              Tôi có thể đặt lịch hẹn trực tuyến với Nha khoa Phương Thành không?
                                          </a>
                                      </h2>
                                      <div id="faqone" class="accordion-collapse collapse" data-bs-parent="#faq-details">
                                          <div class="accordion-body">
                                              <div class="accordion-content">
                                                  <p>Quý khách có thể đặt lịch hẹn trực tuyến tại Nha Khoa Phương Thành thông qua ứng dụng Nha Khoa Phương Thành ngoài ra bạn có thể đặt trực tiếp tại Website của Nha Khoa Phương Thành</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingFour">
                                          <a href="javascript:void(0);" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                              Có nên chải răng nhiều lần trong ngày?
                                          </a>
                                      </h2>
                                      <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faq-details">
                                          <div class="accordion-body">
                                              <div class="accordion-content">
                                                  <p>Câu trả lời là bạn chỉ nên chải răng 2-3 lần/ngày. Mục đích của việc chải răng hằng ngày là làm khuấy trộn liên kết mảng bám để dễ dàng loại bỏ và ngăn không cho vi khuẩn hình thành. Do đó, chỉ cần chải răng khoảng 2-3 lần/ngày đã là quá đủ.</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>


                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingFive">
                                          <a href="javascript:void(0);" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                              Bao lâu thì nên đi phòng nha một lần?
                                          </a>
                                      </h2>
                                      <div id="collapseFive" class="accordion-collapse collapse show" data-bs-parent="#faq-details">
                                          <div class="accordion-body">
                                              <div class="accordion-content">
                                                  <p>Thông thường nếu sức khỏe răng miệng của bạn bình thường thì vẫn nên thăm khám tại các phòng nha định kỳ từ 6 tháng đến 1 năm. Các căn bệnh liên quan đến răng miệng thường có khả năng ủ bệnh trong khoảng 3-6 tháng. 
                                                      Việc thăm khám định kỳ sẽ giúp cho nha sĩ ngăn chặn các nguy cơ gây ra các vấn đề răng miệng của bạn thật nhanh chóng và chính xác.</p>
                                                  <!-- <ul>
                                                      <li>The extinction of plant and</li>
                                                      <li>animal species is endangering</li>
                                                      <li>the functioning of ecosystems</li>
                                                  </ul> -->
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>


          <div class="container">
              <div class="box-time">
                  <div class="box-time-heading">
                      <noscript><img width="70" height="71" src="@/assets/img/phuongthanh/rang-blue.png" alt=""></noscript><img class=" ls-is-cached lazyloaded" width="70" height="71" src="@/assets/img/phuongthanh/rang-blue.png" alt="">
                      <div class="title">Niềng răng trong suốt có nhược điểm gì không?</div>
                  </div>
                  <div class="box-time-content">
                      <p style="text-align: justify"><span style="font-weight: 400">Hạn chế lớn nhất của phương pháp niềng răng trong suốt là chi phí cao hơn niềng răng mắc cài. Tuy nhiên, đây là sự đầu tư xứng đáng bởi những ưu điểm vượt trội như tính thẩm mỹ cao, hiệu quả tối ưu, mang đến trải nghiệm thoải mái, tiện lợi.</span></p>
                  </div>
              </div>
          </div>

          <section class="section-compare">
              <div class="container">
                  <div class="box-heading">
                      <h2>Các Yếu Tố Quyết Định Thành Công Của Ca Điều Trị Niềng Răng Trong Suốt</h2>
                      <p>
                          <span style="font-weight: 400">
                              Sự thành công của một ca điều trị niềng răng trong suốt được quyết định bởi một số yếu tố như:
                          </span>
                      </p>
                  </div>
                  <div class="list-item">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="box-item">
                                  <noscript>
                                      <img width="60" height="60" class="img-icon" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  </noscript>
                                  <img width="60" height="60" class="img-icon lazyloaded" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  <h3 style="text-align: justify">Bác sĩ có chuyên môn sâu về chỉnh nha</h3>
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Bác sĩ cần vững chuyên môn chỉnh nha, đồng thời am hiểu cấu trúc xương và cách dịch chuyển răng. 
                                          Đồng thời, bác sĩ sở hữu kỹ năng thành thạo, thực hiện thăm khám, chẩn đoán đúng tình trạng của bệnh 
                                          nhân và đưa ra lộ trình điều trị tối ưu.
                                      </span>
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="box-item">
                                  <noscript>
                                      <img width="60" height="60" class="img-icon" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  </noscript>
                                  <img width="60" height="60" class="img-icon lazyloaded" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  <h3 style="text-align: justify">Kế hoạch điều trị phù hợp tình trạng răng</h3>
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Dựa vào dữ liệu phim, ảnh chụp và phần mềm hỗ trợ, bác sĩ sẽ có đánh giá 
                                          chi tiết về tình trạng răng hiện tại, từ đó đưa ra kế hoạch và thời gian 
                                          điều trị cụ thể. Bên cạnh đó, bệnh nhân sẽ được tư vấn chọn hãng khay 
                                          trong suốt đảm bảo hiệu quả cao, phù hợp với tài chính.
                                      </span>
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="box-item">
                                  <noscript>
                                      <img width="60" height="60" class="img-icon" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  </noscript>
                                  <img width="60" height="60" class="img-icon lazyloaded" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  <h3 style="text-align: justify">Sự phối hợp của bệnh nhân</h3>
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Bệnh nhân cần đeo khay niềng đúng cách, đủ thời gian, cũng như tái khám, 
                                          đeo hàm duy trì đúng theo hướng dẫn của bác sĩ. Việc này giúp quá trình 
                                          chỉnh nha diễn ra thuận lợi theo đúng lộ trình đề ra ban đầu.
                                      </span>
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="box-item">
                                  <noscript>
                                      <img width="60" height="60" class="img-icon" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  </noscript>
                                  <img width="60" height="60" class="img-icon lazyloaded" src="@/assets/img/phuongthanh/rang-blue-1.png" alt="">
                                  <h3 style="text-align: justify">Sự theo dõi, tầm soát liên tục</h3>
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Quá trình niềng răng trong suốt cần được bác sĩ theo dõi thường xuyên và 
                                          tầm soát liên tục. Nhờ đó bác sĩ sẽ có điều chỉnh phù hợp nếu phát hiện 
                                          bất thường trong quá trình răng dịch chuyển.
                                      </span>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <!-- QUY TRÌNH -->
          <section class="section-process">
              <div class="process-top">
                  <div class="container container-process-top">
                      <div class="box-heading">
                          <h2>Quy Trình Niềng Răng Trong Suốt</h2>
                          <p>
                              <span style="font-weight: 400">
                                  Tại Trung tâm nha khoa Phương Thành, quy trình niềng răng trong suốt chuẩn bao gồm 7 bước mang đến
                                  cho khách hàng trải nghiệm điều trị hiệu quả, an toàn và thoải mái.
                              </span>
                          </p>
                      </div>
                      <div class="list-step">
                          <div class="row justify-content-center">
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="122" height="119" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kham-tong-quat.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="122" height="119" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kham-tong-quat.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kham-tong-quat.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 1</div>
                                          <h3 class="step-title">Khám và tư vấn</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="92" height="126" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tu-van-lay-dau-rang.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="92" height="126" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tu-van-lay-dau-rang.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tu-van-lay-dau-rang.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 2</div>
                                          <h3 class="step-title">Lấy dấu răng kỹ thuật số</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="128" height="62" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-xem-truoc-ket-qua.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="128" height="62" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-xem-truoc-ket-qua.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-xem-truoc-ket-qua.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 3</div>
                                          <h3 class="step-title">Xem kế hoạch điều trị cùng Clincheck</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="156" height="60" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-san-xuat-khay-nieng-trong-suot.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="156" height="60" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-san-xuat-khay-nieng-trong-suot.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-san-xuat-khay-nieng-trong-suot.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 4</div>
                                          <h3 class="step-title">Sản xuất khay</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="127" height="135" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kiem-tra-dieu-tri.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="127" height="135" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kiem-tra-dieu-tri.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-kiem-tra-dieu-tri.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 5</div>
                                          <h3 class="step-title">Nhận và đeo khay</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="127" height="106" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tai-kham.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="127" height="106" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tai-kham.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-tai-kham.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 6</div>
                                          <h3 class="step-title">Tái khám và theo dõi</h3>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-12 step-main">
                                  <div class="step-item">
                                      <div class="step-icon">
                                          <noscript><img width="156" height="58" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-deo-ham-duy-tri.png" alt="Image Icon"></noscript><img class=" lazyloaded" width="156" height="58" src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-deo-ham-duy-tri.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/icon-buoc-deo-ham-duy-tri.png" alt="Image Icon">
                                      </div>
                                      <div class="step-content">
                                          <div class="step-number">Bước 7</div>
                                          <h3 class="step-title">Kết thúc liệu trình, đeo hàm duy trì</h3>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="process-content">
                  <div class="container">
                      <div class="box-content-main">
                          <div class="box-content">
                              <h3 style="text-align: justify">Bước 1: Khám và tư vấn với bác sĩ</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Khách hàng được bác sĩ khám tổng quát, chỉ định chụp X-quang để đánh giá chi tiết tình trạng răng, xương hàm. Sau đó, bác sĩ sẽ tư vấn lộ trình điều trị niềng răng phù hợp.</span></p>
                              <h3 style="text-align: justify">Bước 2: Lấy dấu răng 3D kỹ thuật số</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Khách hàng được hướng dẫn lấy dấu răng 3D kỹ thuật số Trios giúp ghi dấu chính xác, sinh động về trực quan về tình trạng răng. Sau đó, bác sĩ sẽ làm việc cùng kỹ thuật viên của hãng Align và sử dụng phần mềm Clincheck để mô phỏng kế hoạch điều trị.</span></p>
                              <h3 style="text-align: justify">Bước 3: Xem kế hoạch điều trị cùng Clincheck</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Phần mềm Clincheck sẽ hoàn thành kế hoạch điều trị trong khoảng 3-4 tuần. Bác sĩ sẽ hẹn khách hàng xem kế hoạch điều trị Clincheck để biết số khay niềng cần sử dụng và hình dung quá trình răng dịch chuyển.</span></p>
                              <h3 style="text-align: justify">Bước 4: Sản xuất khay dành riêng cho bạn</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Nếu bạn tiếp nhận kế hoạch điều trị Clincheck, bác sĩ sẽ xác nhận để công ty Align sản xuất khay niềng có thiết kế cá nhân hóa theo cung răng của bạn.</span></p>
                              <h3 style="text-align: justify">Bước 5: Nhận và đeo khay</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Sau khoảng 3-4 tuần, bác sĩ sẽ hẹn khách hàng đến phòng khám để gắn khay niềng. Đồng thời, bác sĩ cũng hướng dẫn cách đeo khay kèm theo một số dặn dò cần thiết.</span></p>
                              <h3 style="text-align: justify">Bước 6: Tái khám, theo dõi tình hình</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Khách hàng thực hiện tái khám với bác sĩ để được theo dõi tình trạng răng dịch chuyển và nhận các khay niềng tiếp theo.</span></p>
                              <h3 style="text-align: justify">Bước 7: Kết thúc liệu trình, đeo hàm duy trì</h3>
                              <p style="text-align: justify"><span style="font-weight: 400">Sau khi đeo hết các khay niềng, khách hàng cần đeo hàm duy trì và tái khám thường xuyên trong khoảng 1-2 năm để duy trì nụ cười khỏe đẹp.</span></p>
                          </div>
                          <div class="box-time">
                              <div class="box-time-heading">
                                  <noscript>
                                      <img width="70" height="71" src="@/assets/img/phuongthanh/rang-blue.png" alt="">
                                  </noscript>
                                  <img class=" lazyloaded" width="70" height="71" src="@/assets/img/phuongthanh/rang-blue.png" alt="">
                                  <div class="title">Lưu ý khi niềng trong suốt</div>
                              </div>
                              <div class="box-time-content">
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Thị trường hiện nay có nhiều hãng khay niềng trong suốt thường quảng cáo 
                                          về công nghệ vượt trội, có khả năng lên kế hoạch điều trị từ trí tuệ 
                                          nhân tạo (AI) và xem trước kết quả… khiến nhiều người nhầm tưởng vai 
                                          trò trọng yếu của khay niềng trong suốt.&nbsp;
                                      </span>
                                  </p>
                                  <p style="text-align: justify">
                                      <span style="font-weight: 400">
                                          Thực chất, các loại khay niềng khác nhau cũng chỉ là công cụ hỗ trợ. 
                                          Quan trọng nhất vẫn là cần bác sĩ có chuyên môn sâu về chỉnh nha để 
                                          luôn theo sát quá trình điều trị, từ đó điều chỉnh và bổ sung kế hoạch 
                                          chính xác và phù hợp với từng tình trạng răng.
                                      </span>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <!-- HẾT QUY TRÌNH -->
          
          <!-- ĐỘI NGŨ BÁC SĨ -->
          <!-- <section class="section-doctor">
              <div class="pattern-doctor">
                  <noscript>
                      <img width="1345" height="94" src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" alt="Pattern images 6"></noscript>
                      <img class=" lazyloaded" width="1345" height="94" src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" data-src="/wp-content/themes/fpelitedental/assets/images/image-pattern-ornament-6.png" alt="Pattern images 6">
              </div>
              <div class="background-doctor-main">
                  <div class="container">
                      <div class="box-heading">
                          <h2>Đội Ngũ Bác Sĩ Hàng Đầu</h2>
                          <h3>
                              <span style="font-weight: 400">
                                  Được đào tạo chuyên sâu với nhiều năm kinh nghiệm thực hành chỉnh nha
                              </span>
                          </h3>
                      </div>
                      <div class="doctor-main-content">
                          <div id="doctor-feature" class="owl-carousel owl-theme owl-loaded owl-drag">    
                              <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(0px, 0px, 0px); transition: all; width: 3329px;"><div class="owl-item active" style="width: 1089.6px; margin-right: 20px;"><div class="doctor-item">
                                  <div class="row">
                                      <div class="col-lg-8">
                                          <div class="box-content">
                                              <noscript>
                                                  <img width="99" height="76" class="image-quote" src="/wp-content/uploads/2024/06/icon-quote.png" alt=""></noscript><img width="99" height="76" class="image-quote lazyloaded" src="/wp-content/uploads/2024/06/icon-quote.png" data-src="/wp-content/uploads/2024/06/icon-quote.png" alt="">
                                              <p style="text-align: justify"><i><span style="font-weight: 400">“LÀM NGAY những gì có thể để cải thiện thẩm mỹ cho bệnh nhân, nhất là trẻ em vì những sai lệch phát triển xương trong thời gian dài sẽ ảnh hưởng sự phát triển tâm lý của bé.” Bác không đồng ý với quan điểm “chỉ chỉnh nha khi trẻ đã phát triển đầy đủ vì như vậy là bỏ qua rất nhiều cơ hội và để lại những sai lệch xương rất nặng khi lớn lên.</span></i><span style="font-weight: 400">”</span></p>
                                              <ul>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Tốt nghiệp Bác sĩ RHM khóa 2003-2009, ĐHYD TPHCM.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ đào tạo chỉnh nha của Đại học Victor Segalen, Bordeaux, Pháp khóa 2010-2013.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Thực hành chỉnh nha chuyên sâu từ năm 2014, thực hành chỉnh nha với Invisalign từ năm 2019.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Tốt nghiệp Thạc sĩ RHM khóa 2015-2017, ĐHYD TP. HCM.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Hoàn thành khóa học BioMeaw cơ bản và nâng cao của Giáo sư Enrique Garcia Romero năm 2019.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ Chỉnh Nha Tăng Trưởng với Bioprogressive do giáo sư Enrique Garcia Romero và giáo sư Sergio Sambataro năm 2023.</span></li>
                                                  <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Thông thạo tiếng Anh và tiếng Pháp.</span></li>
                                              </ul>
                                                  <a class="btn-readmore" href="https://elitedental.com.vn/doctors/nguyen-thi-thanh-truc" data-wpel-link="internal">Xem chi tiết</a>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <div class="box-image">
                                              <noscript><img width="394" height="395" class="image-bg" src="@/assets/img/phuongthanh/doctor/BS_Thanh-removebg.png" alt=""></noscript><img width="394" height="395" class="image-bg lazyloaded" src="@/assets/img/phuongthanh/doctor/BS_Thanh-removebg.png" alt="">
                                              <noscript><img width="253" height="260" class="image-doctor" src="@/assets/img/phuongthanh/doctor/BS_Thanh-removebg.png" alt=""></noscript><img width="253" height="260" class="image-doctor lazyloaded" src="@/assets/img/phuongthanh/doctor/BS_Thanh-removebg.png" alt="">
                                              <div class="info-doctor">
                                                  <div class="title">Thạc sĩ, Bác sĩ</div>
                                                  <div class="name">Nguyễn Thị Thanh Trúc</div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  </div></div><div class="owl-item" style="width: 1089.6px; margin-right: 20px;"><div class="doctor-item">
                                      <div class="row">
                                          <div class="col-lg-8">
                                              <div class="box-content">
                                                  <noscript><img width="99" height="76" class="image-quote" src="/wp-content/uploads/2024/06/icon-quote.png" alt=""></noscript><img width="99" height="76" class="image-quote lazyloaded" src="/wp-content/uploads/2024/06/icon-quote.png" data-src="/wp-content/uploads/2024/06/icon-quote.png" alt="">
                                                  <p style="text-align: justify"><span style="font-weight: 400">Xuất thân trong gia đình có truyền thống về ngành Y, </span><span style="font-weight: 400">Thạc sĩ – Bác sĩ</span><span style="font-weight: 400"> Tùng không ngừng trau dồi kiến thức chuyên môn và hoàn thành xuất sắc các khóa học Chỉnh hình răng mặt từ cơ bản đến nâng cao. Với triết lý điều trị </span><i><span style="font-weight: 400">“Luôn làm việc dựa trên lợi ích của bệnh nhân”</span></i><span style="font-weight: 400">, bác Tùng luôn được bệnh nhân yêu quý và đã giúp “tái tạo” rất nhiều nụ cười khỏe đẹp cho cộng đồng.</span></p>
                                                  <ul>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Tốt nghiệp Đại học Y Dược TP.HCM năm 2011</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Tốt nghiệp Thạc sĩ, bác sĩ Chuyên khoa I, bác sĩ Nội trú về mảng điều trị Dự phòng – Phát triển.&nbsp;</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ Chỉnh hình răng mặt lâm sàng nâng cao của Đại học Y Dược TP.HCM&nbsp; 2013_2015</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ hoàn thành chương trình chỉnh nha Bioprogressive 2016-2018</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ hoàn thành khóa đào tạo kỹ thuật Meaw cơ bản 2018 và nâng cao 2019.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ Cấy ghép Nha khoa 2012.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Nhiều năm kinh nghiệm điều trị Chỉnh nha và Chỉnh nha trẻ em.</span></li>
                                                  </ul>
                                                  <a class="btn-readmore" href="https://elitedental.com.vn/doctors/nguyen-thi-thanh-tung" data-wpel-link="internal">Xem chi tiết</a>
                                              </div>
                                          </div>
                                          <div class="col-lg-4">
                                              <div class="box-image">
                                                  <noscript><img width="394" height="395" class="image-bg" src="/wp-content/uploads/2024/06/background-circle-doctor.png" alt=""></noscript><img width="394" height="395" class="image-bg lazyloaded" src="/wp-content/uploads/2024/06/background-circle-doctor.png" data-src="/wp-content/uploads/2024/06/background-circle-doctor.png" alt="">
                                                  <noscript><img width="253" height="260" class="image-doctor" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" alt=""></noscript><img width="253" height="260" class="image-doctor lazyloaded" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" alt="">
                                                  <div class="info-doctor">
                                                      <div class="title">Thạc sĩ, Bác sĩ</div>
                                                      <div class="name">Nguyễn Thị Thanh Tùng</div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div></div><div class="owl-item" style="width: 1089.6px; margin-right: 20px;"><div class="doctor-item">
                                      <div class="row">
                                          <div class="col-lg-8">
                                              <div class="box-content">
                                                  <noscript><img width="99" height="76" class="image-quote" src="/wp-content/uploads/2024/06/icon-quote.png" alt=""></noscript><img width="99" height="76" class="lazyload image-quote" src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2099%2076%22%3E%3C/svg%3E" data-src="/wp-content/uploads/2024/06/icon-quote.png" alt="">
                                                  <p style="text-align: justify"><span style="font-weight: 400">Trong chặng đường 18 năm hành nghề, Bác sĩ Đỗ Quỳnh Như đã dành 12 năm nghiên cứu, trau dồi và thực hành niềng răng trong suốt, niềng răng mắc cài và niềng răng trẻ em. Riêng về điều trị niềng răng cho trẻ em, bác sĩ Như cùng đội ngũ bác sĩ chỉnh nha của Trung tâm nha khoa Phương Thành đã thực hiện thành công hàng ngàn ca giúp trẻ khắc phục khuyết điểm sai lệch khớp cắn ảnh hưởng đến sức khỏe khi trưởng thành.&nbsp;</span></p>
                                                  <ul>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Tốt nghiệp loại ưu chuyên ngành Nha khoa tại Đại học Y Dược TP.HCM năm 2004.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Ủy viên BCH Hiệp hội Chỉnh hình Răng Mặt TP.HCM.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Bác sĩ chứng nhận thực hành chuyên sâu Invisalign từ năm 2013.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Trên 12 năm kinh nghiệm Chỉnh nha mắc cài và Chỉnh nha trẻ em, trên 10 năm kinh nghiệm chỉnh nha với Invisalign.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Hoàn thành khóa học BioMeaw cơ bản và nâng cao của Giáo sư Enrique Garcia Romero năm 2019.</span></li>
                                                      <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Chứng chỉ Chỉnh Nha Tăng Trưởng với Bioprogressive do giáo sư Enrique Garcia Romero và giáo sư Sergio Sambataro năm 2023.</span></li>
                                                  </ul>
                                                  <a class="btn-readmore" href="https://elitedental.com.vn/doctors/do-quynh-nhu" data-wpel-link="internal">Xem chi tiết</a>
                                              </div>
                                          </div>
                                          <div class="col-lg-4">
                                              <div class="box-image">
                                                  <noscript><img width="394" height="395" class="image-bg" src="/wp-content/uploads/2024/06/background-circle-doctor.png" alt=""></noscript><img width="394" height="395" class="lazyload image-bg" src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20394%20395%22%3E%3C/svg%3E" data-src="/wp-content/uploads/2024/06/background-circle-doctor.png" alt="">
                                                  <noscript><img width="394" height="449" class="image-doctor" src="https://elitedental.com.vn/wp-content/uploads/2024/06/bac-si-do-quynh-nhu.png" alt=""></noscript><img width="394" height="449" class="lazyload image-doctor" src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20394%20449%22%3E%3C/svg%3E" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/bac-si-do-quynh-nhu.png" alt="">
                                                  <div class="info-doctor">
                                                      <div class="title">Bác sĩ</div>
                                                      <div class="name">Đỗ Quỳnh Như</div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="owl-nav disabled"><div class="owl-prev">prev</div><div class="owl-next">next</div></div><div class="owl-dots disabled"></div></div>
                          <div id="doctor-feature-thumbnail" class="owl-carousel owl-theme owl-loaded">   
                              <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(0px, 0px, 0px); transition: all; width: 370px;"><div class="owl-item active current" style="width: 113.187px; margin-right: 10px;"><div class="doctor-thumnbnail">
                                  <noscript><img width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/bac-si-nguyen-thi-thanh-truc.png" alt=""></noscript><img class=" ls-is-cached lazyloaded" width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/bac-si-nguyen-thi-thanh-truc.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/bac-si-nguyen-thi-thanh-truc.png" alt="">
                                  <div class="info-doctor">
                                      <div class="title">Thạc sĩ, Bác sĩ</div>
                                      <div class="name">Nguyễn Thị Thanh Trúc</div>
                                  </div>
                              </div>
                          </div>
                          <div class="owl-item active" style="width: 113.187px; margin-right: 10px;"><div class="doctor-thumnbnail">
                              <noscript><img width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" alt=""></noscript><img class=" lazyloaded" width="253" height="260" src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" data-src="https://elitedental.com.vn/wp-content/uploads/2022/03/Nguyen-Thi-Thanh-Tung-1.png" alt="">
                                  <div class="info-doctor">
                                      <div class="title">Thạc sĩ, Bác sĩ</div>
                                      <div class="name">Nguyễn Thị Thanh Tùng</div>
                                  </div>
                              </div>
                          </div>
                          <div class="owl-item active" style="width: 113.187px; margin-right: 10px;"><div class="doctor-thumnbnail">
                              <noscript><img width="394" height="449" src="https://elitedental.com.vn/wp-content/uploads/2024/06/bac-si-do-quynh-nhu.png" alt=""></noscript><img class=" lazyloaded" width="394" height="449" src="https://elitedental.com.vn/wp-content/uploads/2024/06/bac-si-do-quynh-nhu.png" data-src="https://elitedental.com.vn/wp-content/uploads/2024/06/bac-si-do-quynh-nhu.png" alt="">
                                  <div class="info-doctor">
                                      <div class="title">Bác sĩ</div>
                                      <div class="name">Đỗ Quỳnh Như</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="owl-nav disabled">
                      <div class="owl-prev disabled"><i class="fa-light fa-arrow-left"></i>
                      </div>
                      <div class="owl-next disabled"><i class="fa-light fa-arrow-right"></i>
                      </div>
                  </div>
                  <div class="owl-dots disabled">

                  </div>
              </div>
          </div>
                      <div class="btn-wrapper">
                          <a href="https://elitedental.com.vn/doi-ngu-bac-si-elite-dental" data-wpel-link="internal">
                              <noscript><img width="85" height="69" src="/wp-content/uploads/2024/06/icon-group-doctor.png" alt=""></noscript><img class=" ls-is-cached lazyloaded" width="85" height="69" src="/wp-content/uploads/2024/06/icon-group-doctor.png" data-src="/wp-content/uploads/2024/06/icon-group-doctor.png" alt="">
                              XEM THÊM VỀ ĐỘI NGŨ BÁC SĨ                </a>
                      </div>
                  </div>
              </div>
          </section> -->
          
          <!-- DỊCH VỤ CHỈNH NHA -->
          <section class="section-usps">
              <div class="container">
                  <div class="row">
                      <div class="col-xl-6 col-lg-12">
                          <div class="box-heading">
                              <h2>Trải Nghiệm Dịch Vụ<br>
                                  Chỉnh Nha Trong Suốt<br>
                                  Tại Nha khoa Phương Thành
                              </h2>
                              <h3><span style="font-weight: 400">Bác sĩ chuyên sâu – Hiệu quả dài lâu</span></h3>
                          </div>
                          <div class="box-image">
                              <noscript>
                                  <img width="600" height="400" class="image-usps" src="@/assets/img/phuongthanh/core/baidang.png" alt=""></noscript>
                                  <img width="600" height="400" class="image-usps lazyloaded" src="@/assets/img/phuongthanh/core/baidang.png" data-src="@/assets/img/phuongthanh/core/baidang.png" alt="">
                          </div>
                      </div>
                      <div class="col-xl-6 col-lg-12">
                          <div class="list-usps">
                              <div class="usps-item">
                                  <div class="box-content">
                                  <p style="text-align: justify"><span style="font-weight: 400">Để có kết quả niềng răng trong suốt mỹ mãn, ổn định theo thời gian, yếu tố quan trọng hàng đầu cần quan tâm đến là lựa chọn phòng khám uy tín có đội ngũ bác sĩ chuyên sâu chỉnh nha. Đến với Trung tâm nha khoa Phương Thành, khách hàng hoàn toàn yên tâm với chất lượng dịch vụ nhờ vào những thế mạnh nổi bật sau:</span></p>
                              </div>
                          </div>
                          <div class="usps-item">
                              <div class="box-icon">
                                  <noscript><img width="60" height="60" src="@/assets/img/phuongthanh/doctor.png" alt=""></noscript><img class=" lazyloaded" width="60" height="60" src="@/assets/img/phuongthanh/doctor.png" alt="">
                              </div>
                              <div class="box-content">
                                  <h3 style="text-align: justify">
                                      Bác sĩ chỉnh nha chuyên môn cao, giàu kinh nghiệm
                                  </h3>
                                  <p style="text-align: justify"><span style="font-weight: 400">Trung tâm nha khoa Phương Thành là nơi hội tụ đội ngũ bác sĩ chỉnh nha chuyên môn cao, thường xuyên tham gia các khóa đào tạo nâng cao của Invisalign. Với hơn 10 năm điều trị niềng răng trong suốt, các bác sĩ hiểu rõ cấu trúc răng, xương hàm và có cách dịch chuyển răng đúng và hiệu quả. </span></p>
                              </div>
                          </div>
                          <div class="usps-item">
                              <div class="box-icon">
                                  <noscript><img width="60" height="60" src="@/assets/img/phuongthanh/teeth-nieng.png" alt=""></noscript><img class=" ls-is-cached lazyloaded" width="60" height="60" src="@/assets/img/phuongthanh/teeth-nieng.png" alt="">
                              </div>
                              <div class="box-content">
                                  <h3 style="text-align: justify">Ngân hàng ca điều trị thành công nhiều trường hợp với niềng răng trong suốt</h3>
                                  <p style="text-align: justify"><span style="font-weight: 400">Trung tâm nha khoa Phương Thành có ngân hàng tổng hợp nhiều ca điều trị niềng răng trong suốt thành công trên nhiều trường hợp sai khớp cắn, lệch lạc. Khách hàng sẽ được tham khảo những ca tương tự với mình đã chữa trị thế nào và kết quả cuối cùng ra sao. Từ đó giúp bạn thêm an tâm để bắt đầu hành trình điều trị. </span></p>
                              </div>
                          </div>
                          <div class="usps-item">
                              <div class="box-icon">
                                  <noscript><img width="60" height="60" src="@/assets/img/phuongthanh/quytrinh.png" alt=""></noscript><img class=" lazyloaded" width="60" height="60" src="@/assets/img/phuongthanh/quytrinh.png" alt="">
                              </div>
                              <div class="box-content">
                                  <h3 style="text-align: justify">Bác sĩ đồng hành sát sao trong suốt quá trình điều trị</h3>
                                  <p style="text-align: justify"><span style="font-weight: 400">Bác sĩ sẽ trực tiếp thăm khám, tư vấn và lên kế hoạch điều trị cho khách hàng. Ngoài ra, trong suốt hành trình trước và sau khi niềng răng trong suốt, bạn luôn có sự đồng hành của bác sĩ để kiểm soát điều trị, kịp thời xử lý vấn đề để đảm bảo kết quả khớp cắn chuẩn, toàn diện.</span></p>
                              </div>
                          </div>
                          <div class="usps-item">
                              <div class="box-icon">
                                  <noscript><img width="60" height="60" src="@/assets/img/phuongthanh/thietbi.png" alt=""></noscript><img class=" ls-is-cached lazyloaded" width="60" height="60" src="@/assets/img/phuongthanh/thietbi.png"alt="">
                              </div>
                              <div class="box-content">
                                  <h3 style="text-align: justify">Thiết bị hỗ trợ chỉnh nha hiện đại, hệ thống vô trùng</h3>
                                  <p style="text-align: justify"><span style="font-weight: 400">Trung tâm nha khoa Phương Thành chú trọng ứng dụng công nghệ hiện đại, cập nhật các thiết bị tân tiến hỗ trợ điều trị như máy scan 3D Trios lấy dấu răng chính xác, máy chụp phim Sirona cho hình ảnh rõ nét,… Nhờ đó khách hàng có trải nghiệm điều trị nhẹ nhàng, nhanh chóng và tiết kiệm thời gian.</span></p>
                              </div>
                          </div>
                          <div class="usps-item">
                                  <div class="box-icon">
                                      <noscript><img width="60" height="60" src="@/assets/img/phuongthanh/teeth-khoe.png" alt=""></noscript><img class=" lazyloaded" width="60" height="60" src="@/assets/img/phuongthanh/teeth-khoe.png" alt="">
                                  </div>
                                  <div class="box-content">
                                      <h3 style="text-align: justify">Đảm bảo kết thúc ca niềng răng đạt 6 tiêu chuẩn “vàng”</h3>
                                      <p style="text-align: justify"><span style="font-weight: 400">Hàng ngàn ca niềng răng trong suốt tại Trung tâm nha khoa Phương Thành kết thúc thành công đảm bảo 6 tiêu chuẩn “vàng” gồm:</span></p>
                                      <ul>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Khớp cắn chuẩn, hàm trên phủ ngoài và ở trước hàm dưới 1-2mm.</span></li>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Các răng xếp lồng múi ở đúng vị trí.</span></li>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Đường giữa hàm trên trùng khớp với đường giữa mặt.</span></li>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Khôi phục khả năng ăn nhai, phát âm tốt.</span></li>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Đảm bảo thẩm mỹ góc mặt nhìn nghiêng và góc nhìn thẳng.</span></li>
                                          <li style="font-weight: 400;text-align: justify"><span style="font-weight: 400">Kết quả sau cùng bền vững, ổn định.</span></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </section>

          <!-- ĐÁNH GIÁ KHÁCH HÀNG -->
          <section>
              <div class="container">

              </div>
          </section>

      </div>
  </div>
<!-- <footerHome></footerHome> -->
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import IndexfiveFaq from "@/assets/json/indexfivefaq.json";
import 'vue3-carousel/dist/carousel.css'
export default {
components: {
  Carousel,
  Slide,
  Pagination,
},
data() {
  return {
      IndexfiveFaq: IndexfiveFaq,
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      settingss: {
          itemsToShow: 1,
          snapAlign: 'center',
          },
      settings: {
          itemsToShow: 4,
          snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
          578: {
              itemsToShow: 2,
              snapAlign: 'start',
          },
          // 700px and up
          768: {
              itemsToShow: 2,
              snapAlign: 'start',
          },
          // 1024 and up
          992: {
              itemsToShow: 3,
              snapAlign: 'start',
          },
          1200: {
              itemsToShow: 4,
              snapAlign: 'start',
          },
      },
  }
},

watch: {
  $route(to, from) {
    //  console.log("LOG WATCH DATA : ")
  //   this.getData();
  },
  perPage: {
    deep: true,
    handler(val){
      // this.getData();
    }
  },
  currentPage:{
    deep: true,
    handler(val){
      //   console.log("this.perpage", this.currentPage);
      // this.getData();
    }
  }
},

mounted() {
},
created() {
},
methods: {
  nextSlide() {
      this.$refs.carousel.next();
  },
  prevSlide() {
      this.$refs.carousel.prev();
  },
},
}
</script>

<style>
.list-doingu{
  width: 100%;
  /* max-height: 250px; */
}
.item-doingu {
  position: relative;
  padding: 0 10px;
  border-radius: 20px;
  cursor: pointer;
}
.item-doingu img{
  object-fit: cover;
  width: 100%;
  /* height: 250px; */
  border-radius: 20px;
  background-color: #fff;
}

.list-doingu .item-doingu .card-doingu{
  position: absolute;
  bottom: 0;
  background: rgba(88, 97, 116, .9);
  left: 0;
  right: 0;
  padding: 10px;
  margin: 30px;
  border-radius: 11px;
  text-align: center;
  color: #fff;
  transition: visibility 0s, height .3s ease
}

.list-doingu .item-doingu .card-doingu .doctor-chuc-danh span{
  font-size: 12px !important;
}
.list-doingu .item-doingu .card-doingu .doctor-name h3{
  font-size: 16px !important;
  color: #fff;
}

.list-doingu .item-doingu .card-doingu .doctor-desc{
  color: #edeef2;
  font-size: 11px;
  visibility: hidden;
  height: 0;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button{
  margin-top: 10px;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button a {
  background: #fff;
  border-radius: 24px;
  font-family: "Nunito Semibold";
  font-size: 12px;
  height: 30px;
  padding: 5px 15px;
  line-height: 30px;
  max-width: 150px;
  margin: 0 auto
}

.list-doingu .item-doingu:hover .card-doingu {
  bottom: 0;
  animation: slideUp .5s ease forwards;
  transition: visibility 0s,height .3s ease
}
.list-doingu .item-doingu:hover .card-doingu .doctor-desc{
  visibility: visible;
  height: auto
}

.color{
  color: #D6A24D;
}



/* Những Ai Nên Niềng Răng Trong Suốt */
.section-who{
  margin-top: 30px;
  margin-bottom: 20px;
}

.section-who .box-content .box-heading {
  margin-bottom: 30px
}

.section-who .box-content .box-heading h1 {
  color: #d4a04c;
  font-family: "Nunito Bold";
  margin-bottom: 20px
}

@media screen and (max-width: 767px) {
  .section-who .box-content .box-heading h2 {
      margin-bottom:16px;
      text-align: center
  }
}

.section-who .box-content .box-heading p {
  color: #2a2f38;
  font-family: "Nunito Regular";
  font-size: 16px
}

.section-who .box-content .list-item .box-item {
  display: flex;
  margin-bottom: 24px
}

.section-who .box-content .list-item .box-item:last-child {
  margin-bottom: 0
}

.section-who .box-content .list-item .box-item .box-icon {
  margin-right: 24px;
  color: #d4a04c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-who .box-content .list-item .box-item .box-icon .icon-check {
  font-size: 30px;
}

.section-who .box-content .list-item .box-item .box-icon img {
  min-width: 37px
}

.section-who .box-content .list-item .box-item .box-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-who .box-content .list-item .box-item .box-item-content p {
  font-size: 16px;
  color: #2a2f38;
  margin-bottom: 0
}

.section-who .box-time {
  border: 1px solid #db92c5;
  border-radius: 16px;
  padding: 30px;
  margin-top: 50px
}

@media screen and (max-width: 767px) {
  .section-who .box-time {
      padding:15px;
      margin-top: 30px
  }
}

.section-who .box-time .box-time-heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

.section-who .box-time .box-time-heading img {
  min-width: 58px;
  margin-right: 12px
}

.section-who .box-time .box-time-heading .title {
  color: #b01f8e;
  font-size: 20px;
  font-family: "Nunito SemiBold"
}

.section-who .box-time .box-time-content p {
  color: #2a2f38;
  font-size: 16px;
  font-family: "Nunito Regular"
}

.section-who .box-image {
  margin-right: 30px
}

@media screen and (max-width: 991px) {
  .section-who .box-image {
      text-align:center;
      margin-right: 0;
      margin-bottom: 30px
  }
}

.section-who .box-image img {
  width: 100%;
  height: auto;
  object-fit: cover
}

@media screen and (max-width: 991px) {
  .section-who .box-image img {
      width:auto
  }
}


/* CÂU HỎI */

.main-wrapper.home-ten .faq-user{
  box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 10px;
  padding: 15px;
  background: #ffffff;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  position: absolute;
  bottom: 40%;
  left: 15%;
}
.main-wrapper.home-ten .faq-user .user-info {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 50px;
  flex-shrink: 0;
}
.main-wrapper.home-ten .faq-user .user-info span {
  flex-shrink: 0;
  margin-right: 10px;
}
.main-wrapper.home-ten .faq-user .user-info img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.main-wrapper.home-ten .faq-user .user-info h6 {
  font-size: 18px;
  font-weight: 600;
  color: #00003b;
  margin-bottom: 5px;
}
.main-wrapper.home-ten .faq-user .user-info p {
  font-size: 12px;
  color: #374151;
  margin-bottom: 0;
}

.main-wrapper.home-ten .faq-user .rating {
  list-style: none;
  width: 100%;
  margin: 0 0 7px;
  padding: 0;
}
.main-wrapper.home-ten .faq-user .rating i.filled {
  color: #f4c150;
}

/* CARD BOX */

.box-time {
  margin-top: 50px;
  border: 1px solid #d4a04caf;
  border-radius: 16px;
  padding: 30px
}

@media screen and (max-width: 767px) {
  .box-time {
      padding:15px;
      margin-top: 30px
  }
}

.box-time .box-time-heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

.box-time .box-time-heading img {
  min-width: 58px;
  margin-right: 12px
}

.box-time .box-time-heading .title {
  color: #d4a04c;
  font-size: 20px;
  font-family: "Nunito SemiBold"
}

.box-time .box-time-content p {
  color: #2a2f38;
  font-size: 16px;
  font-family: "Nunito Regular"
}



.section-compare {
  position: relative;
  padding-top: 100px
}

@media screen and (max-width: 991px) {
  .section-compare {
      padding-top:50px
  }
}

@media screen and (max-width: 767px) {
  .section-compare {
      padding-top:30px
  }
}

.section-compare .box-heading {
  max-width: 850px;
  margin: 0 auto 30px;
  text-align: center
}

.section-compare .box-heading h2 {
  color: #d4a04c;
  font-family: "Nunito Bold"
}

.section-compare .box-heading h3 {
  color: #d4a04c;
  font-family: "Nunito Bold";
  font-size: 24px
}

.section-compare .box-heading p {
  font-size: 16px;
  font-family: "Nunito Regular";
  color: #2a2f38
}

.section-compare .list-item .box-item {
  position: relative;
  border: 1px solid #d2d6de;
  border-radius: 16px;
  padding: 24px;
  height: calc(100% - 24px);
  margin-bottom: 24px
}

@media screen and (max-width: 991px) {
  .section-compare .list-item .box-item {
      padding:20px 12px
  }
}

.section-compare .list-item .box-item .img-icon {
  position: absolute;
  top: 20px;
  right: 30px
}

@media screen and (max-width: 991px) {
  .section-compare .list-item .box-item .img-icon {
      width:50px;
      top: 10px;
      right: 10px
  }
}

.section-compare .list-item .box-item h3 {
  font-size: 20px;
  color: #2a2f38;
  font-family: "Nunito Bold";
  margin-bottom: 12px;
  max-width: 300px;
  display: flex;
  align-items: flex-end;
  height: 50px
}

@media screen and (max-width: 991px) {
  .section-compare .list-item .box-item h3 {
      max-width:250px;
      height: auto
  }
}

@media screen and (max-width: 767px) {
  .section-compare .list-item .box-item h3 {
      max-width:220px;
      font-size: 16px
  }
}

.section-compare .list-item .box-item p {
  color: #2a2f38;
  font-size: 16px;
  font-family: "Nunito Regular";
  margin-bottom: 0
}

@media screen and (max-width: 767px) {
  .section-compare .list-item .box-item p {
      font-size:14px
  }
}

.section-compare .elm-why-left {
  position: absolute;
  top: 5%;
  left: 5%
}

@media screen and (max-width: 1200px) {
  .section-compare .elm-why-left {
      display:none
  }
}

.section-compare .elm-why-right {
  position: absolute;
  top: 20%;
  right: 0
}

@media screen and (max-width: 1200px) {
  .section-compare .elm-why-right {
      display:none
  }
}


/* QUY TRÌNH */

.section-process {
  margin-top: 100px
}

@media screen and (max-width: 991px) {
  .section-process {
      margin-top:50px
  }
}

@media screen and (max-width: 767px) {
  .section-process {
      margin-top:30px
  }
}

.section-process .process-top {
  position: relative;
  /* background: #d4a04c;
  padding: 60px 0 150px;
  border-radius: 0 100px 0 0 */
}

.section-process .process-top .container-process-top{
  background: #d4a04c;
  padding: 60px 0 150px;
  border-radius: 50px
}

@media screen and (max-width: 767px) {
  .section-process .process-top {
      border-radius:0 40px 0 0
  }
}

.section-process .process-top .box-heading {
  max-width: 900px;
  margin: 0 auto 30px;
  padding: 0 20px;
  text-align: center
}

.section-process .process-top .box-heading h2 {
  color: #fff;
  font-family: "Nunito Bold"
}

@media screen and (max-width: 767px) {
  .section-process .process-top .box-heading h2 br {
      display:none
  }
}

.section-process .process-top .box-heading p {
  color: #fff;
  font-size: 16px;
  font-family: "Nunito Regular";
  margin-bottom: 0
}

.section-process .process-top .list-step {
  position: relative;
  padding: 0 20px;
  z-index: 1
}

.section-process .process-top .list-step .step-item {
  text-align: center
}

@media screen and (max-width: 767px) {
  .section-process .process-top .list-step .step-item {
      text-align:left;
      display: flex;
      margin-bottom: 30px;
      align-items: center
  }
}

.section-process .process-top .list-step .step-item .step-icon {
  height: 135px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center
}

@media screen and (max-width: 767px) {
  .section-process .process-top .list-step .step-item .step-icon {
      height:auto;
      margin-bottom: 20px;
      min-width: 50px;
      margin-right: 15px;
      max-width: 50px
  }
}

.section-process .process-top .list-step .step-item .step-content .step-number {
  display: inline-block;
  background: linear-gradient(76.59deg,#36b4c8 2.16%,rgba(19,213,226,.82) 100%);
  padding: 6px 10px;
  margin-bottom: 18px;
  border-radius: 26px;
  font-size: 16px;
  color: #fff;
  font-family: "Nunito Bold"
}

@media screen and (max-width: 767px) {
  .section-process .process-top .list-step .step-item .step-content .step-number {
      margin-bottom:12px;
      font-size: 14px
  }
}

.section-process .process-top .list-step .step-item .step-content .step-title {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
  font-family: "Nunito Bold";
  line-height: 1.5
}

@media screen and (min-width: 768px) and (max-width:1024px) {
  .section-process .process-top .list-step .step-item .step-content .step-title {
      font-size:14px
  }
}

@media screen and (max-width: 767px) {
  .section-process .process-top .list-step .step-item .step-content .step-title {
      font-size:16px
  }
}

.section-process .process-top .list-step .step-main:last-child .step-item {
  margin-bottom: 0
}

@media screen and (min-width: 768px) {
  .section-process .process-top .list-step .row .col-lg-2 {
      width:20%
  }
}

.section-process .process-top .elm-process-top {
  position: absolute;
  right: 0;
  bottom: 0
}

@media screen and (max-width: 767px) {
  .section-process .process-top .elm-process-top {
      width:220px
  }
}

.section-process .process-content {
  position: relative;
  z-index: 1;
  max-width: 900px;
  margin: -100px auto 0
}

.section-process .process-content .box-content-main {
  padding: 60px 50px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 41px 57.4px -25px rgba(233,189,220,.27)
}

@media screen and (max-width: 767px) {
  .section-process .process-content .box-content-main {
      padding:30px 20px
  }
}

.section-process .process-content .box-content-main .box-content h3 {
  color: #d4a04c;
  font-size: 20px;
  font-family: "Nunito SemiBold";
  margin-bottom: 12px
}

.section-process .process-content .box-content-main .box-content p {
  font-size: 16px;
  color: #2a2f38;
  font-family: "Nunito Regular";
  margin-bottom: 20px
}

.section-process .process-content .box-content-main .box-time {
  border: 1px solid #d4a04c;
  border-radius: 16px;
  padding: 30px
}

@media screen and (max-width: 767px) {
  .section-process .process-content .box-content-main .box-time {
      padding:15px
  }
}

.section-process .process-content .box-content-main .box-time .box-time-heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

.section-process .process-content .box-content-main .box-time .box-time-heading img {
  min-width: 58px;
  margin-right: 12px
}

.section-process .process-content .box-content-main .box-time .box-time-heading .title {
  color: #d4a04c;
  font-size: 20px;
  font-family: "Nunito SemiBold"
}

.section-process .process-content .box-content-main .box-time .box-time-content p {
  color: #2a2f38;
  font-size: 16px;
  font-family: "Nunito Regular"
}


/* ĐỘI NGŨ BÁC SĨ */

.section-doctor {
  padding-top: 100px
}

@media screen and (max-width: 991px) {
  .section-doctor {
      padding-top:50px
  }
}

@media screen and (max-width: 767px) {
  .section-doctor {
      padding-top:30px
  }
}

.section-doctor .pattern-doctor img {
  width: 100%;
  height: auto
}

.section-doctor .background-doctor-main {
  background: #faf0f7
}

.section-doctor .background-doctor-main .box-heading {
  text-align: center
}

@media screen and (max-width: 767px) {
  .section-doctor .background-doctor-main .box-heading {
      padding-top:20px;
      margin-bottom: 0
  }
}

.section-doctor .background-doctor-main .box-heading h2 {
  color: #d4a04c;
  font-family: "Nunito Bold"
}

.section-doctor .background-doctor-main .box-heading h3 {
  color: #68026d;
  font-family: "Nunito Bold";
  font-size: 24px
}

.section-doctor .background-doctor-main .doctor-item {
  padding-top: 30px
}

@media screen and (max-width: 991px) {
  .section-doctor .background-doctor-main .doctor-item .row .col-lg-4 {
      order:1
  }
}

@media screen and (max-width: 991px) {
  .section-doctor .background-doctor-main .doctor-item .row .col-lg-8 {
      order:2
  }
}

.section-doctor .background-doctor-main .doctor-item .box-content {
  position: relative;
  padding-top: 50px
}

@media screen and (max-width: 767px) {
  .section-doctor .background-doctor-main .doctor-item .box-content {
      padding-top:30px
  }
}

.section-doctor .background-doctor-main .doctor-item .box-content .image-quote {
  position: absolute;
  top: 0;
  width: 98px
}

@media screen and (max-width: 767px) {
  .section-doctor .background-doctor-main .doctor-item .box-content .image-quote {
      width:60px
  }
}

.section-doctor .background-doctor-main .doctor-item .box-content p {
  color: #2a2f38;
  font-family: "Nunito Regular";
  position: relative
}

.section-doctor .background-doctor-main .doctor-item .box-content ul {
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none
}

.section-doctor .background-doctor-main .doctor-item .box-content ul li {
  color: #2a2f38;
  font-family: "Nunito Regular";
  position: relative;
  padding-left: 20px;
  margin-bottom: 16px
}

.section-doctor .background-doctor-main .doctor-item .box-content ul li:after {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #b01f8e;
  position: absolute;
  top: 5px;
  left: 0
}

.section-doctor .background-doctor-main .doctor-item .box-content .btn-readmore {
  display: inline-block;
  border: 1px solid #b01f8e;
  font-family: "Nunito Bold";
  font-size: 14px;
  border-radius: 24px;
  color: #b01f8e;
  padding: 15px 20px;
  line-height: 14px
}

.section-doctor .background-doctor-main .doctor-item .box-image {
  max-width: 320px;
  position: relative;
  margin: 0 auto
}

@media screen and (min-width: 1024px) {
  .section-doctor .background-doctor-main .doctor-item .box-image {
      max-width:280px
  }
}

.section-doctor .background-doctor-main .doctor-item .box-image .image-bg {
  width: 100%
}

.section-doctor .background-doctor-main .doctor-item .box-image .image-doctor {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%
}

.section-doctor .background-doctor-main .doctor-item .box-image .info-doctor {
  display: inline-block;
  background: rgba(88,97,116,.9);
  padding: 7px 14px;
  border-radius: 12px;
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center
}

.section-doctor .background-doctor-main .doctor-item .box-image .info-doctor .title {
  font-size: 12px;
  font-family: "Nunito Regular"
}

.section-doctor .background-doctor-main .doctor-item .box-image .info-doctor .name {
  font-size: 14px;
  font-family: "Nunito Bold"
}

.section-doctor .background-doctor-main .doctor-main-content {
  position: relative
}

.section-doctor .background-doctor-main .doctor-thumnbnail {
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  position: relative
}

.section-doctor .background-doctor-main .doctor-thumnbnail img {
  min-height: 150px;
  max-height: 150px;
  object-fit: cover
}

@media screen and (min-width: 992px) and (max-width:1024px) {
  .section-doctor .background-doctor-main .doctor-thumnbnail img {
      min-height:160px;
      max-height: 160px
  }
}

@media screen and (max-width: 991px) {
  .section-doctor .background-doctor-main .doctor-thumnbnail img {
      min-height:260px;
      max-height: 260px
  }
}

.section-doctor .background-doctor-main .doctor-thumnbnail .info-doctor {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background: rgba(88,97,116,.9);
  border-radius: 12px;
  color: #fff;
  text-align: center
}

@media screen and (min-width: 1024px) {
  .section-doctor .background-doctor-main .doctor-thumnbnail .info-doctor {
      padding:5px;
      margin: 5px
  }
}

.section-doctor .background-doctor-main .doctor-thumnbnail .info-doctor .title {
  font-size: 10px;
  font-family: "Nunito Regular"
}

.section-doctor .background-doctor-main .doctor-thumnbnail .info-doctor .name {
  font-size: 10px;
  font-family: "Nunito Bold"
}

@media screen and (min-width: 1024px) {
  .section-doctor .background-doctor-main #doctor-feature-thumbnail {
      position:absolute;
      width: 33%;
      right: 0;
      bottom: -20px
  }
}

@media screen and (max-width: 991px) {
  .section-doctor .background-doctor-main #doctor-feature-thumbnail {
      margin-top:30px
  }
}

@media screen and (max-width: 767px) {
  .section-doctor .background-doctor-main #doctor-feature-thumbnail .owl-stage-outer .owl-stage {
      padding-left:0 !important
  }
}

.section-doctor .background-doctor-main #doctor-feature-thumbnail .owl-nav {
  margin-top: 0;
  top: 38%
}

.section-doctor .background-doctor-main #doctor-feature-thumbnail .owl-nav .owl-next,.section-doctor .background-doctor-main #doctor-feature-thumbnail .owl-nav .owl-prev {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important
}

.section-doctor .background-doctor-main .btn-wrapper {
  margin-top: 50px;
  text-align: center
}

.section-doctor .background-doctor-main .btn-wrapper a {
  background-color: #b01f8e;
  padding: 1em 2.5em 1em 2em;
  display: inline-flex;
  align-items: center;
  border-radius: 200px;
  color: #fff;
  font-family: "Nunito Semibold";
  font-size: 18px;
  max-height: 73px
}

@media screen and (max-width: 767px) {
  .section-doctor .background-doctor-main .btn-wrapper a {
      font-size:16px
  }
}

.section-doctor .background-doctor-main .btn-wrapper a img {
  margin-right: 20px;
  min-width: 60px;
  max-width: 60px
}


/* DỊCH VỤ CHỈNH NHA */

.section-usps {
  position: relative;
  /* background-color: #fff7e9; */
  padding: 50px 0
}

.section-usps .container{
  background-color: #fff7e9;
  padding: 30px;
  border-radius: 20px;
}

@media screen and (max-width: 991px) {
  .section-usps {
      padding:50px 0
  }
}

@media screen and (max-width: 767px) {
  .section-usps {
      padding:30px 0
  }
}

.section-usps .elm-right {
  position: absolute;
  right: 0;
  bottom: 0
}

@media screen and (max-width: 767px) {
  .section-usps .elm-right {
      display:none
  }
}

.section-usps .box-heading {
  text-align: center;
  margin-bottom: 30px
}

.section-usps .box-heading h2 {
  color: #d4a04c;
  font-family: "Nunito Bold"
}

.section-usps .box-heading h3 {
  color: #68026d;
  font-family: "Nunito Bold";
  font-size: 24px;
  margin-bottom: 0
}

@media screen and (max-width: 767px) {
  .section-usps .box-heading h3 {
      font-size:18px
  }
}

@media screen and (max-width: 991px) {
  .section-usps .box-image {
      text-align:center;
      margin-bottom: 50px
  }
}

@media screen and (max-width: 767px) {
  .section-usps .box-image {
      margin-bottom:30px
  }
}

.section-usps .box-image .image-usps {
  border-radius: 15px;
  border: 10px solid #fff
}

.section-usps .list-usps {
  margin-left: 30px
}

@media screen and (max-width: 1024px) {
  .section-usps .list-usps {
      margin-left:0
  }
}

.section-usps .list-usps .usps-item {
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eae4f0
}

.section-usps .list-usps .usps-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none
}

.section-usps .list-usps .usps-item .box-icon {
  width: 60px;
  min-width: 60px;
  margin-right: 20px
}

.section-usps .list-usps .usps-item .box-content {
  font-size: 14px;
  color: #2a2f38;
  font-family: "Nunito Regular"
}

.section-usps .list-usps .usps-item .box-content h3 {
  font-size: 18px;
  color: #2a2f38;
  font-family: "Nunito Bold"
}

@media screen and (max-width: 767px) {
  .section-usps .list-usps .usps-item .box-content h3 {
      font-size:16px
  }
}

.section-usps .list-usps .usps-item .box-content p {
  font-size: 14px;
  color: #2a2f38;
  font-family: "Nunito Regular";
  margin: 0
}

.section-usps .list-usps .usps-item .box-content ul {
  font-size: 14px;
  color: #2a2f38;
  font-family: "Nunito Regular";
  margin-top: 8px
}




/* HAHA */

.section-define {
  padding-top: 100px
}

@media screen and (max-width: 991px) {
  .section-define {
      padding-top:50px
  }
}

@media screen and (max-width: 767px) {
  .section-define {
      padding-top:30px
  }
}

@media screen and (max-width: 991px) {
  .section-define .row .col-lg-7 {
      order:2
  }
}

@media screen and (max-width: 991px) {
  .section-define .row .col-lg-5 {
      order:1
  }
}

.section-define .box-content .box-heading {
  margin-bottom: 30px
}

.section-define .box-content .box-heading h2 {
  color: #d4a04c;
  font-family: "Nunito Bold";
  margin-bottom: 20px
}

@media screen and (max-width: 767px) {
  .section-define .box-content .box-heading h2 {
      margin-bottom:16px;
      text-align: center
  }
}

.section-define .box-content .box-heading p {
  color: #2a2f38;
  font-family: "Nunito Regular";
  font-size: 16px
}

.section-define .box-content .list-item .box-item {
  margin-bottom: 12px
}

.section-define .box-content .list-item .box-item:last-child {
  margin-bottom: 0
}

.section-define .box-content .list-item .box-item .box-item-heading {
  display: flex;
  align-items: center;
  margin-bottom: 4px
}

.section-define .box-content .list-item .box-item .box-item-heading .box-icon {
  margin-right: 16px
}

.section-define .box-content .list-item .box-item .box-item-heading h3 {
  color: #b01f8e;
  font-family: "Nunito SemiBold";
  margin: 0
}

.section-define .box-content .list-item .box-item .box-item-content p {
  font-size: 16px;
  color: #2a2f38;
  margin-bottom: 0
}

@media screen and (max-width: 991px) {
  .section-define .box-image {
      text-align:center;
      margin-bottom: 30px
  }
}

.section-define .box-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 14px
}

@media screen and (max-width: 991px) {
  .section-define .box-image img {
      width:auto
  }
}
</style>
