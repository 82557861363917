<template>
    <!-- Thursday Slot -->
    <div id="slot_thursday" class="tab-pane fade">
        <h4 class="card-title d-flex justify-content-between">
            <span>Time Slots</span>
            <router-link class="edit-link" data-bs-toggle="modal" to="#add_time_slot"><i class="fa fa-plus-circle"></i> Add Slot</router-link>
        </h4>
        <p class="text-muted mb-0">Not Available</p>
    </div>
    <!-- /Thursday Slot -->
</template>