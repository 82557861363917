<template>
    <!-- FAQ Section -->
    <section class="faq-section faq-section-ten">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 text-center aos mb-2" data-aos="fade-up">
            <div class="section-header-one text-center">
              <h2 class="section-title"><span>Câu hỏi</span> thường gặp?</h2>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 aos" data-aos="fade-up">
            <div class="faq-img">
              <img src="@/assets/img/phuongthanh/core/home.jpg" class="img-fluid" alt="img" />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="faq-info aos" data-aos="fade-up">
              <div class="accordion" id="faq-details">
                <!-- FAQ Item -->
                <div class="accordion-item" v-for="item in IndexfiveFaq" :key="item.id">
                  <h2 class="accordion-header" :id="item.faqid">
                    <a
                      href="javascript:void(0);"
                      :class="item.accordion"
                      data-bs-toggle="collapse"
                      :data-bs-target="item.target"
                      :aria-expanded="item.expanded"
                      :aria-controls="item.targetid"
                    >
                      {{ item.question }}
                    </a>
                  </h2>
                  <div
                    :id="item.targetid"
                    :class="item.class"
                    :aria-labelledby="item.faqid"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>{{ item.answer }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import IndexfiveFaq from "@/assets/json/indexfivefaq.json";
  import {Carousel, Pagination, Slide} from "vue3-carousel";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {
        IndexfiveFaq: IndexfiveFaq,
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },

        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    computed: {
    },
    methods: {

      next() {
        this.$refs.flickity.next();
      },
  
      previous() {
        this.$refs.flickity.previous();
      },

    },
  };
  </script>
  
  <style>
  .list-card-nk{
    padding: 10px;
  }
  
  .img-library{
    height: 300px;
  }
  
  .img-library img{
    height: 300px;
    border-radius: 10px;
  }
  .card-img{
      height: 300px;
      padding: 10px 10px 0 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .card-img div img{
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .card-img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .box-text{
      padding: 0px 10px
  }
  
  .box-info{
    height: 130px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .card-name-sen{
    position: absolute;
    background-color: #d4a04c;
    padding: 5px;
    color: #fff;
    margin: 0;
    top: 269px;
    left: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: bold;
    font-size: clamp(0.8125rem, 0.7679rem + 0.1429vw, 0.875rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .decs-cate-sen{
      font-size: 14px;
      text-align: justify;
      color: #000;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
  
  .btn-detail{
      border: 2px solid #d4a04c !important;
      background-color:#fff !important;
      color: #d4a04c !important;
      border: none;
      border-radius: 5px;
      font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem) !important;
  }
  
  .btn-detail:hover{
      background-color: #d4a04c !important;
      color: #fff !important;
  }
  
  .card-price{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-price span{
    background-color: #CCFBF1;
    color: #14B8A6;
    font-weight: bold;
    padding: 5px;
    font-size: clamp(0.625rem, 0.4911rem + 0.4286vw, 0.8125rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  </style>
  
  