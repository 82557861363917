<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <h3 class="card-title" style="float: left;">Quản lý thuốc</h3>
                    <div class="card-tools" style="float: right;">
                        <a
                            href="#info_modal"
                            data-bs-toggle="modal"
                            size="sm"
                            type="button"
                            class="btn btn-tool card-add"
                        >
                            <i class="fas fa-plus"></i>
                        </a>
                        <button type="button" class="btn btn-tool" @click="getData">
                            <i class="fas fa-retweet"></i>
                        </button>
                        <!-- <button type="button" class="btn btn-tool">
                            <i class="fas fa-minus"></i>
                        </button> -->
                    </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-2">
                        <div class="col-sm-12 col-md-12">
                            <div
                                class="col-sm-12 d-flex justify-content-left align-items-center"
                            >
                            <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center" style="color: #d4a04c;">
                                {{ this.list.length }}
                            </label>
                            trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                            </div>
                            </div>
                        </div>
                      </div>

                      <div class="custom-new-table">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                                <thead>
                                <th class="col150 cursor td-stt" style="text-align: center;">
                                    STT
                                </th>
                                <th class="col150 cursor" style="text-align: left;">
                                    Tên thuốc
                                </th>
                                <th class="col150 cursor" style="text-align: center;">
                                    Giá trị
                                </th>
                                <th class="col150 cursor" style="text-align: center;">
                                    Loại
                                </th>
                                <th class="col100 cursor td-xuly" style="text-align: center;">
                                    Xử lý
                                </th>
                            </thead>
                              <tbody>
                              <tr v-for="(item, index) in list" :key="index">
                                <td style="text-align: center">
                                  {{ index + ((currentPage-1)*perPage) + 1}}
                                </td>
                                <td style="text-align: left">
                                    {{ item.name }}
                                </td>
                                <td style="text-align: center">
                                    {{ item.value }}
                                </td>
                                <td style="text-align: left" v-if="item.type != null">
                                    {{ item.type.name}}
                                </td>
                                <td style="text-align: center">
                                    <a
                                        href="#info_modal"
                                        data-bs-toggle="modal"
                                        size="sm"
                                        type="button"
                                        class="btn btn-outline btn-sm"
                                        v-on:click="handleGetDataById(item.id)"
                                    >
                                        <i class="fas fa-pencil-alt text-success me-1"></i>
                                    </a>
                                    <a
                                        href="#delete_modal"
                                        data-bs-toggle="modal"
                                        class="btn btn-outline btn-sm"
                                        v-on:click="handleShowDeleteModal(item.id)"
                                    >
                                        <i class="fas fa-trash-alt text-danger me-1"></i>
                                    </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                      </div>
                      <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        ref="ref_info_modal"
                        >
                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Thông tin thuốc</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                </div>
                                <div class="modal-body">
                                    <Form
                                        class="login"
                                        @submit="handleSubmit"
                                        :validation-schema="schema"
                                        v-slot="{ errors }"
                                        ref="form"
                                    >
                                        <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Tên thuốc</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.name"
                                                placeholder="Vui lòng nhập tên thuốc"
                                                name="name"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.name }"
                                            />
                                                <div class="invalid-feedback">{{ errors.name }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                            <label class="text-left">Giá trị</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.value"
                                                placeholder="Vui lòng nhập giá trị"
                                                name="value"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.value }"
                                            />
                                                <div class="invalid-feedback">{{ errors.value }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <label class="text-left mb-0">Dịch vụ</label>
                                                <!-- <span style="color: red">&nbsp;*</span> -->
                                                <VueMultiselect
                                                    v-model="model.type"
                                                    :options="listType"
                                                    label="name"
                                                    placeholder="Nhấp vào để chọn"
                                                    selectLabel="Nhấn vào để chọn"
                                                    deselectLabel="Nhấn vào để xóa"
                                                >
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-2 ">
                                                    <label for="formFileSm" class="text-left mb-0">Ảnh đại diện
                                                    </label>
                                                    <span style="color: red">&nbsp;(Kích thước chuẩn : 1920px x 734px) *</span>
                                                    <template  v-if="model.avatar" >
                                                    <a
                                                        class="ml-25"
                                                        :href="`${urlFile}/${model.avatar.fileId}`"
                                                    ><i
                                                        :class="`${getColorWithExtFile(model.avatar.ext)} me-2 ${getIconWithExtFile(model.avatar.ext)}`"
                                                    ></i>{{model.avatar.fileName }}</a>

                                                    </template>
                                                    <Field
                                                        id="formFile"
                                                        name="avatar"
                                                        ref="fileupload"
                                                        type="file"
                                                        class="form-control"
                                                        @change="upload($event)"
                                                        :class="{ 'is-invalid': errors.avatar }"
                                                    />
                                                    <div class="invalid-feedback">{{ errors.avatar }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="text-left">Xuất sứ</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="origin" v-slot="{ field }">
                                                <CKEditorCustom
                                                    v-bind="field"
                                                    v-model="model.origin"
                                                    :class="{ 'is-invalid': errors.origin }"
                                                >
                                                </CKEditorCustom>
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.origin }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="text-left">Thành phần</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="ingredient" v-slot="{ field }">
                                                <CKEditorCustom
                                                    v-bind="field"
                                                    v-model="model.ingredient"
                                                    :class="{ 'is-invalid': errors.ingredient }"
                                                >
                                                </CKEditorCustom>
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.ingredient }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="text-left">Chỉ định</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="indications" v-slot="{ field }">
                                                <CKEditorCustom
                                                    v-bind="field"
                                                    v-model="model.indications"
                                                    :class="{ 'is-invalid': errors.indications }"
                                                >
                                                </CKEditorCustom>
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.indications }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="text-left">Cách dùng</label>
                                                <span style="color: red" >&nbsp;*</span>
                                                <Field name="dosage" v-slot="{ field }">
                                                <CKEditorCustom
                                                    v-bind="field"
                                                    v-model="model.dosage"
                                                    :class="{ 'is-invalid': errors.dosage }"
                                                >
                                                </CKEditorCustom>
                                                </Field>
                                                <div class="invalid-feedback">{{ errors.dosage }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="mb-3 service-upload">
                                                <label class="mb-2">File ảnh</label>
                                                <div class="col-lg-12">
                                                <div class="form-group">
                                                    <div
                                                        class="call-option file-option"
                                                        @dragover="onDragOver"
                                                        @dragleave="onDragLeave"
                                                        @drop="onFileDrop"
                                                        :class="{ 'file-over': isFileOver }"
                                                    >
                                                    <input
                                                        type="file"
                                                        id="radio1"
                                                        name="selector"
                                                        class="option-radio"
                                                        style="display: none"
                                                        ref="fileupload"
                                                        @change="onFileChange"
                                                        multiple
                                                    />
                                                    <label
                                                        for="radio1"
                                                        class="call-lable verify-lable verify-file"
                                                    >
                                                        <i v-if="!file" class="fas fa-cloud-upload-alt" style="font-size: 40px;"></i>
                                                        <div v-if="!file">Kéo và thả tệp tin tại đây</div>
                                                        <div v-else>
                                                        <img :src="filePreview" alt="Uploaded Image" />
                                                        <!-- Add Remove button using Feather Icon -->
                                                        <a
                                                            href="javascript:void(0);"
                                                            class="remove-btn"
                                                            @click="removeImage"
                                                        >
                                                            <i class="far fa-trash-alt"></i>
                                                        </a>
                                                        </div>
                                                    </label>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="upload-wrap">
                                                <div v-for="(uploadedFile, index) in uploadedFiles" :key="index" class="upload-images mx-2">
                                                <img :src="urlFile + uploadedFile.fileId" alt="">
                                                <!-- <img :src="uploadedFile.previewUrl" :alt="'Uploaded Image ' + index" /> -->
                                                <a href="javascript:void(0);" class="btn btn-icon btn-danger btn-sm" @click="removeImage(index)">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="text-end pt-2 mt-3">
                                            <b-button
                                                type="button"
                                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                                data-bs-dismiss="modal"
                                                @click="reset()"
                                            >
                                                Đóng
                                            </b-button>
                                            <b-button  type="submit" variant="success" class="ms-1 cs-btn-primary btn-submit w-md">
                                                Lưu
                                            </b-button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="modal fade"
                            id="delete_modal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            ref="ref_delete"
                        >
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="acc_title">Xóa</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                    </div>
                                    <div class="modal-body" style="font-weight: 500;">
                                        <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                                    </div>
                                    <div class="modal-footer">
                                    <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                        Xóa
                                    </b-button>
                                    <b-button
                                        type="button"
                                        class="btn si_accept_cancel btn-submit w-md btn-out"
                                        data-bs-dismiss="modal"
                                    >
                                        Đóng
                                    </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row" >
                        <div class="col-md-6 col-sm-6 mt-2">
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center">
                              <b-form-select
                                  class="form-select form-select-sm"
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  style="width: 70px; text-align: center;"
                              ></b-form-select
                              >&nbsp;
                            </label>
                            trên tổng số {{ totalRows }} dòng
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="right"
                                size="sm"
                                class="my-0"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { thuocModel } from "@/models/thuocModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import CKEditorCustom from "@/utils/view/CKEditorCustom.vue";
  export default {
    components: {
        VueDatePicker,
        Treeselect,
        VueMultiselect,
        Form,
        Field,
        CKEditorCustom,
    },
    data() {
      return {
        title: "DANH SÁCH",
        model: thuocModel.baseJson(),
        list: [],
        listType: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view/`,
        theModal: null,
        keyId : null,
        uploadedFiles: [],
        isFileOver: false,
        file: null,
        filePreview: null,
      };
    },
    name: "pharmacy/user",

    created() {
      this.getData();
      this.GetAllType();
    },
    mounted() {
        this.theModal = new Modal(document.getElementById('info_modal'));
        this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
        this.model = thuocModel.baseJson()
        this.$refs.fileupload.value = null;
        document.getElementById("formFile").value = null;
        document.getElementById("radio1").value = null;
      });
      this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
        this.model = thuocModel.baseJson()
      });
    },

    setup() {
        const schema = Yup.object().shape({
            name: Yup.string().required("Tên không được bỏ trống !"),
            
            avatar: Yup.mixed().required("Ảnh đại diện ảnh không được bỏ trống!"),
            origin: Yup.string().required("Xuất sứ không được bỏ trống !"),

        });
        return {
            schema,
        };
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        }
    },

    methods: {
        reset() {
            this.model = thuocModel.baseJson();
        },
        keyId(model){
            if(model != null && model.id){
                return id;
            }
        },

        async uploadFile(file) {
            try {
                // Tạo formData để gửi file
                const formData = new FormData();
                formData.append('files', file);
                formData.append('code', "MEDICINE")
                // Gọi API để upload file
                // const response = await axios.post(`${process.env.VUE_APP_API_URL}Files/upload`, formData);

                axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                // console.log("LOG UPDATE : ", response);
                let resultData = response.data
                if (response.data.code == 0){
                    this.model.fileImage.push({
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                    });
                    // Thu thập tất cả các tệp tin đã tải lên
                    this.uploadedFiles.forEach(uploadFile => {
                    // Thu thập thông tin từ mỗi tệp tin và thêm vào this.model
                    this.model.ext = uploadFile.ext; // Giả sử ext đã được lưu trữ trong uploadedFiles
                    this.model.fileId = uploadFile.fileId; // Giả sử fileId đã được lưu trữ trong uploadedFiles
                    this.model.fileName = uploadFile.fileName; // Giả sử fileName đã được lưu trữ trong uploadedFiles
                    });
                    this.uploadedFiles = this.model.fileImage
                    console.log("Upload thành công : ", this.model);
                }
                }).catch((error) => {
                // Handle error here
                console.error('Lỗi khi upload file:', error);
                });
                // Xử lý phản hồi từ API nếu cần
                // console.log('Upload thành công:', response.data.data);
            } catch (error) {
                console.error('Lỗi khi upload file:', error);
            }
            },
            onFileChange(event) {
                const files = event.target.files;
                console.log("FILE: ", files);
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    // this.uploadedFiles.push({ // Thêm thông tin về tệp tin vào mảng uploadedFiles
                    //     file: file,
                    //     previewUrl: URL.createObjectURL(file)
                    // });
                    // Gọi phương thức uploadFile để upload file lên API
                    this.uploadFile(file);
                    // Sau khi upload, bạn có thể thực hiện các xử lý khác ở đây nếu cần
                }
            },
            onDragOver(event) {
                event.preventDefault();
                this.isFileOver = true;
            },
            onDragLeave(event) {
                event.preventDefault();
                this.isFileOver = false;
            },
            onFileDrop(event) {
                event.preventDefault();
                this.isFileOver = false;
                const files = event.dataTransfer.files;
                this.handleFiles(files);
            },
            handleFiles(files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    // Xử lý tệp tin ở đây, ví dụ: lưu trữ vào một mảng hoặc hiển thị xem trước
                    // Ví dụ:
                    console.log("FILE: ", file);
                        this.uploadedFiles.push({
                        file: file,
                        previewUrl: URL.createObjectURL(file)
                    });
                }
            },

            removeImage(index) {
            // Xóa tệp tin khỏi mảng uploadedFiles tại chỉ mục index
            this.uploadedFiles.splice(index, 1);
            },

        async GetAllType(){
            await this.$store.dispatch("commonStore/getAll", "DM_PRODUCT_TYPE").then((res) => {
                if (res)
                {
                this.listType= res.data;
                }
            })
        },
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch("thuocStore/getPagingParams", params ).then(res => {
                this.list = res.data.data
                this.totalRows = res.data.totalRows
                this.numberOfElement = res.data.data.length
            });
        },

        async handleGetDataById(id) {
            await this.$store.dispatch("thuocStore/getById", {id : id}).then((res) => {
                if (res != null && res.code ===0) {
                //    console.log(res)
                    this.model = thuocModel.getJson(res.data);
                    this.keyId =  (res.data != null && res.data.menu != null ) ? res.data.menu : null;
                    this.$refs.form.setFieldValue('fileImage', res.data.fileImage || null);
                    this.uploadedFiles = res.data.fileImage;
                    console.log("HAHA: ", this.uploadedFiles);
                    this.model.origin = this.model.origin.replaceAll("https://api.phuongthanh.vn/api/v1/files/view/", this.url)
                    this.model.ingredient = this.model.ingredient.replaceAll("https://api.phuongthanh.vn/api/v1/files/view/", this.url)
                    this.model.indications = this.model.indications.replaceAll("https://api.phuongthanh.vn/api/v1/files/view/", this.url)
                    this.model.dosage = this.model.dosage.replaceAll("https://api.phuongthanh.vn/api/v1/files/view/", this.url)
                }
            });
        },
        handleShowDeleteModal(id) {
            this.model.id = id;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("thuocStore/delete", { 'id': this.model.id }).then((res) => {
                if (res != null && res.code ===0) {
                    this.model = thuocModel.baseJson();
                    this.getData();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async handleSubmit() {
            if (
                this.model.id != 0 &&
                this.model.id != null &&
                this.model.id
            )
            {
                await this.$store.dispatch("thuocStore/update", this.model).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.theModal.hide();
                    this.uploadedFiles = [];
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            } else {
                await this.$store.dispatch("thuocStore/create", this.model).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.theModal.hide();
                    this.uploadedFiles = [];
                }
                    this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
                this.$refs.form.setFieldValue('fileImage', null);

            }
        },

        async upload() {
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('code', "MEDICINE")
            formData.append('files', event.target.files[0])
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                let resultData = response.data
                if (response.data.code == 0){
                this.model.avatar={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
              //  console.log("LOG UPDATE : ", this.model);
                }
            })
            }
        },

        getColorWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
                return 'text-danger';

        },
        getIconWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
                return 'mdi mdi-file-image-outline';
        },
        deleteImage(){
            if (this.model != null && this.model.fileImage != null)
            {
                //console.log("LOG this.model : ", this.model)
                axios.post(`${process.env.VUE_APP_API_URL}files/delete/${this.model.fileImage.fileId}`).then((response) => {
                this.model.fileImage = null;
                // console.log('log model file remove', this.model.fileImage);
                }).catch((error) => {
                // Handle error here
                //  console.error('Error deleting file:', error);
                });
            }
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>


