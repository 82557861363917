<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-3 col-xl-3">
            <div class="stickysidebar">
              <div class="card search-filter">
                <div class="card-header">
                  <h4 class="card-title mb-0">Tìm kiếm</h4>
                </div>
                <div class="card-body">
                  <div class="filter-widget">
                    <h4>DỊCH VỤ</h4>
                    <div v-for="item in list">
                      <label class="custom_check">
                        <input type="checkbox" name="gender_type"/>
                        <span class="checkmark"></span> {{item.name}}
                      </label>
                    </div>
                  </div>
                  <div class="btn-search">
                    <b-button type="button" class="btn w-100">Tìm kiếm</b-button>
                  </div>
                </div>
              </div>
              <!-- /Search Filter -->
            </div>
          </div>
          <danh-sach-dich-vu></danh-sach-dich-vu>
        </div>
      </div>
    </div>
    <indexfivefooter></indexfivefooter>
  </div>
</template>
<script>
import DanhSachDichVu from "@/views/News/san_Pham/danhSachDichVu.vue";
export default {
  components: {DanhSachDichVu},
  data() {
    return {
      title: "Dịch vụ",
      text: "Trang chủ",
      text1: "Dịch vụ",
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(){
      await  this.$store.dispatch("serviceStore/getAllSelected").then((res) =>{
        this.list = res.data || [];
      })
    },

  }
};
</script>
