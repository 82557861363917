
  const getJson = (item) => {
    return {
      id: item.id,
      companyName: item.companyName,
      address: item.address,
      email: item.email,
      phone: item.phone,
      manager: item.manager,
      logo: item.logo,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      companyName: item.companyName,
      address: item.address,
      email: item.email,
      phone: item.phone,
      manager: item.manager,
      logo: item.logo,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      companyName: null,
      address: null,
      email: null,
      phone: null,
      manager: null,
      logo: null,
    };
  };


  export const footerModel = {
    sendJson , getJson, baseJson,
  };
