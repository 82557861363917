const getJson = (item) => {
    return {
      id: item.id,
      fileImage: item.fileImage,
      sort: item.sort,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      fileImage: item.fileImage,
      sort: item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      fileImage: null,
      sort: 0,
    };
  };


  export const headerModel = {
    sendJson , getJson, baseJson,
  };
