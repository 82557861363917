const getJson = (item) => {
    return {
        id: item.id,
      name: item.name,
      icon: item.icon,
      parentId: item.parentId,
      menuId: item.menuId,
      sort: item.sort,
    };
  };

  const sendJson = (item) => {
    return {
        id: item.id,
      name: item.name,
      icon: item.icon,
      parentId: item.parentId,
      menuId: item.menuId,
      sort: item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      icon: null,
      parentId: null,
      menuId: null,
      isMenuCitizen : false,
      sort: 0,
    };
  };

  export const menuFooterModel = {
    sendJson , getJson, baseJson,
  };
