<template>
  <!-- Blog Sidebar -->
  <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Search -->
      <div class="card post-widget"  v-for="(item, index) in newList">
        <div class="card-news-sidebar" >
          <h4 class="card-title" style="margin-bottom: 0px;  font-size: 14px;">{{item.name}}</h4>
        </div>
        <div class="card-body" style="padding: 10px;">

          <div class="row mb-2" v-for="news in item.newsList" :key="item.id">
              <div class="col-md-4">
                <router-link :to="{
                        path: `/ban-tin-mobi/${news.id}`,
                    }">
                  <img
                      v-if="news.fileImage"
                      class="img-fluid img-sidebar"
                      :src="news.fileImage.urlFile"
                      alt="Post Image"
                  />
                </router-link>

              </div>
              <div class="col-md-8">
                <router-link :to="{
                        path: `/ban-tin-mobi/${news.id}`,
                    }">
                  <div class="title-sidebar">
                    <p style="
                    font-size: 14px;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: 'Nunito Bold';
                    margin-bottom: 0px;"
                       class=""
                    >
                      {{ news.name }}
                    </p>
                    <p style="font-size: 12px;">
                      {{ news.publicationDateShow}}
                    </p>
                  </div>
                </router-link>

              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Blog Sidebar -->
</template>

<script>
import BlogSidebar from "@/assets/json/blogsidebar.json";
export default {
  data() {
    return {
      BlogSidebar: BlogSidebar,
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      newList: [],
    };
  },
  created() {
    this.getData();
  },

  methods: {
    async getData() {
      let params = {
        start: 0,
        limit: 4,
      }
      await this.$store.dispatch("newsStore/getListRelatedNews", params ).then(res => {
        this.newList = res.data;
      });
    },

  },
};
</script>

<style>
.card-news-sidebar{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #be8b43;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
