const getJson = (item) => {
    return {
        id: item.id,
      name: item.name,
      path: item.path,
      icon: item.icon,
      parentId: item.parentId,
      sort: item.sort,
    };
  };

  const sendJson = (item) => {
    return {
        id: item.id,
      name: item.name,
      path: item.path,
      icon: item.icon,
      parentId: item.parentId,
      sort: item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      path: null,
      icon: null,
      parentId: null,
      isMenuCitizen : false,
      sort: 0,
    };
  };

  export const menuModel = {
    sendJson , getJson, baseJson,
  };
