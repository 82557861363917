<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <pharmacybreadcrumb2 :title="title" />
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="mb-3 col-md-6">
                    <label>Nội dung</label>
                    <input
                        v-model="itemFilter.userName"
                        type="text"
                        name="untyped-input"
                        class="form-control"
                        placeholder="Tìm kiếm theo tên tài khoản"
                        style="height: 39px;"
                    />
                  </div>
                  <div class="mb-3 col-md-6 multi-role">
                    <label>Quyền</label>
                    <VueMultiselect
                        v-model="itemFilter.unitRole"
                        :options="listRole"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                      >
                    </VueMultiselect>
                    <!-- <treeselect
                        :options="listRole"
                        v-model="itemFilter.menuId"
                        :default-expand-level="1"
                        :normalizer="normalizer"
                        v-on:select="addNodeToModel"
                    >
                    </treeselect> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <b-button variant=""
                              class="w-10 btn-search"
                              style="margin-right: 10px ; height: 40px ; width: 130px; font-size: 14px; background-color: #d4a04c; border: none; color: #000 !important; font-weight: bold;"
                              size="sm"
                              @click="handleSearch"
                    >
                      <i class="fa fa-search font-size-16 align-middle me-2" aria-hidden="true"></i>
                      Tìm kiếm
                    </b-button>
                    <b-button
                        class="w-10 btn-reset"
                        style="margin-right: 10px; height: 40px ; width: 130px; font-size: 14px; background-color: #d4a04c; border: none; color: #000 !important; font-weight: bold;"
                        size="sm"
                        @click="handleClear"
                    >
                      <i class="fa fa-refresh font-size-16 align-middle me-2" aria-hidden="true"></i>
                      Làm mới
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-2">
                      <div class="col-sm-8 col-md-8 d-flex justify-content-left align-items-center">
                        <div
                            class="col-sm-12 d-flex justify-content-left align-items-center"
                        >
                        <div>
                          Hiển thị
                          <label class="d-inline-flex align-items-center" style="color: #d4a04c;">
                            {{ this.listUser.length }}
                          </label>
                          trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                        </div>
                        </div>
                      </div>
                      <div class="col-md-4" style="display: flex; justify-content: flex-end; align-items: center;">
                        <div class="card-tools">
                          <a
                              href="#info_modal"
                              data-bs-toggle="modal"
                              size="sm"
                              type="button"
                              class="btn btn-tool card-add"
                          >
                            <i class="fas fa-plus"></i>
                          </a>
                          <button type="button" class="btn btn-tool" @click="getData">
                            <i class="fas fa-retweet"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="custom-new-table">
                      <div class="table-responsive">
                      <table class="table table-hover table-center mb-0">
                        <thead>
                        <th class="col150 cursor td-stt" style="text-align: center;">
                          STT
                        </th>
                        <th class="col150 cursor" style="text-align: center;">
                          Tài khoản
                        </th>
                        <th class="col100 cursor" style="text-align: center;">
                          Họ và tên
                        </th>
                        <th class="col150 cursor" style="text-align: center;">
                          Quyền
                        </th>
                        <th class="col100 cursor td-xuly-user" style="text-align: center;">
                          Xử lý
                        </th>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in listUser" :key="index">
                          <td style="text-align: center">
                            {{ index + ((currentPage-1)*perPage) + 1}}
                          </td>
                          <td style="text-align: left">
                            {{ item.userName }}
                          </td>
                          <td style="text-align: left">
                            {{ item.fullName }}
                          </td>
                          <td style="text-align: center">
                            {{ item.unitRole.name}}
                          </td>
                          <td style="text-align: center">
                            <a
                                href="#reset_user"
                                data-bs-toggle="modal"
                                class="btn btn-outline btn-sm"
                                v-on:click="handleShowResetModal(item.id)"
                            >
                              <i class="fas fa-rotate text-danger me-1"></i>
                            </a>
                            <a
                                href="#info_modal"
                                data-bs-toggle="modal"
                                size="sm"
                                type="button"
                                class="btn btn-outline btn-sm"
                                v-on:click="handleGetInfo(item.id)"
                            >
                              <i class="fas fa-pencil-alt text-success me-1"></i>
                            </a>
                            <a
                                href="#delete_modal"
                                data-bs-toggle="modal"
                                class="btn btn-outline btn-sm"
                                v-on:click="handleShowDeleteModal(item.id)"
                            >
                              <i class="fas fa-trash-alt text-danger me-1"></i>
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>

                    <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        ref="ref_info_modal"
                    >
                      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Thông tin tài khoản</h5>
                            <b-button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></b-button>
                          </div>
                          <div class="modal-body">
                            <Form
                                class="login"
                                @submit="handleSubmit"
                                :validation-schema="schema"
                                v-slot="{ errors, meta }"
                                ref="form"
                            >
                              <div class="row">
                                <div class="col-12">
                                  <div class="mb-3">
                                    <label >Họ và Tên</label>
                                    <span style="color: red">&nbsp;*</span>
                                    <Field
                                        placeholder="Vui lòng nhập họ và tên"
                                        name="fullName"
                                        v-model="model.fullName"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.fullName && meta.touched && !meta.valid }"
                                    />
                                    <div class="invalid-feedback">{{ errors.fullName }}</div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label class="text-left">Tên tài khoản</label>
                                    <span style="color: red">&nbsp;*</span>
                                    <Field
                                        v-model="model.userName"
                                        placeholder="Vui lòng nhập tên tài khoản"
                                        name="userName"
                                        type="text"
                                        class="form-control"
                                        :disabled = isView
                                        :class="{ 'is-invalid': errors.userName && meta.touched && !meta.valid }"
                                    />
                                    <div class="invalid-feedback">{{ errors.userName }}</div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label class="text-left">Số điện thoại</label>
                                    <input type="hidden" v-model="model.id"/>
                                    <input
                                        v-model="model.phoneNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Nhập số điện thoại"
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label class="text-left">Email</label>
                                    <Field
                                        placeholder="Vui lòng nhập Email"
                                        name="email"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.email && meta.touched && !meta.valid }"
                                    />
                                    <div class="invalid-feedback">{{ errors.email }}</div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label class="text-left">Vai trò</label>
                                    <span style="color: red">&nbsp;*</span>
                                    <!-- <div>{{errors}}</div>
                                    <div>{{model.unitRole}}</div> -->
                                    <Field
                                        name="unitRole"
                                        v-slot="{ field}"
                                    >
                                      <VueMultiselect
                                          v-bind="field"
                                          v-model="model.unitRole"
                                          :options="listRole"
                                          label="name"
                                          placeholder="Nhấp vào để chọn"
                                          selectLabel="Nhấn vào để chọn"
                                          deselectLabel="Nhấn vào để xóa"
                                          track-by="id"
                                          :class="{ 'is-invalid': errors.unitRole }"
                                      >
                                      </VueMultiselect>
                                      <div class="invalid-feedback">{{ errors.unitRole}}</div>
                                    </Field>


                                  </div>
                                </div>

                              </div>
                              <div class="text-end pt-2 mt-3">
                                <b-button
                                    type="button"
                                    class="btn si_accept_cancel btn-submit w-md btn-out"
                                    data-bs-dismiss="modal"
                                >
                                  Đóng
                                </b-button>
                                <b-button  type="submit" variant="success" class="btn-submit w-md ms-1 cs-btn-primary">
                                  Lưu
                                </b-button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                        class="modal fade "
                        id="delete_modal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        ref="ref_delete"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="acc_title">Xóa</h5>
                            <b-button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></b-button>
                            </div>
                            <div class="modal-body" style="font-weight: 500;">
                                <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                            </div>
                            <div class="modal-footer">
                            <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                Xóa
                            </b-button>
                            <b-button
                                type="button"
                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                data-bs-dismiss="modal"
                            >
                                Đóng
                            </b-button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div
                        class="modal fade "
                        id="reset_user"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="acc_title">Bạn có chắc?</h5>
                            <b-button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></b-button>
                            </div>
                            <div class="modal-body" style="font-weight: 500;">
                                <p id="acc_msg">Bạn chắc chắn muốn đặt mật khẩu lại mặc định: PhuongThanh#2o24</p>
                            </div>
                            <div class="modal-footer">
                            <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleReset" data-bs-dismiss="modal">
                                Khôi phục
                            </b-button>
                            <b-button
                                type="button"
                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                data-bs-dismiss="modal"
                            >
                                Đóng
                            </b-button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="col-md-6 col-sm-6 mt-2">
                        <div>
                          Hiển thị
                          <label class="d-inline-flex align-items-center">
                            <b-form-select
                                class="form-select form-select-sm"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                style="width: 70px; text-align: center;"
                            ></b-form-select
                            >&nbsp;
                          </label>
                          trên tổng số {{ totalRows }} dòng
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="right"
                            size="sm"
                            class="my-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { userModel } from "@/models/userModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import {notifyModel} from "@/models/notifyModel";

export default {
  components: {
    VueDatePicker,
    Treeselect,
    VueMultiselect,
    Form,
    Field,
  },
  data() {
    return {
      title: "DANH SÁCH",
      model: userModel.baseJson(),
      listUser: [],
      listRole: [],
      currentPage: 1,
      numberOfElement: 1,
      perPage: 5,
      pageOptions: [ 5, 10, 25, 50, 100],
      totalRows: 1,
      sortBy: 'age',
      sortDesc: false,
      theModal: null,
      isView: false,
      itemFilter: {
        userName : null,
        unitRole : null
      },
    };
  },
  name: "pharmacy/user",

  created() {
    this.getData();
    this.getListRole();
  },
  mounted() {
    this.theModal = new Modal(document.getElementById('info_modal'));

    this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
      this.model = userModel.baseJson();
      this.$refs.form.setFieldValue('unitRole', this.model.unitRole);
    });
    this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
      this.model = userModel.baseJson();
      this.$refs.form.setFieldValue('unitRole', this.model.unitRole);
    });
  },
  setup() {
    const schema = Yup.object().shape({
      userName: Yup.string().required("Tài khoản không được bỏ trống !"),
      fullName : Yup.string().required("Họ và tên không được bỏ trống !"),
      unitRole : Yup.mixed().required("Vai trò không được bỏ trống !"),
    });

    return {
      schema
    };
  },
  watch: {
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    }
  },

  methods: {
    handleClear() {
      this.itemFilter = {
        userName : null,
        unitRole : null
      }
    },
    handleSearch() {
      this.getData();
    },
    async getData() {
      let params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: this.sortBy,
        userName: this.itemFilter.userName,
        unitRole : this.itemFilter.unitRole,
      }
      await this.$store.dispatch("userStore/getPagingParams", params ).then(res => {
            if (res != null && res.code ===0) {
              this.listUser = res.data.data
              this.totalRows = res.data.totalRows
              this.numberOfElement = res.data.data.length
            }
      });
    },

    async getListRole(){
      await  this.$store.dispatch("unitRoleStore/getAll").then((res) =>{
            if (res != null && res.code ===0) {
              this.listRole = res.data || [];
            }
      })
    },
    async handleGetInfo(id) {
      await this.$store.dispatch("userStore/getById", {id : id}).then((res) => {
        if (res != null && res.code ===0) {
          this.model = userModel.getJson(res.data);
          this.$refs.form.setFieldValue('unitRole', res.data.unitRole);
        }
      });
    },
    handleShowDeleteModal(id) {
      this.model.id = id;
      this.showDeleteModal = true;
    },
    handleShowResetModal(id) {
      this.model.id = id;
      this.showResetModal = true;
    },
    async handleDelete() {
      if (this.model.id != 0 && this.model.id != null && this.model.id) {
        await this.$store.dispatch("userStore/delete", { 'id': this.model.id }).then((res) => {
          if (res != null && res.code ===0) {
            this.showDeleteModal = false;
            this.getData();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }
    },
    async handleReset() {
      if (this.model.id != 0 && this.model.id != null && this.model.id) {
        await this.$store.dispatch("userStore/reset", { 'id': this.model.id }).then((res) => {
          if (res != null && res.code ===0) {
            this.showResetModal = false;
            this.getData();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }
    },
    async handleSubmit() {
      if (
          this.model.id != 0 &&
          this.model.id != null &&
          this.model.id
      ) {
        await this.$store.dispatch("userStore/update", this.model).then((res) => {
          if (res != null && res.code ===0) {
            this.getData();
            this.model= userModel.baseJson()
            this.theModal.hide();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      } else {
        await this.$store.dispatch("userStore/create", this.model).then((res) => {
          if (res != null && res.code ===0) {
            this.getData();
            this.model= userModel.baseJson()
            this.theModal.hide();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });

      }

    },

  }
};
</script>

