
const getJson = (item , listMenu) => {
    return {
        id: item.id,
        name: item.name,
        isPublic: item.isPublic,
        sort: item.sort,
    }
}
const sendJson = (item) => {
    return {
        id: item.id,
        name: item.name,
        isPublic: item.isPublic,
        sort: item.sort,
    }
}

const baseJson = () => {
    return {
        id: null,
        name: null,
        isPublic: true,
        sort: 0,
    }
}


export const  newsMobiModel = {
    getJson, sendJson, baseJson
}
