const getJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      gender: item.gender,
      avatar: item.avatar,
      birthday: item.birthday,
      placeOfBirth: item.placeOfBirth,
      married: item.married,
      address: item.address,
      phone: item.phone,
      email: item.email,
      cccd: item.cccd,
      dateCCCD: item.dateCCCD,
      // dateStartWork: item.dateStartWork,
      // height: item.height,
      // weight: item.weight,
      // statusWork: item.statusWork,
      // noBHXH: item.noBHXH,
      // dateBHXH: item.dateBHXH,
      // issueByBHXH: item.issueByBHXH,
      // noBHTN: item.noBHTN,
      noBHYT: item.noBHYT,
      fromDateBHYT: item.fromDateBHYT,
      toDateBHYT: item.toDateBHYT,
      note: item.note,
      // salary: item.salary,
      // noDegree: item.noDegree,
      // dateDegree: item.dateDegree,
      noCCHN: item.noCCHN,
      dateCCHN: item.dateCCHN,
      department: item.department,
      room: item.room,
      unitGroup: item.unitGroup,
      service: item.service,
      prizes: item.prizes,
      experiences: item.experiences,
      educations: item.educations,
      majors: item.majors,
      level: item.level,
      decribe : item.decribe,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      gender: item.gender,
      avatar: item.avatar,
      birthday: item.birthday,
      placeOfBirth: item.placeOfBirth,
      married: item.married,
      address: item.address,
      phone: item.phone,
      email: item.email,
      cccd: item.cccd,
      dateCCCD: item.dateCCCD,
      // dateStartWork: item.dateStartWork,
      // height: item.height,
      // weight: item.weight,
      // statusWork: item.statusWork,
      // noBHXH: item.noBHXH,
      // dateBHXH: item.dateBHXH,
      // issueByBHXH: item.issueByBHXH,
      // noBHTN: item.noBHTN,
      noBHYT: item.noBHYT,
      fromDateBHYT: item.fromDateBHYT,
      toDateBHYT: item.toDateBHYT,
      note: item.note,
      // salary: item.salary,
      // noDegree: item.noDegree,
      // dateDegree: item.dateDegree,
      noCCHN: item.noCCHN,
      dateCCHN: item.dateCCHN,
      department: item.department,
      room: item.room,
      unitGroup: item.unitGroup,
      service: item.service,
      prizes: item.prizes,
      experiences: item.experiences,
      educations: item.educations,
      majors: item.majors,
      level: item.level,
      decribe : item.decribe,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      gender: null,
      avatar: null,
      birthday: null,
      placeOfBirth: null,
      married: null,
      address: null,
      phone: null,
      email: null,
      cccd: null,
      dateCCCD: null,
      // dateStartWork: null,
      // height: 0,
      // weight: 0,
      // statusWork: true,
      // noBHXH: null,
      // dateBHXH: null,
      // issueByBHXH: null,
      // noBHTN: null,
      noBHYT: null,
      fromDateBHYT: null,
      toDateBHYT: null,
      note: null,
      // salary: 0,
      // noDegree: null,
      // dateDegree: null,
      noCCHN: null,
      dateCCHN: null,
      department: null,
      room: null,
      unitGroup: null,
      service: [],
      prizes: [],
      experiences: [],
      educations: [],
      majors: null,
      level: null,
      decribe : null,
    };
  };


  export const doctorModel = {
    sendJson , getJson, baseJson,
  };
