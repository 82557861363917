<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <pharmacybreadcrumb2 :title="title" />
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="mb-3 col-lg-3">
                      <label>Nội dung</label>
                      <input
                          v-model="itemFilter.content"
                          type="text"
                          name="untyped-input"
                          class="form-control"
                          placeholder="Tìm kiếm theo tiêu đề"
                          style="height: 39px;"
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>Thời gian bắt đầu</label>
                      <VueDatePicker
                          v-model="itemFilter.ngayBatDau"
                          :enable-time-picker="false"
                          :locale="locale"
                          :format="format"
                          auto-apply
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>Thời gian kết thúc</label>
                      <VueDatePicker
                          v-model="itemFilter.ngayKetThuc"
                          :enable-time-picker="false"
                          :locale="locale"
                          :format="format"
                          auto-apply
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>Chuyên mục tin</label>
                      <treeselect
                          :options="treeView"
                          v-model="itemFilter.menuId"
                          :default-expand-level="1"
                          :normalizer="normalizer"
                          v-on:select="addNodeToModel"
                          placeholder="Nhấp vào để chọn"
                      >
                      </treeselect>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <b-button variant=""
                                class="w-10 btn-search"
                                style="margin-right: 10px ; height: 40px ; width: 130px; font-size: 14px; background-color: #d4a04c; border: none; color: #000 !important; font-weight: bold;"
                                size="sm"
                                @click="handleSearch"
                      >
                        <i class="fa fa-search font-size-16 align-middle me-2" aria-hidden="true"></i>
                        Tìm kiếm
                      </b-button>
                      <b-button
                          class="w-10 btn-reset"
                          style="margin-right: 10px; height: 40px ; width: 130px; font-size: 14px; background-color: #d4a04c; border: none; color: #000 !important; font-weight: bold;"
                          size="sm"
                          @click="handleClear"
                      >
                        <i class="fa fa-refresh font-size-16 align-middle me-2" aria-hidden="true"></i>
                        Làm mới
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-2">
                        <div class="col-sm-12 col-md-12">
                          <div
                              class="col-sm-12 d-flex justify-content-left align-items-center"
                          >
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center" style="color: #d4a04c;">
                              {{ this.listItem.length }}
                            </label>
                            trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                          </div>
                          </div>
                        </div>
                      </div>
                      <div class="custom-new-table">
                        <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                          <thead>
                          <th style="text-align: center">
                            STT
                          </th>
                          <th style="text-align: center">
                            Tiêu đề
                          </th>
                          <!-- <th style="text-align: center">
                            Chuyên mục tin Web
                          </th> -->
                          <th style=" text-align: center">
                            Ngày giờ đăng
                          </th>
                          <th style="text-align: center">
                            Trạng thái
                          </th>
                          <th style="text-align: center">
                            Xử lý
                          </th>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in listItem" :key="index">
                            <td style="text-align: center">
                              {{ index + ((currentPage-1)*perPage) + 1}}
                            </td>
                            <td style="text-align: left">
                              <p style="margin-bottom: 0;">
                                {{ item.name }}
                              </p>
                              <div>
                                <span class="badge bg-success" v-if="item.menu">
                                  {{item.menu.name}}
                                </span>
                                <span  class="badge bg-primary ms-1" v-if="item.menuMobi">
                                  {{item.menuMobi.name}}
                                </span>
                              </div>
                            </td>
                            <td style="text-align: center">
                              {{ item.createdAtShow}}
                            </td>
                            <td style="text-align: center"  >
                              <span v-if="item.isPublish" class="badge bg-success">Xuất bản</span>
                              <span v-else class="badge bg-danger">Không xuất bản</span>
                            </td>
                            <td style="text-align: center">
                              <button
                                  type="button"
                                  size="sm"
                                  class="btn btn-outline btn-sm"
                                  v-on:click="handleLink(item.urlWeb)"
                              >
                                <i class="fas fa-eye text-orange me-1"></i>
                              </button>
                              <router-link
                                  :to="{
                                      path: `/quan-tri/danh-sach/chi-tiet/${item.id}`,
                                    }">
                                <button
                                    type="button"
                                    size="sm"
                                    class="btn btn-outline btn-sm"
                                >
                                  <i class="fas fa-pencil-alt text-success me-1"></i>
                                </button>
                              </router-link>
                              <a
                                  href="#delete_user"
                                  data-bs-toggle="modal"
                                  class="btn btn-outline btn-sm"
                                  v-on:click="handleShowDeleteModal(item.id)"
                              >
                                  <i class="fas fa-trash-alt text-danger me-1"></i>
                              </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      </div>

                      <div
                          class="modal fade"
                          id="delete_user"
                          tabindex="-1"
                          role="dialog"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="acc_title">Xóa</h5>
                                <b-button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></b-button>
                                </div>
                                <div class="modal-body" style="font-weight: 500;">
                                    <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                                </div>
                                <div class="modal-footer">
                                <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                    Xóa
                                </b-button>
                                <b-button
                                    type="button"
                                    class="btn si_accept_cancel btn-submit w-md btn-out"
                                    data-bs-dismiss="modal"
                                >
                                    Đóng
                                </b-button>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div class="row" >
                        <div class="col-md-6 col-sm-6 mt-2">
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center">
                              <b-form-select
                                  class="form-select form-select-sm"
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  style="width: 70px; text-align: center;"
                              ></b-form-select
                              >&nbsp;
                            </label>
                            trên tổng số {{ totalRows }} dòng
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                          <b-pagination
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="right"
                              size="sm"
                              class="my-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

  import VueDatePicker from '@vuepic/vue-datepicker';
  import {newsModel} from "@/models/newsModel";
  import Treeselect from 'vue3-treeselect';
  import {notifyModel} from "@/models/notifyModel";
  export default {
    components: {
      VueDatePicker,
      Treeselect,
    },
    data() {
      return {
        title: "DANH SÁCH BÀI VIẾT",
        treeView: [],
        listMenuMobi: [],
        listItem: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        model: newsModel.baseJson(),
        itemFilter: {
          content : null,
          ngayBatDau: null,
          ngayKetThuc: null,
          menuId : null
        },
        locale: 'vi',
        format : `dd/mm/yyyy`,
      };
    },
    name: "pharmacy/user",

    created() {
      this.GetTreeList();
      this.getData();
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        }
    },

    methods: {
      handleClear() {
        this.itemFilter = {
          content : null,
          ngayBatDau: null,
          ngayKetThuc: null,
          menuId : null
        }
      },
      handleSearch() {
        this.getData();
        },
      async getData() {
        let params = {
          start: this.currentPage,
          limit: this.perPage,
          sortBy: this.sortBy,
          content: this.itemFilter.content,
          menuId : this.itemFilter.menuId,
          startDate: this.itemFilter.ngayBatDau
              ? new Date(this.itemFilter.ngayBatDau)
              : null,
          endDate:this.itemFilter.ngayKetThuc
              ? new Date(this.itemFilter.ngayKetThuc)
              : null,
        }
        await this.$store.dispatch("newsStore/getPagingParams", params ).then(res => {
          if (res != null && res.code ===0 ){
            this.listItem = res.data.data
            this.totalRows = res.data.totalRows
            this.numberOfElement = res.data.data.length
          }
        });
      },
      async GetTreeList(){
        await this.$store.dispatch("menuCongDanStore/getTreeList").then((res) => {
          if (res != null && res.code ===0 )
          {
            this.treeView = res.data;
          }
        })
      },
      handleShowDeleteModal(id) {
  //      console.log("DELETE: ", this.model);
        this.model.id = id;
        this.showDeleteModal = true;
      },
      handleLink(item) {
        window.open(item)
      },
      async handleDelete() {
          if (this.model.id != 0 && this.model.id != null && this.model.id) {
              await this.$store.dispatch("newsStore/delete", { 'id': this.model.id }).then((res) => {
              if (res != null && res.code ===0) {
                  this.showDeleteModal = false;
                  this.getData();
              }
              this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
              });
          }
      },
      addNodeToModel(node ){
        if(node != null && node.id){
        }
      },
      normalizer(node){
        if(node.children == null || node.children == 'null'){
          delete node.children;
        }
      },
      myProvider () {
       return [];
      }
    }
  };
  </script>
