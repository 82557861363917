<template>
  <div role="tabpanel" id="doc_overview" class="tab-pane fade show active">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <!-- About Details -->
        <div class="widget about-widget">
<!--          <h4 class="widget-title"></h4>-->
          <p>
            Bác sĩ Cao Nữ Hoàng Oanh hiện là bác sĩ Da liễu tại Phòng khám chuyên khoa Da liễu Doctor Acnes.
            Bác sĩ Oanh chuyên khám và điều trị các bệnh da như mụn trứng cá, nấm da, viêm da tiếp xúc,
            viêm nang lông, dày sừng nang lông, các vấn đề sau mụn: thâm mụn, sẹo mụn, sẹo rỗ, sẹo lồi, dị ứng da,
            sẩn ngứa, vảy nến, chàm, lão hóa da, các bệnh lý da liễu khác.
          </p>
        </div>
        <!-- /About Details -->

        <!-- Education Details -->
        <div class="widget education-widget">
          <h4 class="widget-title">HỌC VẤN</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" class="name">Đại Học Y Dược Thành Phố Hồ Chí Minh (UMP) </a>
                    <div>ĐẠI HỌC</div>
                    <span class="time">2015 - 2020</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" class="name">Đại Học Y Dược Thành Phố Hồ Chí Minh (UMP) </a>
                    <div>CHUYÊN KHOA I</div>
                    <span class="time">2022 - 2025</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Education Details -->

        <!-- Experience Details -->
        <div class="widget experience-widget">
          <h4 class="widget-title">KINH NGHIỆM LÀM VIỆC</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" class="name">Bệnh viện đa khoa sa đéc</a>
                    <span class="time">2015 - 2020 (5 Năm)</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" class="name">Bệnh viện đa khoa sa đéc</a>
                    <span class="time">2020 - Hiện tại (4 Năm)</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Experience Details -->

        <!-- Awards Details -->
        <div class="widget awards-widget">
          <h4 class="widget-title">GIẢI THƯỞNG</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <p class="exp-year">Tháng 7 Năm 2023</p>
                    <h4 class="exp-title">
                      Giải thưởng Doanh nhân trẻ khởi nghiệp xuất sắc năm 2023
                    </h4>
                    <p>
                      Bác sĩ CK1 Phạm Kim Thành, người sáng lập Nha khoa Phương Thành cũng là Bí thư Chi bộ Nha khoa Phương Thành
                      đã vinh dự nhận giải thưởng Doanh nhân trẻ khởi nghiệp xuất sắc năm 2023.
                      Chương trình do Hội Doanh nhân trẻ Việt Nam tổ chức vào tháng 7.2023 tại Hà Nội.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <p class="exp-year">Tháng 5 Năm 2024</p>
                    <h4 class="exp-title">Giải thưởng Chuyên gia Nha khoa của Năm</h4>
                    <p>
                      Bác sĩ CK1 Phạm Kim Thành, người sáng lập Nha khoa Phương Thành cũng là Bí thư Chi bộ
                      Nha khoa Phương Thành đã vinh dự nhận giải thưởng vào năm 2024
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Awards Details -->

        <!-- Services List -->
        <div class="service-list">
          <h4>DỊCH VỤ</h4>
          <ul class="clearfix">
            <li>Cấy ghép implant</li>
            <li>Niềng răng chỉnh nha</li>
            <li>Bọc răng sứ thẩm mỹ </li>
            <li>Tẩy trắng răng</li>
            <li>Trám răng thẩm mỹ</li>
            <li>Nội nha chữa tuỷ</li>
          </ul>
        </div>
        <!-- /Services List -->

        <!-- Specializations List -->
        <div class="service-list">
          <h4>CHUYÊN NGÀNH</h4>
          <ul class="clearfix">
            <li>Bác sĩ chỉnh nha</li>
            <li>Phẫu thuật miệng và hàm mặt</li>
            <li>Chân răng giả</li>
          </ul>
        </div>
        <!-- /Specializations List -->
      </div>
    </div>
  </div>
</template>
