<template>
  <section class="faq-sec-fourteen">
    <div class="container">
      <div class="section-head-fourteen">
        <h2>Have any <span> Questions? </span></h2>
        <p>No Extra charges, No Surprise Fees</p>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="faq-main-cards" id="accordionExample">
            <div class="faq-card">
              <div class="faq-title">
                <a data-bs-toggle="collapse" href="#faqOne" aria-expanded="false">
                  <span>Q</span>
                  <div class="faq-content">
                    <h6>Is assisted living for me?</h6>
                    <div
                      id="faqOne"
                      class="card-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        Despite your best efforts to provide elder care at home, you may
                        find that the level of care required is simply too demanding. With
                        increase in dependency, you may find that you’re unable to provide
                        assistance yourself or through at-home caregivers. In such a
                        situation, an assisted living facility is the best option. A team
                        of skilled and experienced caregivers are better suited to be able
                        to take care of your loved ones’ every need.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="faq-card">
              <div class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqtwo"
                  aria-expanded="false"
                >
                  <span>Q</span>
                  <div class="faq-content">
                    <h6>Is assisted living for me?</h6>
                    <div
                      id="faqtwo"
                      class="card-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        An otolaryngologist is a doctor who specializes in the diagnosis
                        and treatment of ear, nose and throat diseases as well as related
                        structures of the head and neck. Otolaryngologists are also
                        referred to as ENT doctors or physicians. For more information
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="faq-card">
              <div class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqthree"
                  aria-expanded="false"
                >
                  <span>Q</span>
                  <div class="faq-content">
                    <h6>Is there a doctor on the premises?</h6>
                    <div
                      id="faqthree"
                      class="card-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        An otolaryngologist is a doctor who specializes in the diagnosis
                        and treatment of ear, nose and throat diseases as well as related
                        structures of the head and neck. Otolaryngologists are also
                        referred to as ENT doctors or physicians. For more information
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="faq-card">
              <div class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfour"
                  aria-expanded="false"
                >
                  <span>Q</span>
                  <div class="faq-content">
                    <h6>How can we pay for long-term care?</h6>
                    <div
                      id="faqfour"
                      class="card-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        An otolaryngologist is a doctor who specializes in the diagnosis
                        and treatment of ear, nose and throat diseases as well as related
                        structures of the head and neck. Otolaryngologists are also
                        referred to as ENT doctors or physicians. For more information
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="faq-card">
              <div class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfive"
                  aria-expanded="false"
                >
                  <span>Q</span>
                  <div class="faq-content">
                    <h6>What is Respite Care?</h6>
                    <div
                      id="faqfive"
                      class="card-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        An otolaryngologist is a doctor who specializes in the diagnosis
                        and treatment of ear, nose and throat diseases as well as related
                        structures of the head and neck. Otolaryngologists are also
                        referred to as ENT doctors or physicians. For more information
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="faq-sec-imgs">
            <span class="faq-img-one"
              ><img src="@/assets/img/faq-sec-img-01.png" alt="Img"
            /></span>
            <span class="faq-img-two"
              ><img src="@/assets/img/faq-sec-img-02.png" alt="Img"
            /></span>
            <span class="faq-img-three"
              ><img src="@/assets/img/faq-sec-img-03.png" alt="Img"
            /></span>
            <span class="faq-img-four"
              ><img src="@/assets/img/faq-sec-img-04.png" alt="Img"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
