<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <h3 class="card-title" style="float: left;">Quản lý tư vấn</h3>
                    <div class="card-tools" style="float: right;">
                        <a
                            href="#info_modal"
                            data-bs-toggle="modal"
                            size="sm"
                            type="button"
                            class="btn btn-tool card-add"
                        >
                            <i class="fas fa-plus"></i>
                        </a>
                        <button type="button" class="btn btn-tool" @click="getData">
                            <i class="fas fa-retweet"></i>
                        </button>
                        <!-- <button type="button" class="btn btn-tool">
                            <i class="fas fa-minus"></i>
                        </button> -->
                    </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-2">
                        <div class="col-sm-12 col-md-12">
                            <div
                                class="col-sm-12 d-flex justify-content-left align-items-center"
                            >
                            <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center" style="color: #d4a04c;">
                                {{ this.list.length }}
                            </label>
                            trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                            </div>
                            </div>
                        </div>
                      </div>

                      <div class="custom-new-table">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                                <thead>
                                <th class="col150 cursor td-stt" style="text-align: center;">
                                    STT
                                </th>
                                <th class="col150 cursor" style="text-align: left;">
                                    Họ và tên
                                </th>
                                <th class="col150 cursor" style="text-align: center;">
                                    Số điện thoại
                                </th>
                                <th class="col150 cursor" style="text-align: center;">
                                    Email
                                </th>
                                <th class="col150 cursor" style="text-align: center;">
                                    Nội dung
                                </th>
                                <th class="col100 cursor td-xuly" style="text-align: center;">
                                    Xử lý
                                </th>
                            </thead>
                              <tbody>
                              <tr v-for="(item, index) in list" :key="index">
                                <td style="text-align: center">
                                  {{ index + ((currentPage-1)*perPage) + 1}}
                                </td>
                                <td style="text-align: left">
                                    {{ item.name }}
                                </td>
                                <td style="text-align: center">
                                    {{ item.phone }}
                                </td>
                                <td style="text-align: left">
                                    {{ item.email }}
                                </td>
                                <td style="text-align: left">
                                    {{ item.content }}
                                </td>
                                <td style="text-align: center">
                                    <a
                                        href="#info_modal"
                                        data-bs-toggle="modal"
                                        size="sm"
                                        type="button"
                                        class="btn btn-outline btn-sm"
                                        v-on:click="handleGetDataById(item.id)"
                                    >
                                        <i class="fas fa-pencil-alt text-success me-1"></i>
                                    </a>
                                    <a
                                        href="#delete_modal"
                                        data-bs-toggle="modal"
                                        class="btn btn-outline btn-sm"
                                        v-on:click="handleShowDeleteModal(item.id)"
                                    >
                                        <i class="fas fa-trash-alt text-danger me-1"></i>
                                    </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                      </div>
                      <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        ref="ref_info_modal"
                        >
                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Thông tin tư vấn</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                </div>
                                <div class="modal-body">
                                    <Form
                                        class="login"
                                        @submit="handleSubmit"
                                        :validation-schema="schema"
                                        v-slot="{ errors }"
                                        ref="form"
                                    >
                                        <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Họ và tên</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.name"
                                                placeholder="Vui lòng nhập họ và tên"
                                                name="name"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.name }"
                                            />
                                                <div class="invalid-feedback">{{ errors.name }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Số điện thoại</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.phone"
                                                placeholder="Vui lòng nhập số điện thoại"
                                                name="phone"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.phone }"
                                            />
                                                <div class="invalid-feedback">{{ errors.phone }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Email</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                v-model="model.email"
                                                placeholder="Vui lòng nhập email"
                                                name="email"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.email }"
                                            />
                                                <div class="invalid-feedback">{{ errors.email }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                            <label class="text-left">Nội dung</label>
                                            <span style="color: red">&nbsp;*</span>
                                            <input type="hidden" v-model="model.id"/>
                                            <Field
                                                as="textarea"
                                                v-model="model.content"
                                                placeholder="Vui lòng nhập nội dung"
                                                name="content"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': errors.content }"
                                            />
                                                <div class="invalid-feedback">{{ errors.content }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="text-left">Thứ tự</label>
                                                <input
                                                    v-model="model.sort"
                                                    class="form-control"
                                                    type="number"
                                                    id="sort"
                                                />
                                            </div>
                                        </div>

                                        </div>
                                        <div class="text-end pt-2 mt-3">
                                            <b-button
                                                type="button"
                                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                                data-bs-dismiss="modal"
                                                @click="reset()"
                                            >
                                                Đóng
                                            </b-button>
                                            <b-button  type="submit" variant="success" class="ms-1 cs-btn-primary btn-submit w-md">
                                                Lưu
                                            </b-button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="modal fade"
                            id="delete_modal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            ref="ref_delete"
                        >
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="acc_title">Xóa</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                    </div>
                                    <div class="modal-body" style="font-weight: 500;">
                                        <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                                    </div>
                                    <div class="modal-footer">
                                    <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                                        Xóa
                                    </b-button>
                                    <b-button
                                        type="button"
                                        class="btn si_accept_cancel btn-submit w-md btn-out"
                                        data-bs-dismiss="modal"
                                    >
                                        Đóng
                                    </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row" >
                        <div class="col-md-6 col-sm-6 mt-2">
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center">
                              <b-form-select
                                  class="form-select form-select-sm"
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  style="width: 70px; text-align: center;"
                              ></b-form-select
                              >&nbsp;
                            </label>
                            trên tổng số {{ totalRows }} dòng
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="right"
                                size="sm"
                                class="my-0"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { tuVanModel } from "@/models/tuVanModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
  export default {
    components: {
      VueDatePicker,
      Treeselect,
      VueMultiselect,
      Form,
    Field,
    },
    data() {
      return {
        title: "DANH SÁCH",
        model: tuVanModel.baseJson(),
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        theModal: null,
        treeView : [],
        keyId : null,
      };
    },
    name: "pharmacy/user",

    created() {
      this.getData();
      this.GetTreeList();
    },
    mounted() {
        this.theModal = new Modal(document.getElementById('info_modal'));
        this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
        this.model = tuVanModel.baseJson()
      });
      this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
        this.model = tuVanModel.baseJson()
      });
    },

    setup() {
        const schema = Yup.object().shape({
            name: Yup.string().required("Tên không được bỏ trống !"),
            phone: Yup.string().required("Số điện thoại không được bỏ trống !"),
            
        });
        return {
            schema,
        };
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        }
    },

    methods: {
        reset() {
            this.model = tuVanModel.baseJson();
        },
        keyId(model){
            if(model != null && model.id){
                return id;
            }
        },
      async GetTreeList(){
        await this.$store.dispatch("menuCongDanStore/getTreeList").then((res) => {
          if (res != null && res != null && res.code ===0)
          {
            this.treeView = res.data;
          }
        })
      },
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch("tuVanStore/getPagingParams", params ).then(res => {
                this.list = res.data.data
                this.totalRows = res.data.totalRows
                this.numberOfElement = res.data.data.length
            });
        },

        async handleGetDataById(id) {
            await this.$store.dispatch("tuVanStore/getById", {id : id}).then((res) => {
                if (res != null && res.code ===0) {
                //    console.log(res)
                    this.model = tuVanModel.getJson(res.data);
                    this.keyId =  (res.data != null && res.data.menu != null ) ? res.data.menu : null;
                    this.$refs.form.setFieldValue('fileImage', res.data.fileImage || null);
                }
            });
        },
        handleShowDeleteModal(id) {
            this.model.id = id;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("tuVanStore/delete", { 'id': this.model.id }).then((res) => {
                if (res != null && res.code ===0) {
                    this.model = tuVanModel.baseJson();
                    this.getData();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async handleSubmit() {
            if (
                this.model.id != 0 &&
                this.model.id != null &&
                this.model.id
            )
            {
                await this.$store.dispatch("tuVanStore/update", this.model).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.theModal.hide();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            } else {
                await this.$store.dispatch("tuVanStore/create", this.model).then((res) => {
                if (res != null && res.code ===0) {
                    this.getData();
                    this.theModal.hide();
                }
                    this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
                this.$refs.form.setFieldValue('fileImage', null);

            }
        },

        async upload() {
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('code', "AVATAR")
            formData.append('files', event.target.files[0])
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                let resultData = response.data
                if (response.data.code == 0){
                this.model.fileImage={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
              //  console.log("LOG UPDATE : ", this.model);
                }
            })
            }
        },

        getColorWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
                return 'text-danger';

            },
        getIconWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
                return 'mdi mdi-file-image-outline';
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>


