<template>
  <div class="main-Wrapper">
    <!-- Page Wrapper -->
    <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit">
      <div class="">
        <div class="row">
          <div class="col-md-12" style="padding: 10px 20px;">
            <a
                href="#info_modal"
                data-bs-toggle="modal"
                size="sm"
                type="button"
                class="btn btn-tool card-add"
                style="float: right;"
            >
              <i class="fas fa-plus"></i>
            </a>
          </div>
          <div class="col-md-12">
            <b-card no-body>
              <b-row>
                <b-col cols="12">
                  <div class="tab-content" style="padding: 20px;">
                    <div>
                      <div class="custom-new-table">
                        <div class="table-responsive">
                          <table class="table table-hover table-center mb-0">
                            <thead>
                            <!-- <th class="col150 cursor td-stt" style="text-align: center;">
                                STT
                            </th> -->
                            <th class="col150 cursor" style="text-align: center;">
                              Từ năm đến năm
                            </th>
                            <th class="col150 cursor" style="text-align: center;">
                              Nơi công tác
                            </th>
                            <th class="col100 cursor td-xuly" style="text-align: center;">
                              Xử lý
                            </th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in model.experiences" :key="index">
                              <td>
                                <div style="color: #000;">
                                                                        <span>
                                                                            {{ item.dateStartShow }}
                                                                        </span>
                                  <span>
                                                                            - {{ item.dateEndShow }}
                                                                        </span>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span>{{ item.name }}</span>
                                </div>
                              </td>
                              <td style="text-align: center">
                                <a
                                    href="#info_modal"
                                    data-bs-toggle="modal"
                                    size="sm"
                                    type="button"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleGetInfo(item)"
                                >
                                  <i class="fas fa-pencil-alt text-success me-1"></i>
                                </a>
                                <a
                                    href="#delete_modal"
                                    data-bs-toggle="modal"
                                    class="btn btn-outline btn-sm"
                                    v-on:click="handleShowDeleteModal(item.id, item)"
                                >
                                  <i class="fas fa-trash-alt text-danger me-1"></i>
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
      <div
          class="modal fade"
          id="info_modal"
          aria-hidden="true"
          role="dialog"
          data-bs-backdrop="static"
          ref="ref_info_modal"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Thông tin kinh nghiệm</h5>
              <b-button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></b-button>
            </div>
            <div class="modal-body">
              <Form
                  class="login"
                  @submit="handleSubmit"
                  :validation-schema="schema"
                  v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="text-left mb-0">Từ năm</label>
                      <span style="color: red" >&nbsp;*</span>
                      <Field name="dateStart" v-slot="{ field }">
                        <VueDatePicker
                            v-bind="field"
                            v-model="items.dateStart"
                            :enable-time-picker="false"
                            :format="format"
                            :locale="locale"
                            validate="true"
                            auto-apply
                            ONCLICK=""
                            :class="{ 'is-invalid': errors.dateStart }"
                        />
                      </Field>
                      <div class="invalid-feedback">{{ errors.dateStart }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="text-left mb-0">Đến năm</label>
                      <span style="color: red" >&nbsp;*</span>
                      <Field name="dateEnd" v-slot="{ field }">
                        <VueDatePicker
                            v-bind="field"
                            v-model="items.dateEnd"
                            :enable-time-picker="false"
                            :format="format"
                            :locale="locale"
                            validate="true"
                            auto-apply
                            ONCLICK=""
                            :class="{ 'is-invalid': errors.dateEnd }"
                        />
                      </Field>
                      <div class="invalid-feedback">{{ errors.dateEnd }}</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="text-left">Nơi công tác</label>
                      <span style="color: red">&nbsp;*</span>
                      <Field
                          v-model="items.name"
                          placeholder="Vui lòng nhập nơi công tác"
                          name="name"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.name }"
                      />
                      <div class="invalid-feedback">{{ errors.name  }}</div>
                    </div>
                  </div>

                </div>
                <div class="text-end pt-2 mt-3">
                  <b-button
                      type="button"
                      class="btn si_accept_cancel btn-submit w-md btn-out"
                      data-bs-dismiss="modal"
                  >
                    Đóng
                  </b-button>
                  <b-button  type="submit" variant="success" class="ms-1 cs-btn-primary btn-submit w-md">
                    Lưu
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
          class="modal fade"
          id="delete_modal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
          ref="ref_delete"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="acc_title">Xóa</h5>
              <b-button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></b-button>
            </div>
            <div class="modal-body" style="font-weight: 500;">
              <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
            </div>
            <div class="modal-footer">
              <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                Xóa
              </b-button>
              <b-button
                  type="button"
                  class="btn si_accept_cancel btn-submit w-md btn-out"
                  data-bs-dismiss="modal"
              >
                Đóng
              </b-button>
            </div>
          </div>
        </div>
      </div>

    </Form>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { experienceModel } from "@/models/experienceModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import {doctorModel} from "@/models/doctorModel";
export default {
  components: {
    VueDatePicker,
    Treeselect,
    VueMultiselect,
    Form,
    Field,
  },
  data() {
    return {
      title: "DANH SÁCH",
      model: doctorModel.baseJson(),
      activeTab: 0, // Tab mặc định là tab đầu tiên
      list: [],
      currentPage: 1,
      numberOfElement: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      totalRows: 1,
      sortBy: 'age',
      sortDesc: false,
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      format : `dd/MM/yyyy`,
      locale: 'vi',
      items: experienceModel.baseJson(),
      theModal: null,
    };
  },
  name: "pharmacy/user",
  props: {
    data: { type : Object}
  },
  created() {

  },

  setup(props) {
    const schema = Yup.object().shape({
      dateStart: Yup.string().required("Ngày bắt đầu không được bỏ trống !"),
      dateEnd: Yup.string().required("Ngày kết thúc không được bỏ trống !"),
      name : Yup.string().required("Đơn vị công tác không được bỏ trống !"),
    });
    const data = props.data;
    return {
      schema,
      data
    };
  },
  mounted() {
    this.theModal = new Modal(document.getElementById('info_modal'));
    this.model = this.data;
    this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
      this.items =  experienceModel.baseJson();
    });
    this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
      this.items =  experienceModel.baseJson();
    });
  },
  methods: {

    async handleGetInfo(item) {
      this.items = item;
    },
    handleShowDeleteModal(id, educationInfoModel) {
      this.model.id = id;
      this.items = educationInfoModel;
      this.showDeleteModal = true;
    },
    async handleDelete() {
      if (       this.items.id != 0 &&
          this.items.id != null &&
          this.items.id) {
        await this.$store.dispatch("experienceStore/delete", {
          'id': this.$route.params.id,
          experienceInfo: this.items
        }).then((res) => {
          if (res != null && res.code === 0) {
            this.model.experiences = res.data;
            this.showDeleteModal = false;
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }
    },
    async handleSubmit() {
      if (
          this.items.id != 0 &&
          this.items.id != null &&
          this.items.id
      ) {
        await this.$store.dispatch("experienceStore/update", {
          "id": this.$route.params.id,
          experienceInfo: this.items
        }).then((res) => {
          if (res != null && res.code === 0) {
            this.model.experiences = res.data;
            this.theModal.hide();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }else {
        await this.$store.dispatch("experienceStore/create", {
          "id": this.$route.params.id,
          experienceInfo: this.items
        }).then((res) => {
          if (res != null && res.code === 0) {
            this.model.experiences = res.data;
            this.theModal.hide();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }

    },
  }
}
</script>

<style>
.image-dichvu{
  width: 200px;
}

.tabs{
  width: 100%;
}
.card-header-doctor{

  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.info-doctoc-right{
  box-shadow: 0 10px 40px 10px rgba(0, 0, 0, .0784313725);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .info-doctoc-right{
    margin-top: 20px;
  }
}
</style>

