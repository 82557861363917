<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <breadcrumb :title="title" :text="text" :text1="text1" />
        <div class="page-wrapper">
            <div class="row align-items-center">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                            <div class="col-lg-12">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-md-12" style="margin-top: 40px;">
                                        <div class="vueslick">
                                          <div class="popup-gallery">
                                            <div
                                                style=" height: 100%; width: 100%; border-radius: 8px; border: 1px solid #ccc; margin-bottom: 5px;"
                                                @click="() => showImg(indexImage)"
                                            >
                                              <img
                                                  v-if="listPicture[indexImage]"
                                                  class="card-img-carousel img-res"
                                                  :src="apiView + listPicture[indexImage].fileId"
                                                  height="300px"
                                                  style="object-fit: cover; height: 100%; width: 100%; border-radius: 8px"
                                              >
                                            </div>
                                            <vue-easy-lightbox
                                                :visible="visible" :imgs="ImagesShow" :index="keyFile"
                                                @hide="handleHide">
                                            </vue-easy-lightbox>
                                          </div>
                                          <div class="border carousel-img" style="border-radius: 8px;">
                                            <swiper
                                            :slides-per-view="slidesPerView"
                                            :space-between="spaceBetween"
                                            :pagination="pagination"
                                            :navigation="navigation"
                                            :loop="infinite"
                                            :speed="speed"
                                            class="mySwiper"
                                          >
                                            <swiper-slide
                                              v-for="(src, index) in listPicture"
                                              :key="index"
                                              class=""
                                              style="height: 150px;"
                                            >
                                              <img
                                                class="card-img-carousel-sub"
                                                :src="apiView + src.fileId"
                                                @click="HandleClickImage(index)"
                                              />
                                            </swiper-slide>
                                          </swiper>
                                          </div>
                                        </div>

                                        <div class="mt-2" style="font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem); position: relative;">
                                          <div class="title mt-2 mb-2">
                                            <h2>
                                                {{ this.model.name }}
                                            </h2>
                                          </div>
                                          <div class="p-1">
                                              <div class="text-black">
                                                  <p class="color" style="font-size: 30px; font-weight: bold; margin-bottom: 5px;">
                                                    {{ this.model.value }}
                                                  </p>
                                              </div>
                                          </div>
                                          <div class="p-1">
                                              <div class="text-black">
                                                <span style="color: #D6A24D; font-weight: 900 !important; font-size: 18px;">Xuất xứ: </span>
                                                <span v-html="model.origin"></span>
                                              </div>
                                          </div>
                                          <div class="p-1">
                                            <div class="text-black">
                                              <span style="color: #D6A24D; font-weight: 900 !important; font-size: 18px;">Thành phần: </span>
                                              <span v-html="model.ingredient"></span>
                                            </div>
                                          </div>
                                          <div class="p-1">
                                            <div class="text-black">
                                              <span style="color: #D6A24D; font-weight: 900 !important; font-size: 18px;">Cách dùng: </span>
                                              <span v-html="model.dosage"></span>
                                            </div>
                                          </div>
                                          <div class="p-1">
                                            <div class="text-black">
                                              <span style="color: #D6A24D; font-weight: 900 !important; font-size: 18px;">Chỉ định: </span>
                                              <span v-html="model.indications"></span>
                                            </div>
                                          </div>
                                          <div class="p-1">
                                              <span class="text-black">
                                                <span style="color: #D6A24D; font-weight: 900 !important; font-size: 18px;">Lưu ý: </span>
                                                <span>Sản phẩm này không phải là thuốc, không có tác dụng thay thế thuốc chữa bệnh. Đọc kỹ tờ hướng dẫn sử dụng trước khi dùng.</span>
                                              </span>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                <!-- <div class="row">
                                    <div class="col-lg-12 mb-3">
                                    <div class="title mt-4 mb-4">
                                        <h2>
                                            {{ this.model.name }}
                                        </h2>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 col-sm-6">
                                        <div class="popup-gallery flex-wrap" style="display: flex; justify-content: center; align-items: center;">
                                            <div
                                                style="padding:5px; width: 400px;"
                                                @click="() => showImg(indexImage)"
                                            >
                                            <img
                                                v-if="listPicture[indexImage]"
                                                class="card-img-carousel img-res"
                                                :src="apiView + listPicture[indexImage].fileId"
                                                height="300px"
                                                style="object-fit: cover;"
                                            >
                                            </div>
                                            <vue-easy-lightbox
                                                :visible="visible" :imgs="ImagesShow" :index="keyFile"
                                                @hide="handleHide">
                                            </vue-easy-lightbox>
                                        </div>
                                        <div class="carousel-img">
                                          <swiper
                                            :slides-per-view="slidesPerView"
                                            :space-between="spaceBetween"
                                            :pagination="pagination"
                                            :navigation="navigation"
                                            :loop="infinite"
                                            :speed="speed"
                                            class="mySwiper"
                                          >
                                            <swiper-slide
                                              v-for="(src, index) in listPicture"
                                              :key="index"
                                              class=""
                                              style="height: 150px;"
                                            >
                                              <img
                                                class="card-img-carousel-sub"
                                                :src="apiView + src.fileId"
                                                @click="HandleClickImage(index)"
                                              />
                                            </swiper-slide>
                                          </swiper>
                                        </div>
                                        </div>
                                        <div class="col-md-6 mt-2" style="font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem);">
                                        <div class="p-1">
                                            <div class="text-black">
                                                <p class="color" style="font-size: 30px; font-weight: bold; margin-bottom: 5px;">
                                                  {{ this.model.value }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="p-1">
                                            <div class="text-black">
                                              <span style="color: #000; font-weight: bold">Xuất xứ: </span>
                                              <span v-html="model.origin"></span>
                                            </div>
                                        </div>
                                        <div class="p-1">
                                          <div class="text-black">
                                            <span style="color: #000; font-weight: bold">Thành phần: </span>
                                            <span v-html="model.ingredient"></span>
                                          </div>
                                        </div>
                                        <div class="p-1">
                                          <div class="text-black">
                                            <span style="color: #000; font-weight: bold">Chỉ định: </span>
                                            <span v-html="model.indications"></span>
                                          </div>
                                        </div>
                                        <div class="p-1">
                                          <div class="text-black">
                                            <span style="color: #000; font-weight: bold">Cách dùng: </span>
                                            <span v-html="model.dosage"></span>
                                          </div>
                                        </div>
                                        <div class="p-1">
                                            <span class="text-black">
                                              <span style="color: #000; font-weight: bold">Lưu ý: </span>
                                              <span>Sản phẩm này không phải là thuốc, không có tác dụng thay thế thuốc chữa bệnh. Đọc kỹ tờ hướng dẫn sử dụng trước khi dùng.</span>
                                            </span>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    
                                    </div>

                                </div> -->
                                <div class="row d-flex align-items-baseline mb-3">
                                    <div class="col-12 position-relative pt-4">
                                    <a
                                        class="back"
                                        @click="$router.go(-1)"
                                    >
                                        <i class="bx bx-left-arrow-alt me-2 text-white"></i>
                                        Quay lại
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import {pageInfoModel} from "@/models/pageInfoModel";
import VueEasyLightbox from "vue-easy-lightbox";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
export default {
  components: {VueEasyLightbox, VueSlickCarousel, Swiper, SwiperSlide},
  data() {
    return {
      title: 'Chi tiết thuốc',
      text: 'Trang chủ',
      text1: 'Chi tiết thuốc',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      pageInfo : pageInfoModel.baseJson(),
      url: `${process.env.VUE_APP_API_URL}files/view`,
      apiView: `${process.env.VUE_APP_API_URL}files/view/`,
      listPicture: [],
      ImagesShow: [],
      indexImage: 0,
      visible: false,
      keyFile: 0,
      index: 0,

      slidesPerView: 6, // Số lượng hình ảnh hiển thị
      spaceBetween: 10, // Khoảng cách giữa các hình ảnh
      pagination: {
        clickable: true, // Cho phép nhấp vào pagination
      },
      navigation: false, // Bật điều hướng
      infinite: false, // Không lặp lại
      speed: 500, // Tốc độ chuyển đổi

      settings: {
        arrows: false, // Bật mũi tên
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev">Previous</button>',
        nextArrow: '<button type="button" class="slick-next">Next</button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 4,
              initialSlide: 4,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
              dots: false,
            },
          },
        ],
      }
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },

  mounted() {
  },
  created() {
    this.getData();
  },
  methods: {
    
    async getData() {
      await this.$store.dispatch("thuocStore/getById", {
        "id": this.$route.params.id
      }).then(res => {
        if (res != null && res.code ===0) {
            this.model = res.data;
            console.log("IMAGE: ",this.model);
        if(res.data.fileImage && res.data.fileImage != null){
            res.data.fileImage.forEach((elements) => {
            this.listPicture.push(elements)
            this.ImagesShow.push(this.apiView + elements?.fileId)
            
        }
        )}
        }
      });
    },
    getUrl(item) {
      // console.log("LOG DANH MUC CLICK", item)
      if (item.link.indexOf("/{id}") < 0  && item.level === 0)
      {
        // console.log("LOG ROUTER IF LAYOUT  : ", item)
        this.idMenu = item.id;
        //  console.log("LOG ITEM : ", item.link)
        this.$router.push(item.link);
      } else if (item.link.indexOf("/{id}") > 0 && item.level === 0){
        this.idMenu = item.id;
        // console.log("LOG ROUTER IF ELSE  LAYOUT  : ", item.link.replace("{id}",  item.id))
        this.$router.push(item.link.replace("{id}",  item.id));
      }else {
        // console.log("LOG ROUTER ELSE LAYOUT  : ", item.link +   item.id)
        this.idMenu = item.id;
        this.$router.push(item.link +  "/" + item.id);
      }
    },

    HandleClickImage(index) {
      this.indexImage = index;
    },

    showImg(indexImage) {
      this.keyFile = indexImage;
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },

    showPreview() {
      const preview = this.$imagePreview({
        initIndex: 0,
        images:this.relink,
        isEnableBlurBackground: false,
        isEnableLoopToggle: true,
        initViewMode: "contain",
        containScale: 1,
        shirnkAndEnlargeDeltaRatio: 0.2,
        wheelScrollDeltaRatio: 0.2,
        isEnableImagePageIndicator: true,
        maskBackgroundColor: "rgba(0,0,0,0.6)",
        zIndex: 4000,
        isEnableDownloadImage: true
      })
      this.relink=[]
    },
  },
}
</script>

<style>

.color{
    color: #D6A24D;
}


.category{
  background-color: #f9f9f9;
}

.cate-title{
  background-color: #05912a;
  color: #fff;
  padding: 10px;
  font-size: 20px;
}

.cate-list ul li{
  list-style: none;
  border-bottom: 1px dashed #d0cfcf;
  padding: 10px 0 10px 0;
}

.cate-list ul li a{
  margin-left: 10px;
  font-size: 14px;
  color: #78797C;
}

/* .cs-title-box .cs-title .ic-item {
  background-color: #fff;
  color: #d60604;
  padding: 5px 7px;
  border-radius: 50px;
  margin-right: 10px;
}

.cs-title-box .cs-title {
  background-color: #d60604;
  color: #fff;
  width: fit-content;
  padding: 5px;
  padding-right: 20px;
  border-radius: 50px;
  position: relative;
  z-index: 99;
  margin: 10px 0px;

}

.cs-title-box:before {
  display: block;
  height: 1px;
  width: 100%;
  background: linear-gradient(90deg, #d60604, rgba(199, 26, 22, 0));
  position: absolute;
  top: 50%;
  z-index: 1;
} */
.btn-yellow{
  background-color: #EFC62C;
  border: none;
  border-radius: 0 !important;
  color: #000 !important;
}

.btn-yellow:hover{
  background-color: #ffc800;
  border: none;
}

.color-primary {
  /*color: #28883b;*/
  color: #2b569a;
}

.bg-primary {
  /*background-color: #28883b !important;*/
  background-color: #2b569a !important;
}

.w-10 {
  width: 10%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr {
  vertical-align: middle !important;
  box-shadow: 0 0 1rem rgb(18 38 63 / 3%) !important;
}

.bg-ico-primary {
  height: 340px;
}
.ribbons {
  /*background: linear-gradient(45deg, #940012, #F6C6C6);*/
  position: absolute;
  padding: 10px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  top: -18px;
  left: 20px;
  background-color: #2b569a;
  box-shadow: rgba(255, 255, 255, 0) 0px 4px 6px -1px, rgba(255, 255, 255, 0.5) 0px 2px 4px -1px;
}

@media only screen and (max-width: 425px){
  .create-at{
    text-align: start !important;
    margin-bottom: 5px;
  }
}


section.bg-ico-primary {
  padding-top: 100px;
}
.btn-detail {
  background:#2b569a;
  border: none;
}

.btn-secondary {
  --bs-btn-bg: #2b569a;
  --bs-btn-hover-bg: #537961;
}

.custom-content{
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.image-tracuu-pakn {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  width: -webkit-fill-available;
}

.detail{
    font-size: 14px;
}

.detail .image img{
    width: 80%;
}

.detail .image{
    text-align: center;
}
.noidung figure {
    overflow: unset;
    aspect-ratio: unset;
    margin: 0 0 1rem;
}

/****************** SWIPER *****************/

.popup-gallery{
    display: flex; 
    justify-content: center; 
    align-items: center;
    height: 400px
}
.vueslick{
  width: 50%;
  padding: 10px;
  float: left;
  margin-right: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 578px) {
  .vueslick{
    width: 100% !important;
  }
  .popup-gallery{
    height: 300px !important
  }
}

.swiper-wrapper{
  height: 100px !important
}

.swiper-slide{
  height: 100px !important;
  width: 100px !important;
  border-radius: 5px;
}

.swiper-slide img{
  height: 100%;
  background-color: #f6fbff;
  border-radius: 5px;
}

/* .swiper-slide-active{
  border-radius: 5px;
  border: 2px solid #D6A24D
} */
</style>
