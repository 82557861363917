<template>
    <!-- Need To Know -->
    <section class="need-to-know-section section-list-service">
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-12 aos" data-aos="fade-up">
            <div class="section-header-one section-header-slider text-center mb-2">
              <h2 class="section-title">Kiến thức <span>nha khoa</span></h2>
            </div>
            <div class="section-header-one section-header-slider text-center">
              <div class="section-title title-desc">
                Nha khoa Phương Thành có đầy đủ các chuyên khoa,
                với mỗi khoa là đội ngũ bác sĩ chuyên sâu trong từng lĩnh vực để đem đến kết quả tối ưu cho khách hàng.
              </div>
            </div>
          </div>
        </div>
        <div class="listing-service aos" data-aos="fade-up">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-12" v-for="(item, index) in this.listDentalKnowledge" :key="index">
              <router-link
                  :to="{
                    path: `/ban-tin/${item.id}`,
                  }"
              >
                <div class="service-item">
                  <div class="service-thumb">
                    <a href="">
                      <img width="300" height="208" 
                        :src="item.fileImage.urlFile"
                        class="attachment-medium size-medium wp-post-image img-dichvu" 
                        alt="Ảnh" 
                        decoding="async" 
                        fetchpriority="high" 
                        srcset="" 
                        sizes="100vw">
                    </a>
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h3>
                        <a href="" data-wpel-link="internal">
                          {{ item.name}}
                        </a>
                      </h3>
                    </div>
                    <div class="service-excerpt">
                      <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                      <p>{{ item.describe }}</p>
                    </div>
                    <div class="service-button">
                      <a href="" data-wpel-link="internal">Tìm hiểu thêm</a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 aos" data-aos="fade-up"
               v-for="(value) in this.listDentalKnowledge" :key="value.id"
          >
            <div class="service-item">
              <router-link :to="{
                          path: `/ban-tin/${value.id}`,
                      }">
                <div>
                  <img
                      v-if="value.fileImage"
                      class="img-fluid img-service"
                      :src="value.fileImage.urlFile"
                      alt="Post Image"
                  />
                </div>
                <div class="service-content">
                  <div class="service-title mt-3">
                    <h3
                      style="
                        color: #00003b;
                        margin-bottom: 8px;
                        font-family: 'Nunito Bold';
                        text-transform: uppercase;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        min-height: 55px;"
                      >
                      <a>
                        {{value.name}}
                      </a>
                    </h3>
                  </div>
                  <div class="service-desc mb-3">
                    <p>
                      {{value.describe}}
                    </p>
                  </div>
                  <div class="service-btn">
                    <b-button
                        class="btn-chitiet"
                    >
                      Xem thêm
                    </b-button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
      </div>
    </section>
    <!-- /Need To Know -->
  
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import indexFiveService from "@/assets/json/indexfiveservice.json";
  export default {
    data() {
      return {
        indexFiveService: indexFiveService,
        listDentalKnowledge: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    created() {
      this.getDataDentalKnowledge();
    },
    methods: {
      async getDataDentalKnowledge() {
        let params = {
          start: 1,
          limit: 6,
          sortBy: this.sortBy,
        }
        await this.$store.dispatch("newsStore/getPagingParamsDentalKnowledge", params ).then(res => {
          this.listDentalKnowledge = res.data;
        });
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .img-service{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  @media (max-width: 576px) {
    .img-service{
      height: 300px;
    }
  }
  </style>
  