<template>
    <div class="pharmacy-body">
      <div class="main-wrapper">

        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />

        <!-- Pharmacy Banner -->
        <banner-thuoc></banner-thuoc>
        <!-- /Pharmacy Banner -->

        <!-- Welcome Section -->
<!--        <welcome-thuoc></welcome-thuoc>-->
        <!-- /Welcome Section -->


        <!-- Categories Section -->
        <categories-thuoc></categories-thuoc>
        <!-- /Categories Section -->

        <!-- Seller Section -->
<!--        <seller-thuoc></seller-thuoc>-->
        <!-- /Seller Section -->

        <!-- Feature Products Section -->
        <!-- <feature-thuoc></feature-thuoc> -->
        <!-- /Feature Products Section -->

        <!-- Recent Products Section -->
        <product-thuoc></product-thuoc>
        <!-- /Recent Products Section -->










        <!-- Footer Section -->
        <footerHome></footerHome>
        <!-- /Footer Section -->
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        isScrolled: false,
      }
    },
    mounted() {
      // Add a scroll event listener when the component is mounted
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
      // Remove the scroll event listener when the component is about to be unmounted
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleScroll() {
        // Get the scroll position
        const scroll = window.scrollY;

        // Update the isScrolled data property based on the scroll position
        this.isScrolled = scroll > 35;
      },
    },
  }
  </script>
