<template>
  <!-- Home Banner -->
  <section class="banner-section">
    <div class="banner-slider" style="margin-top: 100px; max-height: 90vh;">
      <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="7000" v-if="this.list">
        <Slide v-for="(item, index) in this.list" :key="index.id">
            <img
              v-if="item"
              :src="item.fileImage?.urlFile" alt="Image"
              style="width: 100%"
            />
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
  <!-- /Home Banner -->

  <section id="section-2" class="section-brand-history">
    <div class="container padding-card">
      <div class="row aos" data-aos="fade-up">
        <div class="col-lg-6 col-md-6 col-12 mb-2">
          <div class="brand-history-thumb">
            <a class="popup-video" href="https://www.youtube.com/watch?v=19wNYHC2NRQ" data-wpel-link="external" rel="external noopener noreferrer">
                <noscript>
                    <img width="1614" height="1332" src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="Câu chuyện thương hiệu Nha khoa Phương Thành">
                </noscript>
                <img class=" ls-is-cached lazyloaded" width="1614" height="1332" src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" data-src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="Câu chuyện thương hiệu Elite Dental">
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="brand-history-content">
            <h1>Câu Chuyện Thương Hiệu Nha Khoa Phương Thành</h1>
                      <p>Thành lập từ 2012, với tiền thân là Khoa Nha – Bệnh Viện An Sinh, Nha khoa Phương Thành là trung tâm nha khoa chuyên sâu, cung cấp các dịch vụ thăm khám – chẩn đoán – tư vấn – điều trị trồng răng Implant, chỉnh nha và thẩm mỹ nụ cười.</p>
                      <p>Với đội ngũ bác sĩ chuyên môn cao, dày dạn kinh nghiệm cùng các trang thiết bị hiện đại, Nha khoa Phương Thành cam kết mang đến cho khách hàng trải nghiệm nhẹ nhàng, thoải mái và kết quả nụ cười khỏe mạnh, rạng rỡ.</p>
            <div class="button-brand-history">
              <button><a href="https://phuongthanh.vn/gioi-thieu" data-wpel-link="internal">Tìm hiểu thêm về Nha khoa Phương Thành</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Specialities Section -->
  <section class="specialities-section-one">
    <!-- <div class="floating-bg" style="margin-top: 120px">
      <img src="@/assets/img/phuongthanh/icon-teeth-72.png" alt="Image" />
    </div> -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-one section-header-slider mt-3 mb-3">
            <h2 class="section-title">5 lý do nên chọn <span>Nha khoa Phương Thành?</span></h2>
          </div>
        </div>
      </div>
      <div class="specialities-block">
        <ul>
          <li v-for="(speciality, index) in indexfivespecialities" :key="index">
            <div class="specialities-item">
              <div class="specialities-img">
                <div class="hexogen">
                  <img
                    :src="require(`@/assets/img/icons/${speciality.imageSrc}`)"
                    :alt="speciality.altText"
                    style="width: 50px;"
                  />
                </div>
              </div>
              <p>{{ speciality.title }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider mt-3 mb-3">
            <p class="section-title"
              style="
                color: #000;
                font-weight: bold;
              "
            >
              <span style="color: red;">*</span> Hợp tác với các chuyên gia đầu ngành trong nước và quốc tế
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
import { computed } from "vue";
import * as Yup from "yup";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
      //  console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
.carousel__pagination{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50px);
}

.carousel__pagination-button--active::after{
  background-color: #d4a04c !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
}

.carousel__pagination-button::after{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d4a04c5d;
}
</style>
