<template>
  <section class="more-coupon-sec">
    <div class="container">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Tiết kiệm nhiều hơn với phiếu giảm giá</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link to="product-all"
                >Xem tất cả <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Giảm 200K</h4>
              <p>Khi mua trên 2000K</p>
            </div>
            <div class="coupon-code">
              <span>Code200</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Giảm 250K</h4>
              <p>Khi mua trên 2500K</p>
            </div>
            <div class="coupon-code">
              <span>Code250</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Giảm 300K</h4>
              <p>Khi mua trên 3000K</p>
            </div>
            <div class="coupon-code">
              <span>Code300</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Giảm 350K</h4>
              <p>Khi mua trên 3500K</p>
            </div>
            <div class="coupon-code">
              <span>Code350</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
