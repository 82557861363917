<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <div class="page-wrapper">
            <div style="margin-top: 20px;">
                <div class="container" style="background-color: #deab5b; border-radius: 20px;">
                <div style="padding: 10px;">
                    <div class="row align-items-center">
                    <div style="text-align: center; margin-bottom: 20px;">
                        <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                        <h2 class="title-intro font-bold" style="color : #ffffff;">
                            NƠI QUY TỰ CÁC CHUYÊN GIA
                        </h2>
                        <p class="decs-intro-doctor">
                            Nha khoa Phương Thành tự hào là nơi hội tụ của đội ngũ chuyên gia nha khoa hàng đầu.
                            Bên cạnh việc cung cấp dịch vụ điều trị chất lượng vượt trội, các bác sĩ tại Nha khoa Phương Thành
                            luôn chủ động nâng cao kiến thức chuyên môn. Với các buổi hội thảo và chương trình chia sẻ
                            kiến thức chuyên sâu thường xuyên, chúng tôi không ngừng cập nhật các xu hướng và phương pháp điều
                            trị mới nhất, từ đó mang đến cho khách hàng những giải pháp nha khoa tối ưu và hiệu quả nhất.
                        </p>
                    </div>
                    <div class="aos mb-3" data-aos="fade-up">
                        <Carousel ref="carousel1" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                        <Slide v-for="(item, index) in filter1" :key="index.id">
                            <div class="list-doingu">
                                <div class="mb-3 item-doingu">
                                    <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                                    <div class="card-doingu">
                                    <div class="doctor-chuc-danh">
                                        <span>{{ item.Text }}</span>
                                    </div>
                                    <div class="doctor-name">
                                        <h3>{{ item.Name }}</h3>
                                    </div>
                                    <div class="doctor-desc">
                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                        <div class="doctor-button">
                                        <a href="">Tìm hiểu thêm</a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        </Carousel>
                        <div class="btn-w-slide">
                            <button class="btn-slide btn-prev" @click="prevSlide1"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                            <button class="btn-slide btn-next"@click="nextSlide1"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                        </div>

                        <div class="button-doctor-main">
                            <a href="https://phuongthanh.vn/doi-ngu-bac-si" data-wpel-link="internal">Tìm hiểu thêm về đội ngũ bác sĩ</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      indexFiveDoctor: indexFiveDoctor,
      itemsToShow: Math.min(indexFiveDoctor.length, 4), // Tính số lượng item hiển thị
        settings: {
            slidesToShow: this.itemsToShow,
            slidesToScroll: 1,
            dots: true,
            infinite: indexFiveDoctor.length > 4, // Chỉ cho phép lướt nếu có nhiều hơn 4 item
            // Các thiết lập khác
        },
        breakpoints: {
            578: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 700px and up
            768: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 1024 and up
            992: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },
  computed: {
    // Lọc danh sách bác sĩ có badge 'Hàm tháo lắp'
    filter1() {
        return this.indexFiveDoctor.filter(item => item.Badge === '1');
    },

},

  mounted() {
    // Chặn phím F12
    // window.addEventListener('keydown', (event) => {
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });

    // Chặn chuột phải
    // window.addEventListener('contextmenu', (event) => {
    //   event.preventDefault();
    // });
  },
  created() {
  },
  methods: {
    nextSlide1() {
        this.$refs.carousel1.next();
    },
    prevSlide1() {
        this.$refs.carousel1.prev();
    },

  },
}
</script>

<style>

.decs-intro-doctor{
    padding: 0 30px;
}

.list-doingu{
    width: 100%;
    /* max-height: 250px; */
}
.item-doingu {
    position: relative;
    padding: 0 10px;
    border-radius: 20px;
    cursor: pointer;
}
.item-doingu img{
    object-fit: cover;
    width: 100%;
    /* height: 250px; */
    border-radius: 20px;
    background-color: #fff;
}

.list-doingu .item-doingu .card-doingu{
    position: absolute;
    bottom: 0;
    background: rgba(88, 97, 116, .9);
    left: 0;
    right: 0;
    padding: 10px;
    margin: 30px;
    border-radius: 11px;
    text-align: center;
    color: #fff;
    transition: visibility 0s, height .3s ease
}

.list-doingu .item-doingu .card-doingu .doctor-chuc-danh span{
    font-size: 12px !important;
}
.list-doingu .item-doingu .card-doingu .doctor-name h3{
    font-size: 16px !important;
    color: #fff;
}

.list-doingu .item-doingu .card-doingu .doctor-desc{
    color: #edeef2;
    font-size: 11px;
    visibility: hidden;
    height: 0;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button{
    margin-top: 10px;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button a {
    background: #d4a04c;
    border-radius: 24px;
    font-family: "Nunito Bold";
    font-size: 12px;
    height: 30px;
    padding: 5px 15px;
    line-height: 30px;
    max-width: 150px;
    margin: 0 auto;
    color: #fff;
}

.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button a:hover {
    background: #fff;
    color: #d4a04c;
}

.button-doctor-main {
    text-align: center
}

.button-doctor-main a {
    border: 1px solid #ffff;
    box-shadow: 0px 13px 12.9px -8px #ffff;
    height: 48px;
    line-height: 48px;
    font-family: "SVN Poppins Bold";
    font-size: 14px;
    padding: 10px 20px;
    max-width: 280px;
    margin: 0 auto;
    border-radius: 24px;
    color: #ffff;
    text-align: center
}

.list-doingu .item-doingu:hover .card-doingu {
    bottom: 0;
    animation: slideUp .5s ease forwards;
    transition: visibility 0s,height .3s ease
}
.list-doingu .item-doingu:hover .card-doingu .doctor-desc{
    visibility: visible;
    height: auto
}

.color{
    color: #D6A24D;
}

.font-bold{
    font-family: 'Nunito Bold';
}

.font-light{
    font-family: 'Nunito Light';
}

.font-regular{
    font-family: 'Nunito Regular';
}


/* HAHA */
.section-usps {
    padding: 100px 0;
    background: #fefdfe;
    margin-top: 50px;
}

@media screen and (max-width: 1024px) {
    .section-usps {
        padding:50px 0;
    }
}

@media screen and (max-width: 767px) {
    .section-usps {
        padding:30px 0
    }
}

.section-usps .container .box-heading h2 {
    color: var(--main-color);
    margin-bottom: 30px;
    text-align: center;
    font-family: "SVN Poppins Bold"
}

.section-usps .container .row .col-lg-4 .box-content {
    background: #fff;
    box-shadow: 0px 4px 30px rgba(146,90,143,.25);
    border-radius: 25px;
    text-align: center;
    padding: 50px;
    max-height: 330px;
    min-height: 330px
}

@media screen and (width: 1024px) {
    .section-usps .container .row .col-lg-4 .box-content {
        padding:30px
    }
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content {
        padding:30px 15px;
        max-height: 300px;
        min-height: 300px
    }
}

@media screen and (max-width: 767px) {
    .section-usps .container .row .col-lg-4 .box-content {
        margin:15px;
        max-height: 250px;
        min-height: 250px
    }
}

.section-usps .container .row .col-lg-4 .box-content .box-icon {
    margin-bottom: 15px
}

.section-usps .container .row .col-lg-4 .box-content .title {
    color: #2a2f38;
    font-size: 20px;
    font-family: "Nunito Regular"
}

.section-usps .container .row .col-lg-4 .box-content .title p {
    color: #2a2f38;
    font-size: 20px;
    font-family: "Nunito Regular";
    margin-bottom: 0
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content .title p {
        font-size:16px
    }
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content .title {
        font-size:16px
    }
}

.section-usps .container .box-middle {
    margin-bottom: 50px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-middle {
        margin-bottom:30px
    }
}

.section-usps .container .box-bottom {
    background-color: var(--main-color);
    border-radius:40px
}

@media screen and (min-width: 768px) {
    .section-usps .container .box-bottom {
        border-radius:40px
    }
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom {
        margin-left:-15px;
        margin-right: -15px
    }
}

.section-usps .container .box-bottom .box-content-bottom {
    margin: 0 auto;
    padding: 60px 50px;
    color: #fff;
    font-family: "Nunito Regular";
    font-size: 22px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom {
        padding:60px 15px;
        font-size: 16px
    }
}

.section-usps .container .box-bottom .box-content-bottom h3 {
    color: #fff;
    font-family: "Nunito Bold";
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: none !important;
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom h3 {
        font-size:20px;
        margin: 0 auto 30px auto
    }
}

.section-usps .container .box-bottom .box-content-bottom p {
    margin-bottom: 10px;
    margin-left: 50px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom p {
        margin-left:0;
        display: flex
    }
}

.section-usps .container .box-bottom .box-content-bottom p img {
    margin-right: 10px;
    margin-top: -5px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom p img {
        width:24px;
        height: 25px;
        margin-top: 0
    }
}

@media screen and (max-width: 384px) {
    .section-usps .container .box-bottom .box-content-bottom p img {
        margin-top:3px
    }
}

</style>
