<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="@/assets/img/phuongthanh/core/home.jpg"
                      class="img-fluid"
                      alt="Doccure Login"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3 class="text-center">ĐĂNG NHẬP </h3>
                      <p class="text-center" style="font-weight: bold; color: #000;">NHA KHOA PHƯƠNG THÀNH</p>
                    </div>
                    <Form
                        :validation-schema="schema" v-slot="{ errors }"   @submit="submitForm"
                    >
                      <div class="mb-3 form-focus">
                        <div class="mb-3">
                          <label class="text-left">Tài khoản</label>
                          <span style="color: red">&nbsp;*</span>
                          <Field
                              v-model="model.username"
                              placeholder="Vui lòng nhập tên tiêu đề"
                              name="username"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': errors.username }"
                          />
                          <div class="invalid-feedback">{{ errors.username }}</div>
                        </div>
                      </div>
                      <div class="mb-3 mt-5 form-focus">
                        <div class="mb-3">
                          <label class="text-left">Mật khẩu</label>
                          <span style="color: red">&nbsp;*</span>
                          <Field
                              v-model="model.password"
                              placeholder="Vui lòng nhập tên tiêu đề"
                              name="password"
                              type="password"
                              class="form-control"
                              :class="{ 'is-invalid': errors.password }"
                          />
                          <div class="invalid-feedback">{{ errors.password }}</div>
                        </div>
                      </div>

                      <div style="display: flex; justify-content: space-around;" class="mt-5">
                                                <b-button
                                                    class="btn btn-primary btn-login"
                                                    type="submit"
                                                >
                                                  ĐĂNG NHẬP
                                                </b-button>
                                              </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <indexfivefooter></indexfivefooter>
    </div>
  </div>
</template>
<script>
import {notifyModel} from "@/models/notifyModel";
import * as Yup from "yup";
import {Field, Form} from "vee-validate";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      model:{
        username: null,
        password: null
      },
    };
  },
  setup() {
    const schema = Yup.object().shape({
      username: Yup.string().required("Tài khoản không được bỏ trống !"),
      password: Yup.string().required("Mật khẩu không được bỏ trống !"),
    });
    return {
      schema,
    };
  },
  methods: {
      async submitForm() {
        await this.$store.dispatch("authStore/login", this.model).then((res) => {
          if (res && res.code ==0 ) {
            this.listMenu = res.data.menu;
            console.log("LOG SUCCCESS ", res.data.accessToken)
            localStorage.setItem('auth-user', JSON.stringify(res.data));
            localStorage.setItem('token', res.data.accessToken);
            // window.location.href="/admin"
            // this.$router.push("/quan-tri/profile");
            window.location.href = "/quan-tri/danh-sach";
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res))
        })
      },
  },
};
</script>
