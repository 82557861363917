const getJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      email : item.email,
      content : item.content,
      phone: item.phone,
      sort: item.sort,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      email : item.email,
      content : item.content,
      phone: item.phone,
      sort: item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      email : null,
      content : null,
      phone: null,
      sort: 0,
    };
  };


  export const tuVanModel = {
    sendJson , getJson, baseJson,
  };
