<template>
    <!-- Steps To Follow-->
    <section class="need-to-know-section steps-to-follow">
      <!-- <div class="floating-bg">
        <img src="@/assets/img/bg/health-care.png" alt="Image" />
      </div> -->
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 mb-2 text-center aos" data-aos="fade-up">
            <div class="section-header-one text-center">
              <h2 class="section-title"><span>Chương trình</span> khuyến mãi</h2>
            </div>
          </div>
          <div class="aos" data-aos="fade-up">
            <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="5000">
              <Slide v-for="(item, index) in this.list" :key="index.id">
                <div style="height: 100%;" class="img-doctor-sub">
                  <img
                    :src="item.fileImage?.urlFile" alt="logo"
                    class=""
                    style="height: 100%; width: 100%; border-radius: 10px;"
                    loading="lazy"
                  />
                </div>
              </Slide>
            </Carousel>
            <div class="btn-w-slide">
              <button class="btn-slide btn-prev" @click="prevSlide"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
              <button class="btn-slide btn-next"@click="nextSlide"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Steps To Follow -->
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import Flickity from 'vue-flickity';
  import {Carousel, Pagination, Slide} from "vue3-carousel";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
      Flickity,
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {
        perPage: 4,
        list: [],
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          768: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 4,
            snapAlign: 'start',
          },
        },
        flickityOptions: {
          initialIndex: 1,
          initialSlides: 4,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: false,

          // any options from Flickity can be used
        },

      };
    },
    props: {
      promotions: { type : Object}
    },
    watch:{
    '$props':{
      handler: function (val) {
        this.list = val.promotions;
      },
      deep: true
    }
  },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },

    methods: {
      nextSlide() {
        this.$refs.carousel.next();
      },
      prevSlide() {
        this.$refs.carousel.prev();
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>

  .list-card-doctor{
    width: 100%;
  }

  .card-doctor{
    margin: 0 10px;
    background-color: #fff;
    transition: all 0.5s;
    border-radius: 30px
  }

  .img-doctor{
    height: 300px;
    padding: 10px 10px 0 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  @media (max-width: 1200px) {
    .card-img-doctor{
      height: 300px;
    }
  }

  @media (max-width: 992px) {
    .card-img-doctor{
      height: 350px;
    }
  }
  .img-doctor .img-doctor-sub{
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  .card-doctor .img-doctor .img-doctor-sub img{
    border-radius: 15px;
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  }

  .card-doctor .img-doctor .img-doctor-sub:hover img{
    transform: scale(1.15);
  }

  .card-doctor .img-doctor .img-doctor-sub .fav-item{
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .card-doctor .img-doctor .img-doctor-sub .featured-rating{
    background: #FBBF24;
    border-radius: 6px;
    color: #ffffff;
    padding: 3px 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .card-doctor .img-doctor .img-doctor-sub .fav-icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-doctor .img-doctor .img-doctor-sub .fav-icon i{
    color: red;
  }

  .card-doctor-detail .card-doctor-category{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .card-doctor-detail .card-doctor-category .list-user-details h5{
    margin-bottom: 0px;
    font-weight: bold;
  }

  .card-doctor-detail .list-profile-detail .list-category-tag{
    padding: 5px 10px;
    transition: all 0.5s;
    font-size: 14px;
    font-weight: 500;
  }

  .card-doctor-detail .list-profile-detail .list-category{
    margin-left: 10px;
  }

  .card-doctor-detail .list-profile-detail .tag-red{
    background: #FDF2F8;
    color: #EC4899;
  }

  .card-doctor-detail .list-profile-detail .tag-green{
    background: #CCFBF1;
    color: #14B8A6;
  }

  .card-doctor-detail .list-profile-detail .tag-purple{
    background: #DDD6FE;
    color: #7C3AED;
  }

  .card-doctor-detail .list-profile-detail{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-doctor-detail .list-profile-detail .list-btn .consult-btn{
    padding: 3px 15px 3px 15px;
    background-color: #d4a04c;
    font-weight: 500;
    font-size: 16px;
    border-radius: 35px;
    box-shadow: inset 0 0 0 0 #ffffff;
    transition: all 0.7s;
    color: #ffffff;
  }

  .card-doctor-detail .list-profile-detail .list-btn .consult-btn:hover{
    box-shadow: inset 0 0 0 50px #ffffff;
    color: #d4a04c;
  }

  .card-doctor-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #43b738;
    padding: 5px;
    border-radius: 5px;
  }

  .card-doctor-name h5{
    margin-bottom: 0px !important;
    color: #fff !important;
  }
  </style>
