import {apiClient} from "@/state/modules/apiClient";

const controller = "Service";

export const actions = {
    async getAll({commit}) {
        return apiClient.get(controller + "/get-all-core");
    },
    async create({commit}, values) {
        return apiClient.post(controller +"/create", values);
    },
    async update({commit, dispatch}, values) {
        return apiClient.post(controller +"/update", values);
    },
    async delete({commit}, values) {
        return await apiClient.post(controller +"/delete", values);
    },
    async getById({commit}, values) {
        return apiClient.post(controller +"/get-by-id" , values);
    },
    async getAllSelected({commit}) {
        return apiClient.get(controller + "/get-all-selected");
    },
};
