const getJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      value : item.value,
      origin : item.origin,
      ingredient: item.ingredient,
      indications: item.indications,
      dosage: item.dosage,
      type: item.type,
      fileImage: item.fileImage,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      value : item.value,
      origin : item.origin,
      ingredient: item.ingredient,
      indications: item.indications,
      dosage: item.dosage,
      type: item.type,
      fileImage: item.fileImage,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      value : null,
      origin : "",
      ingredient: "",
      indications: "",
      dosage: "",
      type: null,
      fileImage: [],
    };
  };


  export const thuocModel = {
    sendJson , getJson, baseJson,
  };
