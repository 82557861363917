<template>
    <div class="account-page">
      <div class="main-wrapper">
        <layoutheader></layoutheader>

        <!-- Page Content -->
        <div class="content top-space">
          <div class="container">
            <div class="row login-right">
                <div class="col-md-12"
                    style="
                        text-align: center;
                        font-family: 'Nunito Bold';
                        font-size: 28px;
                        color: #5b303a;
                    "
                >
                    THÔNG TIN CÁ NHÂN
                </div>
                <div class="col-md-4 avt-use" style="cursor: pointer;">
                    <div style="position: relative;">
                        <input
                            id="formFileSm"
                            name="file-input"
                            ref="fileInput"
                            type="file"
                            class="form-control"
                            @change="upload($event)"
                            style="display: none;"
                        />
                        <img v-if="this.currentUserAuth.avatar != null"
                            :src="url + this.currentUserAuth.avatar?.fileId"
                            @click="triggerFileInput"
                            class="image-header"
                        ></img>
                        <img v-else
                            src="@//assets/img/phuongthanh/logo/logo-doctor-null.png"
                            @click="triggerFileInput"
                            class="image-header"
                        >
                        </img>
                        <i class="fa-solid fa-camera"></i>
                    </div>
                    <p>{{ this.currentUserAuth.fullName }}</p>
                </div>
                <div class="col-md-8" style="display: flex; justify-content: center; flex-direction: column;">
                    <div class="row mb-3" style="display: flex; justify-content: center; align-items: center;">
                        <div class="col-md-3">
                            <label for="">Tên </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group mb-0">
                                <input
                                    v-model="currentUserAuth.fullname"
                                    placeholder="Vui lòng nhập tên"
                                    name="name"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" style="display: flex; justify-content: center; align-items: center;">
                        <div class="col-md-3">
                            <label for="">Địa chỉ </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group mb-0">
                                <input
                                    v-model="currentUserAuth.fullname"
                                    placeholder="Vui lòng nhập địa chỉ"
                                    name="name"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" style="display: flex; justify-content: center; align-items: center;">
                        <div class="col-md-3">
                            <label for="">Email </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group mb-0">
                                <input
                                    v-model="currentUserAuth.fullname"
                                    placeholder="Vui lòng nhập Email"
                                    name="name"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row" style="display: flex; justify-content: center;">
                        <button to="/register" class="btn edit-btn me-2">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button @click="showPopup = true" class="btn lock-btn"
                            ><i class="feather-lock"></i></button
                        >
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->

        <indexfivefooter></indexfivefooter>
      </div>
    </div>
  </template>
  <script>
  import {notifyModel} from "@/models/notifyModel";

  export default {
    created() {
        let authUser = localStorage.getItem("auth-user");
        if (authUser) {
            let jsonUserCurrent = JSON.parse(authUser);
            this.currentUserAuth = jsonUserCurrent;
          //  console.log("CURRENT USER AUTH  created : ", this.currentUserAuth)
        }
    },
    methods: {
        async submitForm() {
          await this.$store.dispatch("authStore/login", {"userName": "admin", "password": "DongThap@123"}).then((res) => {
            if (res && res.code ==0 ) {
              this.listMenu = res.data.menu;
              localStorage.setItem('auth-user', JSON.stringify(res.data));
              // window.location.href="/admin"
              // this.$router.push("/quan-tri/profile");
              window.location.href = "/quan-tri/danh-sach";
            }
            this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res))
          })
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        async upload() {

            if (this.currentUserAuth != null && this.currentUserAuth.fileImage != null)
            {
            // console.log("LOG UPDATE NE : ", event.target.files )
            axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.currentUserAuth.fileImage.fileId}`).then((response) => {
                this.currentUserAuth.files = null;
                //   console.log('log currentUserAuth file remove', this.currentUserAuth.files);
            }).catch((error) => {
                // Handle error here
                //    console.error('Error deleting file:', error);
            });
            }
            console.log("LOG UPLOAD : ", event.target)
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('files', event.target.files[0])
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                // console.log("LOG UPDATE : ", response);
                let resultData = response.data
                if (response.data.code == 0){
                this.currentUserAuth.fileImage={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
                console.log("LOG UPDATE : ", this.currentUserAuth);
                }
            }).catch((error) => {
                // Handle error here
                console.error('Error deleting file:', error);
            });

            }
        },
    },
  };
  </script>

<style>
.edit-btn, .lock-btn{
    width: auto !important;
}

.edit-btn{
    background-color: #28883b !important;
    color: #fff !important;
}
.edit-btn:hover{
    background-color: #26a33f !important;
    color: #fff !important;
}

.lock-btn{
    background-color: #f46a6a !important;
    color: #fff !important;
}
</style>
