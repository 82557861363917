<template>
  <section class="section products-sec">
    <div class="container">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Sản phẩm nổi bật</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link to="/thuoc/chi-tiet"
                >Xem tất cả <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4" v-for="(item, index) in this.list" :key="index">
          <div class="products-card">
            <div class="product-card-img">
              <router-link 
                :to="{
                  path: `/thuoc/chi-tiet/${item.id}`,
                }"
                >
                <div class="img-thuoc">
                  <img :src="urlFile + item.fileImage[0].fileId" alt=""/>
                </div>
                <div class="product-content">
                  <h6>{{ item.type.name }}</h6>
                  <h4>{{ item.name }}</h4>
                  <!-- <span class="badge">60 viên</span> -->
                  <div class="product-cart">
                    <div class="cart-price">
                      <h5>{{ item.value }}</h5>
                    </div>
                    <router-link to="/thuoc/chi-tiet" class="cart-icon">
                      <i class="feather-shopping-bag"></i>
                    </router-link>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script >

import { thuocModel } from "@/models/thuocModel";
import 'vue-multiselect/dist/vue-multiselect.css';
  export default {
    components: {

    },
    data() {
      return {
        title: "DANH SÁCH",
        model: thuocModel.baseJson(),
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view/`,
      };
    },
    name: "pharmacy/user",

    created() {
      this.getData();
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        }
    },

    methods: {
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch("thuocStore/getPagingParams", params ).then(res => {
                this.list = res.data.data
                this.totalRows = res.data.totalRows
                this.numberOfElement = res.data.data.length
            });
        },
    }
};
</script>

<style>


</style>
