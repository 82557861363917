<template>
  <div class="main-wrapper home-ten">
    <mobibreadcrumb :title="title"/>
    <div class="page-wrapper">
      <div class="container" style="padding-top: 10px ;">
        <div class="row" >
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="terms-content"  v-if="model && model.content">
                <div class="noidung" style="font-size: 14px" v-html="model.content">
                </div>
              </div>
            </div>
          </div>
          <mobisidebar></mobisidebar>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import BlogSidebar from "@/views/News/baiviet/newSidebar.vue";
export default {
  components: {BlogSidebar},
  data() {
    return {
      title: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      BlogGrid: BlogGrid,
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      isArray : true,
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
      this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        this.getData();
      }
    }
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: "sort",
        sortDesc: true,
        menuId : this.$route.params.id

      };
      await this.$store.dispatch("newsStore/getByMenuId", params).then((res) => {
        if (res != null && res.code ===0) {
          this.model = res.data;
          this.title = this.model != null ? this.model.name : "";
          this.text1 = (this.model != null && this.model.menu) ? this.model.menu.name : "";
        }
      })
    },
  },
}
</script>

<style>

.card-news{
  border-radius: 20px !important;
}

.card-news .blog-image img{
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.card-news .blog-image{
  height: 250px !important;
}

.card-news .blog-image a{
  height: 100%;
}

.noidung {
  text-align: justify;
  font-family: 'Nunito Light' !important;
  font-weight: 500;
}

.noidung .image {
  text-align: center;
  width: 100% !important;
}

.content-title {
  text-align: center;
}

.blog-title-details{
  font-size: 16px !important;
  justify-content: center !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
