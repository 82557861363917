const getJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      unitGroup : item.unitGroup,
      department : item.department,
      sort : item.sort,
    };
  };
  const sendJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      unitGroup : item.unitGroup,
      department : item.department,
      sort : item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      unitGroup : null,
      department : null,
      sort : 0,
    };
  };

  export const roomModel = {
    sendJson , getJson, baseJson,
  };
