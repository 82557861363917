<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title" style="float: left;">Quản lý Header</h3>
                <div class="card-tools" style="float: right;">
                  <a
                      href="#info_modal"
                      data-bs-toggle="modal"
                      size="sm"
                      type="button"
                      class="btn btn-tool card-add"
                  >
                    <i class="fas fa-plus"></i>
                  </a>
                  <button type="button" class="btn btn-tool" @click="getData">
                    <i class="fas fa-retweet"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-12">
                        <div
                            class="col-sm-12 d-flex justify-content-left align-items-center"
                        >
                          <div>
                            Hiển thị
                            <label class="d-inline-flex align-items-center" style="color: #d4a04c;">
                              {{ this.list.length }}
                            </label>
                            trên tổng số <span style="color: red; font-weight: bold;">{{ totalRows }}</span> dòng
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-new-table">
                      <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                          <thead>
                          <th class="col150 cursor td-stt" style="text-align: center;">
                            STT
                          </th>
                          <th class="col150 cursor" style="text-align: center;">
                            Hình ảnh
                          </th>
                          <th class="col100 cursor td-xuly" style="text-align: center;">
                            Xử lý
                          </th>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in list" :key="index">
                            <td style="text-align: center">
                              {{ index + ((currentPage-1)*perPage) + 1}}
                            </td>
                            <td style="text-align: center">
                              <div v-if="item.fileImage != null">
                                <img
                                    :src="url + item.fileImage.fileId"
                                    class="rounded-0 image-slider"
                                ></img>
                              </div>
                            </td>
                            <td style="text-align: center">
                              <a
                                  href="#delete_modal"
                                  data-bs-toggle="modal"
                                  class="btn btn-outline btn-sm"
                                  v-on:click="handleShowDeleteModal(item.id)"
                              >
                                <i class="fas fa-trash-alt text-danger me-1"></i>
                              </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                        class="modal fade"
                        id="info_modal"
                        aria-hidden="true"
                        role="dialog"
                        data-bs-backdrop="static"
                        ref="ref_info_modal"
                    >
                      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Thêm slider</h5>
                            <b-button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></b-button>
                          </div>
                          <div class="modal-body">
                            <Form
                                class="login"
                                @submit="handleSubmit"
                                :validation-schema="schema"
                                v-slot="{ errors }"
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="mb-2 ">
                                    <label for="formFileSm" class="text-left mb-0">Hình ảnh
                                    </label>
                                    <span style="color: red">&nbsp;(Kích thước chuẩn : 1920px x 734px) *</span>
                                    <template  v-if="model.fileImage" >
                                      <a
                                          class="ml-25"
                                          :href="`${urlFile}/${model.fileImage.fileId}`"
                                      ><i
                                          :class="`${getColorWithExtFile(model.fileImage.ext)} me-2 ${getIconWithExtFile(model.fileImage.ext)}`"
                                      ></i>{{model.fileImage.fileName }}</a>

                                    </template>
                                    <Field
                                        id="formFile"
                                        name="fileImage"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        class="form-control"
                                        @change="upload($event)"
                                        :class="{ 'is-invalid': errors.fileImage }"
                                         ref="fileupload"
                                    />
                                    <div class="invalid-feedback">{{ errors.fileImage }}</div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="mb-3">
                                      <label class="text-left">Thứ tự</label>
                                      <input
                                          v-model="model.sort"
                                          class="form-control"
                                          type="number"
                                          id="sort"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-end pt-2 mt-3">
                                <b-button
                                    type="button"
                                    class="btn si_accept_cancel btn-submit w-md btn-out"
                                    data-bs-dismiss="modal"
                                >
                                  Đóng
                                </b-button>
                                <b-button  type="submit" variant="success" class="btn-submit w-md ms-1 cs-btn-primary">
                                  Lưu
                                </b-button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                        class="modal fade"
                        id="delete_modal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        ref="ref_delete"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="acc_title">Xóa</h5>
                            <b-button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></b-button>
                          </div>
                          <div class="modal-body" style="font-weight: 500;">
                            <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                          </div>
                          <div class="modal-footer">
                            <b-button class="btn btn-delete w-md si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                              Xóa
                            </b-button>
                            <b-button
                                type="button"
                                class="btn si_accept_cancel btn-submit w-md btn-out"
                                data-bs-dismiss="modal"
                            >
                              Đóng
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="col-md-6 col-sm-6 mt-2">
                        <div>
                          Hiển thị
                          <label class="d-inline-flex align-items-center">
                            <b-form-select
                                class="form-select form-select-sm"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                style="width: 70px; text-align: center;"
                            ></b-form-select
                            >&nbsp;
                          </label>
                          trên tổng số {{ totalRows }} dòng
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 mt-2" style="display: flex; justify-content: flex-end;">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="right"
                            size="sm"
                            class="my-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { headerModel } from "@/models/headerModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import {dichVuModel} from "@/models/dichVuModel";
export default {
  components: {
    VueDatePicker,
    Treeselect,
    VueMultiselect,
    Form,
    Field,
  },
  data() {
    return {
      title: "DANH SÁCH",
      model: headerModel.baseJson(),
      list: [],
      currentPage: 1,
      numberOfElement: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      totalRows: 1,
      sortBy: 'age',
      sortDesc: false,
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      theModal: null,
    };
  },
  name: "pharmacy/user",

  created() {
    this.getData();
  },
  mounted() {
    this.theModal = new Modal(document.getElementById('info_modal'));
    this.$refs.ref_info_modal.addEventListener('hidden.bs.modal', event => {
      this.model= headerModel.baseJson()
      this.$refs.fileupload.value = null;
      document.getElementById("formFile").value = null;
    });
    this.$refs.ref_delete.addEventListener('hidden.bs.modal', event => {
      this.model= headerModel.baseJson()
    });
  },

  setup() {
    const schema = Yup.object().shape({
      fileImage: Yup.mixed()
          .required("File không được bỏ trống!")
          .test("fileSize", "File quá lớn", value => {
            return value && value.size <= 2 * 1024 * 1024; // <= 2MB
          })
          .test("fileFormat", "Định dạng file không hợp lệ", value => {
            return value && ["image/jpeg", "image/png", "image/gif"].includes(value.type);
          }),
    });
    return {
      schema,
    };
  },

  watch: {
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    }
  },

  methods: {
    async getData() {
      await this.$store.dispatch("pageInfoStore/getAllHeader").then(res => {
        if (res != null && res.code ===0)
        {
          this.list = res.data
          this.totalRows = res.data.length
          this.numberOfElement = res.data.length
        }

      });
    },
    handleShowDeleteModal(id) {
      this.model.id = id;
      this.showDeleteModal = true;
    },
    async handleDelete() {
    //  console.log("LOG HANDLE DELETED : ", this.model)
      if (this.model.id != 0 && this.model.id != null) {
        await this.$store.dispatch("pageInfoStore/deleteHeader", { 'id': this.model.id }).then((res) => {
          if (res != null && res.code ===0) {
            this.showDeleteModal = false;
            this.getData();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
      }
    },

    async handleSubmit() {
        await this.$store.dispatch("pageInfoStore/createHeader", this.model).then((res) => {
          if (res != null && res.code ===0) {
            this.getData();
            this.theModal.hide();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
        });
    },


    async upload() {

      if (this.model != null && this.model.fileImage != null)
      {
        // console.log("LOG UPDATE NE : ", event.target.files )
        axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.fileImage.fileId}`).then((response) => {
          this.model.files = null;
          //   console.log('log model file remove', this.model.files);
        }).catch((error) => {
          // Handle error here
          //    console.error('Error deleting file:', error);
        });
      }
      console.log("LOG UPLOAD : ", event.target)
      if ( event.target &&  event.target.files.length > 0 ) {
        const formData = new FormData()
        formData.append('files', event.target.files[0])
        formData.append('code', "HEADER")
        axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {

          let resultData = response.data
          if (response.data.code == 0){
            this.model.fileImage={
              fileId: resultData.data.fileId,
              fileName: resultData.data.fileName,
              ext: resultData.data.ext,
            };
            //        console.log("LOG UPDATE : ", this.model);
          }
        }).catch((error) => {
          // Handle error here
          console.error('Error deleting file:', error);
        });

      }
    },

    getColorWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
        return 'text-danger';

    },
    getIconWithExtFile(ext) {
      if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
        return 'mdi mdi-file-image-outline';
    },

  }
};
</script>

<style>
.image-slider{
  width: 500px;
}
</style>

