<template>
  <div class="main-wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <adminbreadcrumb :title="title" :text="text" :text1="text1" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-12">
            <div class="profile-header">
              <div class="row align-items-center">
                <div class="col-auto profile-image">
                  <a href="javascript:void(0);">
                    <img
                      class="rounded-circle"
                      alt="User Image"
                      src="@/assets/admin/img/profiles/avatar-01.jpg"
                    />
                  </a>
                </div>
                <div class="col ml-md-n2 profile-user-info">
                  <h4 class="user-name mb-0">Nguyễn Việt Nhật</h4>
                  <h6 class="text-muted">nguyenvietnhat13052000@gmail.com</h6>
                  <div class="user-Location">
                    <i class="fa fa-map-marker"></i> Cao Lãnh, Đồng Tháp
                  </div>
                  <div class="about-text">
                    Điều quan trọng là phải chăm sóc bệnh nhân, được bệnh nhân theo dõi, nhưng điều đó sẽ xảy ra vào thời điểm có rất nhiều công sức và đau đớn.
                  </div>
                </div>
                <div class="col-auto profile-btn">
                  <a href="javascript:void(0)" class="btn card-add"> Edit </a>
                </div>
              </div>
            </div>
            <div class="profile-menu">
              <ul class="nav nav-tabs nav-tabs-solid">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#per_details_tab"
                    >Thông tin chung</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#password_tab"
                    >Mật khẩu</a
                  >
                </li>
              </ul>
            </div>
            <div class="tab-content profile-tab-cont">
              <!-- Personal Details Tab -->
              <div class="tab-pane fade show active" id="per_details_tab">
                <!-- Personal Details -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title d-flex justify-content-between">
                          <span>Thông tin cá nhân</span>
                          <a
                            class="edit-link"
                            data-bs-toggle="modal"
                            href="#edit_personal_details"
                            ><i class="fa fa-edit me-1"></i>Sửa</a
                          >
                        </h5>
                        <div class="row">
                          <p class="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">Họ và tên</p>
                          <p class="col-sm-10">Nguyễn Việt Nhật</p>
                        </div>
                        <div class="row">
                          <p class="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Ngày, tháng, năm sinh
                          </p>
                          <p class="col-sm-10">13/05/2000</p>
                        </div>
                        <div class="row">
                          <p class="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Email
                          </p>
                          <p class="col-sm-10">nguyenvietnhat13052000@gmail.com</p>
                        </div>
                        <div class="row">
                          <p class="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Điện thoại
                          </p>
                          <p class="col-sm-10">0386048626</p>
                        </div>
                        <div class="row">
                          <p class="col-sm-2 text-muted text-sm-end mb-0">Địa chỉ</p>
                          <p class="col-sm-10 mb-0">
                            Đường 30/4, Phường 1, TP Cao Lãnh, Tỉnh Đồng Tháp.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Personal Details -->
              </div>
              <!-- /Personal Details Tab -->

              <!-- Change Password Tab -->
              <div id="password_tab" class="tab-pane fade">
                <div class="card">
                  <div class="card-body card-pass">
                    <h5 class="card-title">Thay đổi mật khẩu</h5>
                    <div class="row">
                      <div class="col-md-10 col-lg-6">
                        <form>
                          <div class="form-group">
                            <label class="mb-2">Mật khẩu cũ</label>
                            <input type="password" class="form-control" />
                          </div>
                          <div class="form-group">
                            <label class="mb-2">Mật khẩu mới</label>
                            <input type="password" class="form-control" />
                          </div>
                          <div class="form-group">
                            <label class="mb-2">Xác nhận mật khẩu mới</label>
                            <input type="password" class="form-control" />
                          </div>
                          <div style="width: 100%;">
                            <b-button class="btn btn-save" type="submit">
                              Lưu
                            </b-button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Change Password Tab -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- Edit Details Modal -->
  <div class="modal fade" id="edit_personal_details" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Personal Details</h5>
          <b-button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></b-button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row form-row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">First Name</label>
                  <input type="text" class="form-control" value="John" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Last Name</label>
                  <input type="text" class="form-control" value="Doe" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Date of Birth</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Email ID</label>
                  <input type="email" class="form-control" value="johndoe@example.com" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Mobile</label>
                  <input type="text" value="+1 202-555-0125" class="form-control" />
                </div>
              </div>
              <div class="col-12">
                <h5 class="form-title"><span>Address</span></h5>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Address</label>
                  <input type="text" class="form-control" value="4663 Agriculture Lane" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">City</label>
                  <input type="text" class="form-control" value="Miami" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">State</label>
                  <input type="text" class="form-control" value="Florida" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Zip Code</label>
                  <input type="text" class="form-control" value="22434" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Country</label>
                  <input type="text" class="form-control" value="United States" />
                </div>
              </div>
            </div>
            <b-button type="submit" class="btn btn-primary w-100">
              Save Changes
            </b-button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Details Modal -->
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Profile",
      text: "Dashboard",
      text1: "Profile",
      startdate: currentDate,
    };
  },
};
</script>

<style>
.card-pass .card-title{
  /* text-align: center; */
}
.card-pass .row{
  /* display: flex;
  justify-content: center */
}

.btn-save{
  width: 120px;
  background-color: #be8b43;
  border: none;
  font-weight: bold
}
</style>
