<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <pharmacybreadcrumb2 :title="title" :text="text" :text1="text1" />
        <div class="row">
          <div class="col-md-6">
              <div class="card">
              <div class="card-body">
                <treeVue
                    :items="treeView"
                    color="blue"
                    text-field-name="label"
                    :isCheckable="false"
                    :value="model.parentId"
                    @onSelect="itemClick"
                >
                  <!-- <template v-for="(value) in this.model.children" :key="value.id">
                    <span >{{ value.name}}</span>
                  </template> -->
                  <template v-slot:item-prepend-icon="treeViewItem">
                    <i :class="`${treeViewItem.icon}`" style="height: 30px; width: 30px;"></i>
                  </template>
                </treeVue>
              </div>
              </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit">
                  <div class="row">
                    <div class="col-12">
                      <input type="hidden" v-model="model.id"/>
                      <div class="mb-3">
                        <label class="text-left">Tên menu</label>
                        <span style="color: red">&nbsp;*</span>
                        <Field
                            v-model="model.name"
                            placeholder="Vui lòng nhập tên menu"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.name }"
                        />
                        <div class="invalid-feedback">{{ errors.name }}</div>
                      </div>
                      <div class="mb-3">
                        <label class="text-left">Path</label>
                        <span style="color: red">&nbsp;*</span>
                        <Field
                            v-model="model.path"
                            placeholder="Vui lòng nhập path"
                            name="path"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.path }"
                        />
                        <div class="invalid-feedback">{{ errors.path }}</div>
                      </div>
                      <div class="mb-3">
                        <label class="text-left">Icon</label>
                        <span style="color: red">&nbsp;*</span>
                        <Field
                            v-model="model.icon"
                            placeholder="Vui lòng nhập icon"
                            name="icon"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.icon }"
                        />
                        <div class="invalid-feedback">{{ errors.icon }}</div>
                      </div>
                      <div class="mb-3">
                        <label class="text-left">Menu cha</label>
                        <treeselect
                            ref="tbTreeSelect"
                            :options="treeView"
                            :default-expand-level="2"
                            v-model="model.parentId"
                            :key="keyId"
                            placeholder="Nhấp vào để chọn"
                        >
                        </treeselect>
                      </div>
                      <div class="mb-3">
                        <label class="text-left">Thứ tự</label>
                        <input
                            id="sort"
                            v-model="model.sort"
                            type="number"
                            class="form-control"
                            placeholder="Nhập thứ tự"
                        />
                      </div>


                    </div>
                  </div>
                  <div class="text-end pt-2 mt-3">
                    <b-button v-if="model.id" type="button" variant="warning" class="ms-1"
                              v-on:click="handleResetForm"
                    > Đặt lại
                    </b-button>
                    <a
                        href="#delete_user"
                        data-bs-toggle="modal"
                        size="sm"
                        class="btn btn-sm bg-danger-light px-3 ms-1"
                        style="font-size: 16px; padding-top: 6px; padding-bottom: 6px;"
                        v-on:click="handleShowDeleteModal(model.id)">
                        Xóa menu
                    </a>
                    <b-button
                        type="submit"
                        variant="success"
                        class="ms-1 cs-btn-primary px-3"
                    >
                      Lưu Menu
                    </b-button>
                  </div>
                </form>
                <div
                    class="modal fade"
                    id="delete_user"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="acc_title">Xóa</h5>
                        <b-button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></b-button>
                        </div>
                        <div class="modal-body" style="font-weight: 500;">
                          <p id="acc_msg">Dữ liệu được chọn sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa dữ liệu này?</p>
                        </div>
                        <div class="modal-footer">
                          <b-button class="btn btn-danger si_accept_cancel" v-on:click="handleDelete" data-bs-dismiss="modal">
                            Xóa
                          </b-button>
                          <b-button
                              type="button"
                              class="btn bg-default-light"
                              data-bs-dismiss="modal"
                          >
                              Đóng
                          </b-button>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import VueSelect from 'vue-select';
import Loading from "vue3-loading-overlay";
import Paginate from "vuejs-paginate-next";
import 'vue-select/dist/vue-select.css';
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import {menuModel} from "@/models/menuModel";
import "vue-tree-view/dist/style.css";

import { RootTree } from "vue3-jstree-component"
import Vue3TreeVue from 'vue3-tree-vue'
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
      loading: Loading,
      paginate: Paginate,
      VueMultiselect,
      VueSelect,
      Treeselect,
      // Tree,
      RootTree,
      treeVue: Vue3TreeVue,
      Form,
      Field,
  },
  data() {
    return {
      title: "Menu",
      text: "Dashboard",
      text1: "Menu",
      user: [],
      data: [],
    fields: [
      { key: 'STT',
        label: 'STT',
        class: 'td-stt',
        sortable: false,
        thClass: 'hidden-sortable'},
      {
        key: "userName",
        label: "Tài khoản",
        class: 'td-username',
        sortable: true,
        thStyle: "text-align:center",
        thClass: 'hidden-sortable'
      },
      {
        key: "fullName",
        label: "Họ và tên",
        class: 'td-ten',
        sortable: true,
        thStyle: "text-align:center",
        thClass: 'hidden-sortable'
      },
      {
        key: "unitRole",
        label: "Quyền",
        class: 'td-email',
        thStyle: "text-align:center",
        sortable: true,
        thClass: 'hidden-sortable'
      },
      {
        key: 'process',
        label: 'Xử lý',
        class: 'td-xuly',
        sortable: false,
        thClass: 'hidden-sortable'
      }
    ],
    showModal: false,
    showDeleteModal: false,
    submitted: false,
    model: menuModel.baseJson(),
    listParent: [],
    treeView: [],
    isCheckable: false,
    hideGuideLines: false,
    selectedItem: null,
    expandAll: true,
    itemEvents: {
      mouseover: function () {
      },
      contextmenu: function () {
        arguments[2].preventDefault()
      }
    },
      keyId : null,
    };
  },
  name: "pharmacy/user",
  created() {

      this.GetTreeList();
  },

  setup() {
      const schema = Yup.object().shape({
          name: Yup.string().required("Tên menu không được bỏ trống !"),
          path: Yup.string().required("Path không được bỏ trống !"),
          icon: Yup.string().required("Icon menu không được bỏ trống !"),

      });
      return {
          schema,
      };
  },
  methods: {
  async GetTreeList(){
    await this.$store.dispatch("menuStore/getTreeList").then((res) => {
      if (res)
      {
        this.treeView = res.data;
      }
    })
  },
  async handleDelete() {
    if (this.model.id != 0 && this.model.id != null && this.model.id) {
      await this.$store.dispatch("menuStore/delete", {id :this.model.id}).then((res) => {
        if (res != null && res.code ===0) {
          this.GetTreeList();
          this.model = menuModel.baseJson();
          this.keyId = null;
          this.showDeleteModal = false;
        }
        this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
      });
    }
  },
  handleResetForm() {
    this.model = menuModel.baseJson();
    this.$refs.tbTreeSelect.clear();
  },
  handleShowDeleteModal(id) {
    this.model.id = id;
    this.showDeleteModal = true;
  },
  async handleSubmit(e) {
      if (
          this.model.id != 0 &&
          this.model.id != null &&
          this.model.id
      ) {
        // Update model
        await this.$store.dispatch("menuStore/update", this.model).then((res) => {
          if (res != null && res.code ===0) {
            this.$router.go();
            // this.$refs.tbTreeSelect.clear();
            // this.GetTreeList();
            // this.showModal = false;
            // this.keyId = null;
            // this.model = menuModel.baseJson();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res))
        });
      } else {
        // Create model
        await this.$store.dispatch("menuStore/create", this.model).then((res) => {
          if (res != null && res.code ===0) {
            this.$router.go();
            // this.$refs.tbTreeSelect.clear();
            // this.GetTreeList();
            // this.showModal = false;
            // this.keyId = null;
            // this.model = menuModel.baseJson();
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res))
        });
      }
  },
  async handleUpdate(id) {
    await this.$store.dispatch("menuStore/getById", {id : id}).then((res) => {
      if (res != null && res.code ===0) {
        this.model = menuModel.getJson(res.data);
        this.showModal = true;
        this.keyId = (this.model != null && this.model.parentId != null ) ? this.model.parentId : null;
       // console.log("LOG MENU ID  : ", this.keyId);
        this.listParent = this.listParent.filter(x => x.id != id);
      } else {
        this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
      }
    });
  },
 async itemClick (node) {
   await this.handleUpdate(node.id);
  }

},
};
</script>
<style>
.tiny_horizontal_margin i{
  display: flex;
  align-items: center;
}

.tiny_horizontal_margin i::before{
  font-size: 18px;
}
.tree-item .d-flex{
  padding: 3px;
}
/* .tree-item .d-flex{
  font-weight: bold;
  color: #d4a04c;
  text-transform: uppercase;
}

.node-child .tree-item .d-flex{
  font-weight: unset;
  color: #000;
  text-transform: none;
} */
</style>



