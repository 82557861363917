<template>
    <div class="main-Wrapper">
      <pharmacyheader></pharmacyheader>
      <pharmacysidebar></pharmacysidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-info-doctor">
                <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit(onSubmit)">
                    <div class="card-header-doctor row">
                        <div class="col-md-3">
                            <div class="widget-profile pro-widget-content">
                                <div class="widget-profile-sub">
                                    <div class="profile-info-widget">
                                        <div class="booking-doc-img">
                                            <input
                                                id="formFileSm"
                                                name="file-input"
                                                ref="fileInput"
                                                type="file"
                                                class="form-control"
                                                @change="upload($event)"
                                                style="display: none;"
                                                :disabled="isView"
                                            />
                                            <img v-if="this.model.avatar != null"
                                                :src="url + this.model.avatar?.fileId"
                                                @click="triggerFileInput"
                                                class="image-header"
                                            ></img>
                                            <img v-else
                                                src="@//assets/img/phuongthanh/logo/logo-doctor-null.png"
                                                @click="triggerFileInput"
                                                class="image-header"
                                            ></img>
                                        </div>
                                        <div class="profile-det-info">
                                            <h3>{{ this.model.name }}</h3>
                                            <div class="patient-details">
                                                <h5 class="mb-0">{{ this.model.phone }}</h5>
                                            </div>
                                                <span class="badge doctor-role-badge"><i class="fa-solid fa-circle"></i>Dentist</span>
                                            </div>
                                        </div>
                                    </div>
                                    <b-tabs pills vertical nav-class="flex-column" card v-model="activeTab" style="flex-wrap: wrap; margin-right: 0px;">
                                        <b-tab>
                                            <template #title>
                                                <i class="fa-solid fa-user-pen" style="margin-right: 7px;"></i> Thông tin chung
                                            </template>
                                        </b-tab>
                                      <b-tab>
                                        <template #title>
                                          <i class="fa-solid fa-graduation-cap" style="margin-right: 7px;"></i>Hồ sơ bác sĩ
                                        </template>
                                      </b-tab>s
                                        <b-tab>
                                            <template #title>
                                                <i class="fa-solid fa-graduation-cap" style="margin-right: 7px;"></i>Học vấn
                                            </template>
                                        </b-tab>
                                        <b-tab>
                                            <template #title>
                                            <i class="fa-solid fa-shapes" style="margin-right: 7px;"></i> Kinh nghiệm
                                            </template>
                                        </b-tab>
                                        <b-tab>
                                            <template #title>
                                                <i class="fa-solid fa-medal" style="margin-right: 7px;"></i> Giải thưởng
                                            </template>
                                        </b-tab>
                                    <!-- Thêm các tab khác ở đây nếu cần -->
                                    </b-tabs>
                                </div>
                            </div>
                        <div class="col-md-9">
                            <div class="info-doctoc-right">
                                <div class="row">
                                    <div class="col-md-12">
                                        <b-card no-body>
                                            <b-row>
                                            <!-- Cột chứa các tab -->

                                            <!-- Cột chứa nội dung -->
                                                <b-col cols="12">
                                                    <div class="tab-content" style="padding: 20px;">
                                                        <!-- Nội dung cho "Thông tin chung" -->
                                                        <div v-if="activeTab === 0">
                                                            <ThongTinChung
                                                                :model="model"
                                                                :listGender="listGender"
                                                                :listKhoa="listKhoa"
                                                                :listPhong="listPhong"
                                                                :listDonVi="listDonVi"
                                                                :listDichVu="listDichVu"
                                                                :listHonNhan="listHonNhan"
                                                                :listChuyenNganh="listChuyenNganh"
                                                                :listLevel="listLevel"
                                                                :itemsNoiSinh="itemsNoiSinh"
                                                                :itemsDiaChi="itemsDiaChi"
                                                                :listTinh="listTinh"
                                                                :listTP="listTP"
                                                                :listXa="listXa"
                                                                :listTinhDiaChi="listTinhDiaChi"
                                                                :listTPDiaChi="listTPDiaChi"
                                                                :listXaDiaChi="listXaDiaChi"
                                                                @submit="fetchData"
                                                            >
                                                        </ThongTinChung>
                                                        </div>

                                                        <!-- Nội dung cho "Tab khác" -->
                                                        <div v-if="activeTab === 1">
                                                          <MoTa :model="model" >
                                                          </MoTa>
                                                        </div>
                                                      <div v-if="activeTab === 2">
                                                        <HocVan v-bind:data="model">
                                                        </HocVan>
                                                      </div>
                                                      <div v-if="activeTab === 3">
                                                        <KinhNghiem v-bind:data="model">
                                                        </KinhNghiem>
                                                      </div>
                                                      <div v-if="activeTab === 4">
                                                        <GiaiThuong v-bind:data="model" >
                                                        </GiaiThuong>
                                                      </div>

                                                        <!-- Thêm nội dung cho các tab khác ở đây nếu cần -->
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div
                            class="modal fade"
                            id="delete_user"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            ref="deleteUserModal"
                        >
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="acc_title">Hủy</h5>
                                    <b-button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></b-button>
                                    </div>
                                    <div class="modal-body" style="font-weight: 500;">
                                        <p id="acc_msg">Bạn có chắc muốn bỏ qua dữ liệu đang sửa không?</p>
                                    </div>
                                    <div class="modal-footer">
                                    <b-button class="btn btn-delete w-md si_accept_cancel" @click="huySubmit" data-bs-dismiss="modal">
                                        Xác nhận hủy
                                    </b-button>
                                    <b-button
                                        type="button"
                                        class="btn si_accept_cancel btn-submit w-md btn-out"
                                        data-bs-dismiss="modal"
                                    >
                                        Không
                                    </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { doctorModel } from "@/models/doctorModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import ThongTinChung from "@/views/admin/pages/doctor/thongtinchung.vue";
import HocVan from "@/views/admin/pages/doctor/hocvan.vue";
import MoTa from "@/views/admin/pages/doctor/mota";
import GiaiThuong from "@/views/admin/pages/doctor/giaiThuong";
import KinhNghiem from "@/views/admin/pages/doctor/kinhNghiem";
  export default {
    components: {
        VueDatePicker,
        Treeselect,
        VueMultiselect,
        Form,
        Field,
        HocVan,
        MoTa,
        ThongTinChung,
        GiaiThuong,
        KinhNghiem
    },
    data() {
      return {
        title: "DANH SÁCH",
        model: doctorModel.baseJson(),
        activeTab: 0, // Tab mặc định là tab đầu tiên
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        format : `dd/MM/yyyy`,
        locale: 'vi',
        isView: true,
        originalData: null,
        listGender: [],
        listKhoa: [],
        listPhong: [],
        listDonVi: [],
        listDichVu: [],
        listHonNhan: [],
        listChuyenNganh: [],
        listLevel: [],
        itemsNoiSinh:{
            address: null,
            province: null,
            district: null,
            commune: null,
            fullAddress: null,
        },
        itemsDiaChi:{
            address: null,
            province: null,
            district: null,
            commune: null,
            fullAddress: null,
        },
        listTinh: [],
        listTP: [],
        listXa: [],
        listTinhDiaChi: [],
        listTPDiaChi: [],
        listXaDiaChi: [],
      };
    },
    name: "pharmacy/user",

    created() {
    //   this.getData();
      this.getListGender();
      this.getListChuyenNganh();
      this.getListKhoa();
      this.getListPhong();
      this.getListDonVi();
      this.getListDichVu();
      this.getListHonNhan();
      this.getListLevel();
      this.getListTinh();
      this.getListTP("All");
      this.getListXa("All");
      this.fetchData();
    },

    watch: {
        'itemsNoiSinh.province': {
            handler(val) {
                if (val) {
                    this.getListTP(val.code);
                } else {
                    this.listTP = [];
                }
            },
            deep: true
        },
        'itemsNoiSinh.district': {
            handler(val) {
                if (val) {
                    this.getListXa(val.code);
                } else {
                    this.listXa = [];
                }
            },
            deep: true
        },
        'itemsDiaChi.province': {
            handler(val) {
                if (val) {
                    this.getListTP(val.code);
                } else {
                    this.listTPDiaChi = [];
                }
            },
            deep: true
        },
        'itemsDiaChi.district': {
            handler(val) {
                if (val) {
                    this.getListXa(val.code);
                } else {
                    this.listXaDiaChi = [];
                }
            },
            deep: true
        }
    },

    mounted() {
        // Gắn sự kiện Bootstrap modal
        const modalElement = this.$refs.deleteUserModal;
        modalElement.addEventListener('show.bs.modal', this.saveData);
    },
    methods: {
        async fetchData() {
            const response = await this.$store.dispatch('doctorStore/getById', { id: this.$route.params.id });
            this.model = response.data;
        },
        // async getData() {
        //     await this.$store.dispatch("doctorStore/getById", {"id": this.$route.params.id } ).then(res => {
        //         this.model = res.data
        //     });
        // },
        async getListGender(){
            await  this.$store.dispatch("commonStore/getAll","DM_GENDER").then((res) =>{
                this.listGender = res.data || [];
            })
        },
        async getListChuyenNganh(){
            await  this.$store.dispatch("commonStore/getAll","DM_MAJOR").then((res) =>{
                this.listChuyenNganh = res.data || [];
            })
        },
        async getListKhoa(){
            await  this.$store.dispatch("departmentStore/getAllSelected").then((res) =>{
                this.listKhoa = res.data || [];
            })
        },
        async getListPhong(){
            await  this.$store.dispatch("roomStore/getAllSelected").then((res) =>{
                this.listPhong = res.data || [];
            })
        },
        async getListDonVi(){
            await  this.$store.dispatch("unitGroupStore/getAllSelected").then((res) =>{
                this.listDonVi = res.data || [];
            })
        },
        async getListDichVu(){
            await  this.$store.dispatch("serviceStore/getAllSelected").then((res) =>{
                this.listDichVu = res.data || [];
            })
        },
        async getListHonNhan(){
            await  this.$store.dispatch("commonStore/getAll","DM_MARRIED").then((res) =>{
                this.listHonNhan = res.data || [];
            })
        },
        async getListLevel(){
            await  this.$store.dispatch("commonStore/getAll","DM_LEVEL").then((res) =>{
                this.listLevel = res.data || [];
            })
        },
        async getListTinh(){
            await  this.$store.dispatch("geographicalStore/getList", "0").then((res) =>{
                this.listTinh = res.data || [];
                this.listTinhDiaChi = res.data || [];
            })
        },
        async getListTP(code){
            await  this.$store.dispatch("geographicalStore/getListChild", code).then((res) =>{
                this.listTP = res.data || [];
                this.listTPDiaChi = res.data || [];
            })
        },
        async getListXa(code){
            await  this.$store.dispatch("geographicalStore/getListChild", code).then((res) =>{
                this.listXa = res.data || [];
                this.listXaDiaChi = res.data || [];
            })
        },

        changeView() {
            this.isView = false;
            this.$route.params.isView = false;
        },

        saveData() {
            // Lưu trữ bản sao của dữ liệu gốc khi mở modal
            this.originalData = { ...this.model };
            console.log("DATA: ", this.originalData);
        },

        huySubmit() {
            // Khôi phục dữ liệu gốc và đóng modal
            this.model = { ...this.originalData };
            this.isView = true
        },

        async handleUpdate(id) {
            await this.$store.dispatch("doctorStore/getById", {id : id}).then((res) => {
                if (res != null && res.code ===0) {
                    console.log(res)
                    this.model = doctorModel.getJson(res.data);
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },
        handleShowDeleteModal(id) {
            this.model.id = id;
            this.showDeleteModal = true;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("doctorStore/delete", { 'id': this.model.id }).then((res) => {
                if (res != null && res.code ===0) {
                    this.showDeleteModal = false;
                    this.fetchData();
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async onSubmit() {
            this.model.placeOfBirth = this.itemsNoiSinh;
            this.model.address = this.itemsDiaChi;
            await this.$store.dispatch("doctorStore/updateInfo", this.model).then((res) => {
                if (res != null && res.code ===0) {

                    this.fetchData();
                    this.model= doctorModel.baseJson();
                    this.isView = true;
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
            },
        async upload() {

            if (this.model != null && this.model.fileImage != null)
            {
            // console.log("LOG UPDATE NE : ", event.target.files )
            axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.fileImage.fileId}`).then((response) => {
                this.model.files = null;
                //   console.log('log model file remove', this.model.files);
            }).catch((error) => {
                // Handle error here
                //    console.error('Error deleting file:', error);
            });
            }
            console.log("LOG UPLOAD : ", event.target)
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('files', event.target.files[0])
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                // console.log("LOG UPDATE : ", response);
                let resultData = response.data
                if (response.data.code == 0){
                this.model.fileImage={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
                console.log("LOG UPDATE : ", this.model);
                }
            }).catch((error) => {
                // Handle error here
                console.error('Error deleting file:', error);
            });

            }
        },

        getColorWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
                return 'text-danger';

            },
        getIconWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
                return 'mdi mdi-file-image-outline';
        },
        addNodeToModel(node ){
            if(node != null && node.id){
            }
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>

<style>

.card-header-doctor{

    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
}

.info-doctoc-right{
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, .0784313725);
    border-radius: 10px;
}

@media (max-width: 768px) {
    .info-doctoc-right{
        margin-top: 20px;
    }
}
</style>

