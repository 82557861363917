const  getJson = (item) => {
  return {
    id: item.id,
    name: item.name,
    icon: item.icon,
    menu : item.menu,
    router: item.router,
    isPublic: item.isPublic,
    method : item.method,
    url : item.url,
    viewSource : item.viewSource,
    path : item.path,
    sort: item.sort,
    parentId : item.parentId,
  };
};

const sendJson  = (item) => {
  return {
    id: item.id,
    name: item.name,
    icon: item.icon,
    menu : item.menu,
    router: item.router,
    isPublic: item.isPublic,
    method : item.method,
    url : item.url,
    viewSource : item.viewSource,
    path : item.path,
    sort: item.sort,
    parentId : item.parentId,
  };
};

const baseJson = () => {
  return {
    id: null,
    name: null,
    icon: null,
    menu : null,
    router: null,
    isPublic: true,
    method: null,
    url : null,
    viewSource : null,
    path : null,
    sort: 0,
    parentId : null
  };
};

export const menuAppModel = {
  sendJson , getJson, baseJson,
};
