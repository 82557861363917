<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <div class="page-wrapper">
            <!-- Giới thiệu chung -->
            <div class="container" style="padding-top: 150px ;">
                <div class="row intro-nhakhoa">
                    <div class="col-lg-5 col-md-6 item-intro" style="display: flex; justify-content: center; align-items: center">
                        <div style="padding: 0 20px;">
                            <p class="title-intro color font-bold" style="font-size: 30px">
                                Ngôi nhà Trung Tâm Nha Khoa Phương Thành
                            </p>
                            <!-- <p class="title-intro-sub color font-light">
                                Tiên phong dẫn đầu
                            </p> -->
                            <p class="decs-intro font-regular" style="font-size: 16px">
                              Tại Trung tâm Nha khoa Phương Thành, chúng tôi tự hào sở hữu đội ngũ bác sĩ chuyên môn cao và tận tâm. Mỗi bác sĩ đều có kinh nghiệm dày dạn trong lĩnh vực nha khoa, với những chứng chỉ và bằng cấp uy tín. Chúng tôi luôn cập nhật kiến thức mới và công nghệ hiện đại để đảm bảo mang lại dịch vụ tốt nhất cho khách hàng.
                            </p>
                            <p class="decs-intro font-regular" style="font-size: 16px">
                              Đội ngũ bác sĩ của chúng tôi không chỉ giỏi chuyên môn mà còn luôn lắng nghe và thấu hiểu nhu cầu của từng bệnh nhân, giúp bạn cảm thấy thoải mái và an tâm trong suốt quá trình điều trị. Hãy đến với Trung tâm Nha khoa Phương Thành để được chăm sóc sức khỏe răng miệng một cách hoàn hảo nhất!
                            </p>
                            <p class="title-intro color font-bold text-center" style="font-size: 16px">
                              AN TOÀN - CHẤT LƯỢNG - HIỆU QUẢ

                            </p>
                          <p class="title-intro color font-bold text-center" style="font-size: 16px">
                            TẬN TÂM - TRÁCH NHIỆM
                          </p>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 img-intro">
                        <img src="@/assets/img/phuongthanh/gioithieu/tamnhin.png" alt="">
                    </div>
                </div>
            </div>
            <!-- /Giới thiệu chung -->

            <!-- ĐỘI NGŨ BÁC SĨ -->
<!--            <div style="margin-top: 20px;">-->
<!--                <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">-->
<!--                    <div style="padding: 40px;">-->
<!--                        <div class="row align-items-center">-->
<!--                            <div class="col-md-12 mb-2 text-center aos mt-3" data-aos="fade-up">-->
<!--                                <div class="section-header-one text-center">-->
<!--                                <h2 class="section-title"><span>Ngôi Nhà Của Những Chuyên Gia</span></h2>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div style="text-align: center; margin-top: 10px;">-->
<!--                                <img src="@/assets/img/phuongthanh/implant.png" alt="" style="width: 50px;">-->
<!--                                <h4 class="mt-1 mb-3 text-center">Đội ngũ quản lý</h4>-->
<!--                            </div>-->
<!--                            <div class="aos mb-3" data-aos="fade-up">-->
<!--                                <Carousel ref="carousel1" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">-->
<!--                                    <Slide v-for="(item, index) in filter1" :key="index.id">-->
<!--                                        <div class="list-doingu">-->
<!--                                            <div class="mb-3 item-doingu">-->
<!--                                                <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">-->
<!--                                                <div class="card-doingu">-->
<!--                                                    <div class="doctor-chuc-danh">-->
<!--                                                        <span>{{ item.Text }}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-name">-->
<!--                                                        <h3>{{ item.Name }}</h3>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-desc">-->
<!--                                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành, -->
<!--                                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.                                            -->
<!--                                                        <div class="doctor-button">-->
<!--                                                            <a href="">Tìm hiểu thêm</a>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </Slide>-->
<!--                                </Carousel>-->
<!--                                <div class="btn-w-slide">-->
<!--                                <button class="btn-slide btn-prev" @click="prevSlide1"><i class="fa fa-caret-left" aria-hidden="true"></i></button>-->
<!--                                <button class="btn-slide btn-next"@click="nextSlide1"><i class="fa fa-caret-right" aria-hidden="true"></i></button>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div style="text-align: center; margin-top: 10px;">-->
<!--                                <img src="@/assets/img/phuongthanh/implant.png" alt="" style="width: 50px;">-->
<!--                                <h4 class="mt-1 mb-3 text-center">Khoa điều trị và thẩm mỹ răng - Thái Dương Hàm</h4>-->
<!--                            </div>-->
<!--                            <div class="aos mb-3" data-aos="fade-up">-->
<!--                                <Carousel ref="carousel2" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">-->
<!--                                    <Slide v-for="(item, index) in filter2" :key="index.id">-->
<!--                                        <div class="list-doingu">-->
<!--                                            <div class="mb-3 item-doingu">-->
<!--                                                <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">-->
<!--                                                <div class="card-doingu">-->
<!--                                                    <div class="doctor-chuc-danh">-->
<!--                                                        <span>{{ item.Text }}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-name">-->
<!--                                                        <h3>{{ item.Name }}</h3>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-desc">-->
<!--                                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành, -->
<!--                                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.                                            -->
<!--                                                        <div class="doctor-button">-->
<!--                                                            <a href="">Tìm hiểu thêm</a>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </Slide>-->
<!--                                </Carousel>-->
<!--                                <div class="btn-w-slide">-->
<!--                                <button class="btn-slide btn-prev" @click="prevSlide2"><i class="fa fa-caret-left" aria-hidden="true"></i></button>-->
<!--                                <button class="btn-slide btn-next"@click="nextSlide2"><i class="fa fa-caret-right" aria-hidden="true"></i></button>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div style="text-align: center; margin-top: 10px;">-->
<!--                                <img src="@/assets/img/phuongthanh/implant.png" alt="" style="width: 50px;">-->
<!--                                <h4 class="mt-1 mb-3 text-center">Khoa Chỉnh nha - Răng trẻ em</h4>-->
<!--                            </div>-->
<!--                            <div class="aos mb-3" data-aos="fade-up">-->
<!--                                <Carousel ref="carousel3" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">-->
<!--                                    <Slide v-for="(item, index) in filter3" :key="index.id">-->
<!--                                        <div class="list-doingu">-->
<!--                                            <div class="mb-3 item-doingu">-->
<!--                                                <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">-->
<!--                                                <div class="card-doingu">-->
<!--                                                    <div class="doctor-chuc-danh">-->
<!--                                                        <span>{{ item.Text }}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-name">-->
<!--                                                        <h3>{{ item.Name }}</h3>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-desc">-->
<!--                                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành, -->
<!--                                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.                                            -->
<!--                                                        <div class="doctor-button">-->
<!--                                                            <a href="">Tìm hiểu thêm</a>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </Slide>-->
<!--                                </Carousel>-->
<!--                                <div class="btn-w-slide">-->
<!--                                <button class="btn-slide btn-prev" @click="prevSlide3"><i class="fa fa-caret-left" aria-hidden="true"></i></button>-->
<!--                                <button class="btn-slide btn-next"@click="nextSlide3"><i class="fa fa-caret-right" aria-hidden="true"></i></button>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div style="text-align: center; margin-top: 10px;">-->
<!--                                <img src="@/assets/img/phuongthanh/implant.png" alt="" style="width: 50px;">-->
<!--                                <h4 class="mt-1 mb-3 text-center">Khoa Phẩu thuật miệng - Cấy ghép Implant</h4>-->
<!--                            </div>-->
<!--                            <div class="aos mb-3" data-aos="fade-up">-->
<!--                                <Carousel ref="carousel4" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">-->
<!--                                    <Slide v-for="(item, index) in filter4" :key="index.id">-->
<!--                                        <div class="list-doingu">-->
<!--                                            <div class="mb-3 item-doingu">-->
<!--                                                <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">-->
<!--                                                <div class="card-doingu">-->
<!--                                                    <div class="doctor-chuc-danh">-->
<!--                                                        <span>{{ item.Text }}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-name">-->
<!--                                                        <h3>{{ item.Name }}</h3>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-desc">-->
<!--                                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành, -->
<!--                                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.                                            -->
<!--                                                        <div class="doctor-button">-->
<!--                                                            <a href="">Tìm hiểu thêm</a>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </Slide>-->
<!--                                </Carousel>-->
<!--                                <div class="btn-w-slide">-->
<!--                                <button class="btn-slide btn-prev" @click="prevSlide4"><i class="fa fa-caret-left" aria-hidden="true"></i></button>-->
<!--                                <button class="btn-slide btn-next"@click="nextSlide4"><i class="fa fa-caret-right" aria-hidden="true"></i></button>-->
<!--                                </div>-->
<!--                            </div>-->


<!--                            <div style="text-align: center; margin-top: 10px;">-->
<!--                                <img src="@/assets/img/phuongthanh/implant.png" alt="" style="width: 50px;">-->
<!--                                <h4 class="mt-1 mb-3 text-center">Bác sĩ điều trị</h4>-->
<!--                            </div>-->
<!--                            <div class="aos mb-3" data-aos="fade-up">-->
<!--                                <Carousel ref="carousel5" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">-->
<!--                                    <Slide v-for="(item, index) in filter5" :key="index.id">-->
<!--                                        <div class="list-doingu">-->
<!--                                            <div class="mb-3 item-doingu">-->
<!--                                                <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">-->
<!--                                                <div class="card-doingu">-->
<!--                                                    <div class="doctor-chuc-danh">-->
<!--                                                        <span>{{ item.Text }}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-name">-->
<!--                                                        <h3>{{ item.Name }}</h3>-->
<!--                                                    </div>-->
<!--                                                    <div class="doctor-desc">-->
<!--                                                        TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành, -->
<!--                                                        từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.                                            -->
<!--                                                        <div class="doctor-button">-->
<!--                                                            <a href="">Tìm hiểu thêm</a>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </Slide>-->
<!--                                </Carousel>-->
<!--                                <div class="btn-w-slide">-->
<!--                                <button class="btn-slide btn-prev" @click="prevSlide5"><i class="fa fa-caret-left" aria-hidden="true"></i></button>-->
<!--                                <button class="btn-slide btn-next"@click="nextSlide5"><i class="fa fa-caret-right" aria-hidden="true"></i></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

          <div style="margin-top: 20px;">
            <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">
              <div style="padding: 10px;">
                <div class="row align-items-center">
                  <div style="text-align: center;">
                    <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                    <h2 class="title-intro color font-bold">
                      Đội ngũ quản lý
                    </h2>
                  </div>
                  <div class="aos mb-3" data-aos="fade-up">
                    <Carousel ref="carousel1" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                      <Slide v-for="(item, index) in filter1" :key="index.id">
                        <div class="list-doingu">
                          <div class="mb-3 item-doingu">
                            <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                            <div class="card-doingu">
                              <div class="doctor-chuc-danh">
                                <span>{{ item.Text }}</span>
                              </div>
                              <div class="doctor-name">
                                <h3>{{ item.Name }}</h3>
                              </div>
                              <div class="doctor-desc">
                                TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                <div class="doctor-button">
                                  <a href="">Tìm hiểu thêm</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                    <div class="btn-w-slide">
                      <button class="btn-slide btn-prev" @click="prevSlide1"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                      <button class="btn-slide btn-next"@click="nextSlide1"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">
              <div style="padding: 10px;">
                <div class="row align-items-center">
                  <div style="text-align: center;">
                    <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                    <h2 class="title-intro color font-bold">
                      Khoa điều trị và thẩm mỹ răng - Thái Dương Hàm
                    </h2>
                  </div>
                  <div class="aos mb-3" data-aos="fade-up">
                    <Carousel ref="carousel2" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                      <Slide v-for="(item, index) in filter2" :key="index.id">
                        <div class="list-doingu">
                          <div class="mb-3 item-doingu">
                            <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                            <div class="card-doingu">
                              <div class="doctor-chuc-danh">
                                <span>{{ item.Text }}</span>
                              </div>
                              <div class="doctor-name">
                                <h3>{{ item.Name }}</h3>
                              </div>
                              <div class="doctor-desc">
                                TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                <div class="doctor-button">
                                  <a href="">Tìm hiểu thêm</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                    <div class="btn-w-slide">
                      <button class="btn-slide btn-prev" @click="prevSlide2"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                      <button class="btn-slide btn-next"@click="nextSlide2"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">
              <div style="padding: 10px;">
                <div class="row align-items-center">
                  <div style="text-align: center;">
                    <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                    <h2 class="title-intro color font-bold">
                      Khoa Chỉnh nha - Răng trẻ em
                    </h2>
                  </div>
                  <div class="aos mb-3" data-aos="fade-up">
                    <Carousel ref="carousel3" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                      <Slide v-for="(item, index) in filter3" :key="index.id">
                        <div class="list-doingu">
                          <div class="mb-3 item-doingu">
                            <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                            <div class="card-doingu">
                              <div class="doctor-chuc-danh">
                                <span>{{ item.Text }}</span>
                              </div>
                              <div class="doctor-name">
                                <h3>{{ item.Name }}</h3>
                              </div>
                              <div class="doctor-desc">
                                TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                <div class="doctor-button">
                                  <a href="">Tìm hiểu thêm</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                    <div class="btn-w-slide">
                      <button class="btn-slide btn-prev" @click="prevSlide3"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                      <button class="btn-slide btn-next"@click="nextSlide3"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">
              <div style="padding: 10px;">
                <div class="row align-items-center">
                  <div style="text-align: center;">
                    <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                    <h2 class="title-intro color font-bold">
                      Khoa Phẩu thuật miệng - Cấy ghép Implant
                    </h2>
                  </div>
                  <div class="aos mb-3" data-aos="fade-up">
                    <Carousel ref="carousel4" v-bind="settings" :breakpoints="breakpoints" :wrapAround="false" :transition="500">
                      <Slide v-for="(item, index) in filter4" :key="index.id">
                        <div class="list-doingu">
                          <div class="mb-3 item-doingu">
                            <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                            <div class="card-doingu">
                              <div class="doctor-chuc-danh">
                                <span>{{ item.Text }}</span>
                              </div>
                              <div class="doctor-name">
                                <h3>{{ item.Name }}</h3>
                              </div>
                              <div class="doctor-desc">
                                TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                <div class="doctor-button">
                                  <a href="">Tìm hiểu thêm</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                    <div class="btn-w-slide">
                      <button class="btn-slide btn-prev" @click="prevSlide4"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                      <button class="btn-slide btn-next"@click="nextSlide4"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <div class="container" style="background-color: #d4a04c33; border-radius: 20px;">
              <div style="padding: 10px;">
                <div class="row align-items-center">
                  <div style="text-align: center;">
                    <img src="@/assets/img/phuongthanh/logo/logo-doctor-null.png" alt="" style="width: 80px;">
                    <h2 class="title-intro color font-bold">
                      Bác sĩ điều trị
                    </h2>
                  </div>
                  <div class="aos mb-3" data-aos="fade-up">
                    <Carousel ref="carousel5" v-bind="settings" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                      <Slide v-for="(item, index) in filter5" :key="index.id">
                        <div class="list-doingu">
                          <div class="mb-3 item-doingu">
                            <img :src="require(`@/assets/img/phuongthanh/doctor/${item.Image}`)" alt="">
                            <div class="card-doingu">
                              <div class="doctor-chuc-danh">
                                <span>{{ item.Text }}</span>
                              </div>
                              <div class="doctor-name">
                                <h3>{{ item.Name }}</h3>
                              </div>
                              <div class="doctor-desc">
                                TS.BS Phạm Kim Thành hiện là ..... chuyên môn tại Nha khoa Phương Thành,
                                từng có nhiều năm tu nghiệp tại Pháp, giữ vị trí quan trọng trong các tổ chức nha khoa uy tín tại Việt Nam.
                                <div class="doctor-button">
                                  <a href="">Tìm hiểu thêm</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                    <div class="btn-w-slide">
                      <button class="btn-slide btn-prev" @click="prevSlide5"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
                      <button class="btn-slide btn-next"@click="nextSlide5"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <!-- /ĐỘI NGŨ BÁC SĨ -->

            <section class="section-usps">
                <div class="container">
                    <div class="box-heading">
                        <h2>6 TIÊU CHÍ QUAN TRỌNG CỦA BÁC SĨ NHA KHOA CHUYÊN SÂU</h2>
                    </div>
                    <div class="row box-middle">
                                        <div class="col-lg-4 col-sm-4 col-xs-12">
                                <div class="box-content">
                                    <div class="box-icon">
                                        <noscript><img width="69" height="73" src="@/assets/img/phuongthanh/doctor.png" alt="icon image"></noscript>
                                        <img class=" lazyloaded" width="69" height="73" src="@/assets/img/phuongthanh/doctor.png" alt="icon image">
                                    </div>
                                    <div class="title">
                                        <p>Được đào tạo chính quy theo chương trình Răng hàm mặt tại các trường Đại học Y khoa trong hoặc ngoài nước.</p>
                                    </div>
                                </div>
                            </div>
                                        <div class="col-lg-4 col-sm-4 col-xs-12">
                                <div class="box-content">
                                    <div class="box-icon">
                                        <noscript><img width="76" height="80" src="@/assets/img/phuongthanh/research.png" alt="icon image"></noscript>
                                        <img class=" lazyloaded" width="76" height="80" src="@/assets/img/phuongthanh/research.png" alt="icon image">
                                    </div>
                                    <div class="title">
                                        <p>Nghiên cứu chuyên sâu ở một chuyên khoa, không ngừng trau dồi kiến thức để có nền tảng chuyên môn vững vàng.</p>
                                    </div>
                                </div>
                            </div>
                                        <div class="col-lg-4 col-sm-4 col-xs-12">
                                <div class="box-content">
                                    <div class="box-icon">
                                        <noscript><img width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image"></noscript>
                                        <img class=" lazyloaded" width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image">
                                    </div>
                                    <div class="title">
                                        <p>Kinh nghiệm thực tế phong phú, điều trị thành công cho nhiều khách hàng, mang đến nụ cười khỏe đẹp lâu dài.</p>
                                    </div>
                                </div>
                            </div>
                        <div class="col-lg-4 col-sm-4 col-xs-12 mt-3">
                          <div class="box-content">
                            <div class="box-icon">
                              <noscript><img width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image"></noscript>
                              <img class=" lazyloaded" width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image">
                            </div>
                            <div class="title">
                              <p>Kinh nghiệm thực tế phong phú, điều trị thành công cho nhiều khách hàng, mang đến nụ cười khỏe đẹp lâu dài.</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 col-xs-12 mt-3">
                          <div class="box-content">
                            <div class="box-icon">
                              <noscript><img width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image"></noscript>
                              <img class=" lazyloaded" width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image">
                            </div>
                            <div class="title">
                              <p>Kinh nghiệm thực tế phong phú, điều trị thành công cho nhiều khách hàng, mang đến nụ cười khỏe đẹp lâu dài.</p>
                            </div>
                          </div>
                        </div>
                      <div class="col-lg-4 col-sm-4 col-xs-12 mt-3">
                        <div class="box-content">
                          <div class="box-icon">
                            <noscript><img width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image"></noscript>
                            <img class=" lazyloaded" width="76" height="78" src="@/assets/img/phuongthanh/dentist.png" alt="icon image">
                          </div>
                          <div class="title">
                            <p>Kinh nghiệm thực tế phong phú, điều trị thành công cho nhiều khách hàng, mang đến nụ cười khỏe đẹp lâu dài.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="box-bottom lazyloaded">
                        <div class="box-content-bottom">
                            <h3>Trung tâm Nha khoa Phương Thành cam kết mang đến cho khách hàng những trải nghiệm tốt nhất với đội ngũ bác sĩ chuyên nghiệp. Tại đây, bạn sẽ được đảm bảo:</h3>
                            <div>
                              <p><noscript><img decoding="async" class="alignnone size-full wp-image-985" src="@/assets/img/phuongthanh/dentist.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-985 ls-is-cached lazyloaded" src="@/assets/img/phuongthanh/tick.png" data-src="@/assets/img/phuongthanh/tick.png" alt="" width="24" height="25">
                                An toàn: Các quy trình đều tuân thủ nghiêm ngặt các tiêu chuẩn y tế.</p>
                              <p><noscript><img decoding="async" class="alignnone size-full wp-image-985" src="@/assets/img/phuongthanh/dentist.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-985 ls-is-cached lazyloaded" src="@/assets/img/phuongthanh/tick.png" data-src="@/assets/img/phuongthanh/tick.png" alt="" width="24" height="25">
                                Chất lượng: Dịch vụ nha khoa được đầu tư công nghệ hiện đại, đảm bảo hiệu quả điều trị cao.</p>
                              <p><noscript><img decoding="async" class="alignnone size-full wp-image-985" src="@/assets/img/phuongthanh/dentist.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-985 ls-is-cached lazyloaded" src="@/assets/img/phuongthanh/tick.png" data-src="@/assets/img/phuongthanh/tick.png" alt="" width="24" height="25">
                                Hiệu quả: Đội ngũ bác sĩ dày dạn kinh nghiệm sẽ giúp bạn có được kết quả tốt nhất.</p>
                              <p><noscript><img decoding="async" class="alignnone size-full wp-image-985" src="@/assets/img/phuongthanh/dentist.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-985 ls-is-cached lazyloaded" src="@/assets/img/phuongthanh/tick.png" data-src="@/assets/img/phuongthanh/tick.png" alt="" width="24" height="25">
                                Tận tâm: Chúng tôi luôn lắng nghe và chăm sóc từng nhu cầu của khách hàng.</p>
                              <p><noscript><img decoding="async" class="alignnone size-full wp-image-985" src="@/assets/img/phuongthanh/dentist.png" alt="" width="24" height="25" /></noscript><img decoding="async" class="alignnone size-full wp-image-985 ls-is-cached lazyloaded" src="@/assets/img/phuongthanh/tick.png" data-src="@/assets/img/phuongthanh/tick.png" alt="" width="24" height="25">
                                Trách nhiệm: Đội ngũ nhân viên cam kết hỗ trợ và tư vấn tận tình trong suốt quá trình điều trị.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            

        </div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      title: 'Đội ngũ bác sĩ',
      text: 'Trang chủ',
      text1: 'Đội ngũ bác sĩ',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
      indexFiveDoctor: indexFiveDoctor,
      itemsToShow: Math.min(indexFiveDoctor.length, 4), // Tính số lượng item hiển thị
        settings: {
            slidesToShow: this.itemsToShow,
            slidesToScroll: 1,
            dots: true,
            infinite: indexFiveDoctor.length > 4, // Chỉ cho phép lướt nếu có nhiều hơn 4 item
            // Các thiết lập khác
        },
        breakpoints: {
            578: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 700px and up
            768: {
                itemsToShow: 2,
                snapAlign: 'start',
            },
            // 1024 and up
            992: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'start',
            },
        },
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },
  computed: {
    // Lọc danh sách bác sĩ có badge 'Hàm tháo lắp'
    filter1() {
        return this.indexFiveDoctor.filter(item => item.Badge === '1');
    },
    filter2() {
        return this.indexFiveDoctor.filter(item => item.Badge === '2');
    },
    filter3() {
        return this.indexFiveDoctor.filter(item => item.Badge === '3');
    },
    filter4() {
        return this.indexFiveDoctor.filter(item => item.Badge === '4');
    },
    filter5() {
        return this.indexFiveDoctor.filter(item => item.Badge === '5');
    }
},

  mounted() {
    // Chặn phím F12
    // window.addEventListener('keydown', (event) => {
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });

    // Chặn chuột phải
    // window.addEventListener('contextmenu', (event) => {
    //   event.preventDefault();
    // });
  },
  created() {
  },
  methods: {
    nextSlide1() {
        this.$refs.carousel1.next();
    },
    prevSlide1() {
        this.$refs.carousel1.prev();
    },
    nextSlide2() {
        this.$refs.carousel2.next();
    },
    prevSlide2() {
        this.$refs.carousel2.prev();
    },
    nextSlide3() {
        this.$refs.carousel3.next();
    },
    prevSlide3() {
        this.$refs.carousel3.prev();
    },
    nextSlide4() {
        this.$refs.carousel4.next();
    },
    prevSlide4() {
        this.$refs.carousel4.prev();
    },
    nextSlide5() {
        this.$refs.carousel5.next();
    },
    prevSlide5() {
        this.$refs.carousel5.prev();
    },
  },
}
</script>

<style>
.list-doingu{
    width: 100%;
    /* max-height: 250px; */
}
.item-doingu {
    position: relative;
    padding: 0 10px;
    border-radius: 20px;
    cursor: pointer;
}
.item-doingu img{
    object-fit: cover;
    width: 100%;
    /* height: 250px; */
    border-radius: 20px;
    background-color: #fff;
}

.list-doingu .item-doingu .card-doingu{
    position: absolute;
    bottom: 0;
    background: rgba(88, 97, 116, .9);
    left: 0;
    right: 0;
    padding: 10px;
    margin: 30px;
    border-radius: 11px;
    text-align: center;
    color: #fff;
    transition: visibility 0s, height .3s ease
}

.list-doingu .item-doingu .card-doingu .doctor-chuc-danh span{
    font-size: 12px !important;
}
.list-doingu .item-doingu .card-doingu .doctor-name h3{
    font-size: 16px !important;
    color: #fff;
}

.list-doingu .item-doingu .card-doingu .doctor-desc{
    color: #edeef2;
    font-size: 11px;
    visibility: hidden;
    height: 0;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button{
    margin-top: 10px;
}
.list-doingu .item-doingu .card-doingu .doctor-desc .doctor-button a {
    background: #fff;
    border-radius: 24px;
    font-family: "Nunito Bold";
    font-size: 12px;
    height: 30px;
    padding: 5px 15px;
    line-height: 30px;
    max-width: 150px;
    margin: 0 auto
}

.list-doingu .item-doingu:hover .card-doingu {
    bottom: 0;
    animation: slideUp .5s ease forwards;
    transition: visibility 0s,height .3s ease
}
.list-doingu .item-doingu:hover .card-doingu .doctor-desc{
    visibility: visible;
    height: auto
}

.color{
    color: #D6A24D;
}

.font-bold{
    font-family: 'Nunito Bold';
}

.font-light{
    font-family: 'Nunito Light';
}

.font-regular{
    font-family: 'Nunito Regular';
}


/* HAHA */
.section-usps {
    padding: 100px 0;
    background: #fefdfe;
    margin-top: 50px;
}

@media screen and (max-width: 1024px) {
    .section-usps {
        padding:50px 0;
    }
}

@media screen and (max-width: 767px) {
    .section-usps {
        padding:30px 0
    }
}

.section-usps .container .box-heading h2 {
    color: var(--main-color);
    margin-bottom: 30px;
    text-align: center;
    font-family: "SVN Poppins Bold"
}

.section-usps .container .row .col-lg-4 .box-content {
    background: #fff;
    box-shadow: 0px 4px 30px rgba(146,90,143,.25);
    border-radius: 25px;
    text-align: center;
    padding: 50px;
    max-height: 330px;
    min-height: 330px
}

@media screen and (width: 1024px) {
    .section-usps .container .row .col-lg-4 .box-content {
        padding:30px
    }
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content {
        padding:30px 15px;
        max-height: 300px;
        min-height: 300px
    }
}

@media screen and (max-width: 767px) {
    .section-usps .container .row .col-lg-4 .box-content {
        margin:15px;
        max-height: 250px;
        min-height: 250px
    }
}

.section-usps .container .row .col-lg-4 .box-content .box-icon {
    margin-bottom: 15px
}

.section-usps .container .row .col-lg-4 .box-content .title {
    color: #2a2f38;
    font-size: 20px;
    font-family: "Nunito Regular"
}

.section-usps .container .row .col-lg-4 .box-content .title p {
    color: #2a2f38;
    font-size: 20px;
    font-family: "Nunito Regular";
    margin-bottom: 0
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content .title p {
        font-size:16px
    }
}

@media screen and (max-width: 991px) {
    .section-usps .container .row .col-lg-4 .box-content .title {
        font-size:16px
    }
}

.section-usps .container .box-middle {
    margin-bottom: 50px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-middle {
        margin-bottom:30px
    }
}

.section-usps .container .box-bottom {
    background-color: var(--main-color);
    border-radius:40px
}

@media screen and (min-width: 768px) {
    .section-usps .container .box-bottom {
        border-radius:40px
    }
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom {
        margin-left:-15px;
        margin-right: -15px
    }
}

.section-usps .container .box-bottom .box-content-bottom {
    margin: 0 auto;
    padding: 60px 50px;
    color: #fff;
    font-family: "Nunito Regular";
    font-size: 22px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom {
        padding:60px 15px;
        font-size: 16px
    }
}

.section-usps .container .box-bottom .box-content-bottom h3 {
    color: #fff;
    font-family: "Nunito Bold";
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: none !important;
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom h3 {
        font-size:20px;
        margin: 0 auto 30px auto
    }
}

.section-usps .container .box-bottom .box-content-bottom p {
    margin-bottom: 10px;
    margin-left: 50px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom p {
        margin-left:0;
        display: flex
    }
}

.section-usps .container .box-bottom .box-content-bottom p img {
    margin-right: 10px;
    margin-top: -5px
}

@media screen and (max-width: 767px) {
    .section-usps .container .box-bottom .box-content-bottom p img {
        width:24px;
        height: 25px;
        margin-top: 0
    }
}

@media screen and (max-width: 384px) {
    .section-usps .container .box-bottom .box-content-bottom p img {
        margin-top:3px
    }
}

</style>
