<template>
<div class="card">
    <div class="card-body pt-0">

        <!-- Tab Menu -->
        <h3 class="pt-4">Product Details</h3>
        <hr>
        <!-- /Tab Menu -->

        <!-- Tab Content -->
        <div class="tab-content pt-3">

            <!-- Overview Content -->
            <div role="tabpanel" id="doc_overview" class="tab-pane fade show active">
                <div class="row">
                    <div class="col-md-9">

                        <!-- About Details -->
                        <div class="widget about-widget">
                            <h4 class="widget-title">Description</h4>
                            <p>Safi syrup is best for purifying the blood. As it contains herbal
                                extracts it can cure indigestion, constipation, nose bleeds and
                                acne boils. It helps in the removal of the toxins from the
                                blood. It improves the complexion and gives you a healthy life
                            </p>
                        </div>
                        <!-- /About Details -->


                        <!-- Awards Details -->
                        <div class="widget awards-widget">
                            <h4 class="widget-title">Highlights</h4>
                            <div class="experience-box">
                                <ul class="experience-list">
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">
                                                <p>Safi syrup is known for its best purifying
                                                    syrup for blood.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">

                                                <p>It helps in eliminating the toxins from the
                                                    bloodstream.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">

                                                <p>It improves digestion.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="experience-user">
                                            <div class="before-circle"></div>
                                        </div>
                                        <div class="experience-content">
                                            <div class="timeline-content">

                                                <p>It also helps in indigestion and
                                                    constipation.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /Awards Details -->

                        <!-- About Details -->
                        <div class="widget about-widget">
                            <h4 class="widget-title">Directions for use</h4>
                            <p>Adults: Take 2 tablespoons once a day in a glass full of water.
                            </p>
                        </div>
                        <!-- /About Details -->

                        <!-- About Details -->
                        <div class="widget about-widget">
                            <h4 class="widget-title">Storage</h4>
                            <p>Store this syrup at room temperature protected from sunlight,
                                heat, and moisture. Keep away from reaching out of children and
                                pets. Ensure that the unused medicine is disposed of properly.
                            </p>
                        </div>
                        <!-- /About Details -->
                        <!-- About Details -->
                        <div class="widget about-widget">
                            <h4 class="widget-title">Administration Instructions</h4>
                            <p>Shake the bottle before its use. This syrup can be taken with or
                                without food. The quantity consumed should not be lesser or
                                greater than the prescribed one.</p>
                        </div>
                        <!-- /About Details -->

                        <!-- About Details -->
                        <div class="widget about-widget">
                            <h4 class="widget-title">Warning</h4>
                            <p>This is not recommended for the pregnant women and lactating
                                mothers.</p>
                        </div>
                        <!-- /About Details -->
                        <!-- About Details -->
                        <div class="widget about-widget mb-3">
                            <h4 class="widget-title">Precaution</h4>
                            <p class="mb-0">Syrup has to be disposed of properly after 3 years
                                from manufactured date and it is not recommended to use after
                                the date.</p>
                        </div>
                        <!-- /About Details -->
                    </div>
                </div>
            </div>
            <!-- /Overview Content -->
        </div>
    </div>
</div>
</template>