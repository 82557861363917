<template>
  <section class="section review-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 review-big-img">
          <img
            src="@/assets/img/reviews/review-img.png"
            class="img-fluid"
            alt="Review Img"
          />
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="review-info">
            <div class="review-grid">
              <div class="review-img">
                <img src="@/assets/img/reviews/review-01.jpg" alt="Review Img" />
              </div>
              <div class="review-content">
                <div class="rating">
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                </div>
                <p>
                  The Doccure has been a game-changer for me. Ordering my medications is a
                  breeze, and the detailed information provided helps me make informed
                  decisions about my health.
                </p>
                <h5>Claudia Cram</h5>
              </div>
            </div>
            <div class="review-grid">
              <div class="review-img">
                <img src="@/assets/img/reviews/review-02.jpg" alt="Review Img" />
              </div>
              <div class="review-content">
                <div class="rating">
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                </div>
                <p>
                  The convenience of having my medications delivered to my doorstep is
                  unparalleled. The Doccure security measures instill confidence in every
                  transaction.
                </p>
                <h5>Andrew Morgan</h5>
              </div>
            </div>
            <div class="review-grid">
              <div class="review-img">
                <img src="@/assets/img/reviews/review-03.jpg" alt="Review Img" />
              </div>
              <div class="review-content">
                <div class="rating">
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                </div>
                <p>
                  As a busy professional, the Doccure efficient service has saved me
                  countless hours. The ability to chat with a pharmacist online for quick
                  advice is fantastic, making it so much easier to manage my health.
                </p>
                <h5>Margaret Sara</h5>
              </div>
            </div>
            <div class="review-grid">
              <div class="review-img">
                <img src="@/assets/img/reviews/review-04.jpg" alt="Review Img" />
              </div>
              <div class="review-content">
                <div class="rating">
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                </div>
                <p>
                  I can't thank the Doccure enough for its user-friendly interface and
                  quick prescription refills. The automatic reminders for medication
                  schedules have been a lifesaver, ensuring I never miss a dose.
                </p>
                <h5>Kevin Stoll</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
