const getJson = (item) => {
    return {
      datePrize : item.datePrize,
      description : item.description,
    };
  };

  const sendJson = (item) => {
    return {
      datePrize : item.datePrize,
      description : item.description,
    };
  };

  const baseJson = () => {
    return {
      id : null,
      datePrize : null,
      description : null,
    };
  };


  export const prizeModel = {
    sendJson , getJson, baseJson,
  };
