<template>
  <section class="section products-sec recent-product-sec">
    <div class="container" v-for="(item, index) in this.list" :key="index">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Sản phẩm {{ item.name }}</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link :to="{
                  path: `/san-pham-all/${item.id}`,
                }"
                >Xem tất cả <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="(items, index) in item.listMedicine" :key="index.id">
          <div class="products-card">
            <div class="product-card-img">
              <router-link :to="{
                  path: `/san-pham/chi-tiet/${items.id}`,
                }"
                >
                <div class="img-thuoc" v-if="items.avatar != null">
                  <img :src="urlFile + items.avatar.fileId" alt=""/>
                </div>
              </router-link>
            </div>
            <div class="product-content">
              <h6>{{ items.type.name }}</h6>
              <h4>
                <router-link :to="{
                  path: `/san-pham/chi-tiet/${items.id}`,
                }">
                    {{ items.name }}
                </router-link>
              </h4>
              <!-- <span class="badge">60 viên</span> -->
              <div class="product-cart">
                <div class="cart-price">
                  <h5>{{ items.value }}</h5>
                </div>
                <router-link :to="{
                    path: `/san-pham/chi-tiet/${items.id}`,
                  }"
                    class="cart-icon">
                      <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-28.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Cân nậng</h6>
              <h4><router-link to="product-all">Thanh Protein</router-link></h4>
              <span class="badge">65 g</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$120<span>$150</span></h5>
                </div>
                <div class="cart-count">
                  <div class="custom-increment cart">
                    <div class="input-group1">
                      <span class="input-group-btn">
                        <button
                          type="button"
                          class="quantity-left-minus3 btn btn-danger btn-number"
                          data-type="minus"
                          data-field=""
                        >
                          <span><i class="fas fa-minus"></i></span>
                        </button>
                      </span>
                      <input
                        type="text"
                        id="quantity3"
                        name="quantity3"
                        class="input-number"
                        value="10"
                      />
                      <span class="input-group-btn">
                        <button
                          type="button"
                          class="quantity-right-plus3 btn btn-success btn-number"
                          data-type="plus"
                          data-field=""
                        >
                          <span><i class="fas fa-plus"></i></span>
                        </button>
                      </span>
                    </div>
                  </div>
                  <router-link to="product-all" class="cart-icon check-icon">
                    <i class="feather-check"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-29.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Sức khỏe</h6>
              <h4><router-link to="product-all">Acetoheal</router-link></h4>
              <span class="badge">40 viên</span>
              <span class="sticker">5% OFF</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$250<span>$350</span></h5>
                </div>
                <router-link to="product-all" class="cart-icon">
                  <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-30.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Cân nậng</h6>
              <h4><router-link to="product-all">Apexitecan</router-link></h4>
              <span class="badge">20 viên</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$279<span>$300</span></h5>
                </div>
                <router-link to="product-all" class="cart-icon">
                  <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
<!--    <div class="container">-->
<!--      <div class="pharmacy-section-header">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-6">-->
<!--            <div class="pharmacy-title">-->
<!--              <h4>Sản phẩm gần đây</h4>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--            <div class="pharmacy-title-link">-->
<!--              <router-link to="product-all"-->
<!--              >Xem tất cả <i class="fa-solid fa-arrow-right"></i-->
<!--              ></router-link>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-lg-3 col-md-4 col-sm-6">-->
<!--          <div class="products-card">-->
<!--            <div class="product-card-img">-->
<!--              <router-link to="product-all"-->
<!--              ><img src="@/assets/img/products/product-27.png" alt=""-->
<!--              /></router-link>-->
<!--            </div>-->
<!--            <div class="product-content">-->
<!--              <h6>Sức khỏe</h6>-->
<!--              <h4><router-link to="product-all">Viên thuốc cảm lạnh</router-link></h4>-->
<!--              <span class="badge">60 viên</span>-->
<!--              <div class="product-cart">-->
<!--                <div class="cart-price">-->
<!--                  <h5>$150<span>$199</span></h5>-->
<!--                </div>-->
<!--                <router-link to="product-all" class="cart-icon">-->
<!--                  <i class="feather-shopping-bag"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-4 col-sm-6">-->
<!--          <div class="products-card">-->
<!--            <div class="product-card-img">-->
<!--              <router-link to="product-all"-->
<!--              ><img src="@/assets/img/products/product-28.png" alt=""-->
<!--              /></router-link>-->
<!--            </div>-->
<!--            <div class="product-content">-->
<!--              <h6>Cân nậng</h6>-->
<!--              <h4><router-link to="product-all">Thanh Protein</router-link></h4>-->
<!--              <span class="badge">65 g</span>-->
<!--              <div class="product-cart">-->
<!--                <div class="cart-price">-->
<!--                  <h5>$120<span>$150</span></h5>-->
<!--                </div>-->
<!--                <div class="cart-count">-->
<!--                  <div class="custom-increment cart">-->
<!--                    <div class="input-group1">-->
<!--                      <span class="input-group-btn">-->
<!--                        <button-->
<!--                            type="button"-->
<!--                            class="quantity-left-minus3 btn btn-danger btn-number"-->
<!--                            data-type="minus"-->
<!--                            data-field=""-->
<!--                        >-->
<!--                          <span><i class="fas fa-minus"></i></span>-->
<!--                        </button>-->
<!--                      </span>-->
<!--                      <input-->
<!--                          type="text"-->
<!--                          id="quantity3"-->
<!--                          name="quantity3"-->
<!--                          class="input-number"-->
<!--                          value="10"-->
<!--                      />-->
<!--                      <span class="input-group-btn">-->
<!--                        <button-->
<!--                            type="button"-->
<!--                            class="quantity-right-plus3 btn btn-success btn-number"-->
<!--                            data-type="plus"-->
<!--                            data-field=""-->
<!--                        >-->
<!--                          <span><i class="fas fa-plus"></i></span>-->
<!--                        </button>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <router-link to="product-all" class="cart-icon check-icon">-->
<!--                    <i class="feather-check"></i>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-4 col-sm-6">-->
<!--          <div class="products-card">-->
<!--            <div class="product-card-img">-->
<!--              <router-link to="product-all"-->
<!--              ><img src="@/assets/img/products/product-29.png" alt=""-->
<!--              /></router-link>-->
<!--            </div>-->
<!--            <div class="product-content">-->
<!--              <h6>Sức khỏe</h6>-->
<!--              <h4><router-link to="product-all">Acetoheal</router-link></h4>-->
<!--              <span class="badge">40 viên</span>-->
<!--              <span class="sticker">5% OFF</span>-->
<!--              <div class="product-cart">-->
<!--                <div class="cart-price">-->
<!--                  <h5>$250<span>$350</span></h5>-->
<!--                </div>-->
<!--                <router-link to="product-all" class="cart-icon">-->
<!--                  <i class="feather-shopping-bag"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-4 col-sm-6">-->
<!--          <div class="products-card">-->
<!--            <div class="product-card-img">-->
<!--              <router-link to="product-all"-->
<!--              ><img src="@/assets/img/products/product-30.png" alt=""-->
<!--              /></router-link>-->
<!--            </div>-->
<!--            <div class="product-content">-->
<!--              <h6>Cân nậng</h6>-->
<!--              <h4><router-link to="product-all">Apexitecan</router-link></h4>-->
<!--              <span class="badge">20 viên</span>-->
<!--              <div class="product-cart">-->
<!--                <div class="cart-price">-->
<!--                  <h5>$279<span>$300</span></h5>-->
<!--                </div>-->
<!--                <router-link to="product-all" class="cart-icon">-->
<!--                  <i class="feather-shopping-bag"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script >

import { thuocModel } from "@/models/thuocModel";
import 'vue-multiselect/dist/vue-multiselect.css';
  export default {
    components: {

    },
    data() {
      return {
        title: "DANH SÁCH",
        model: thuocModel.baseJson(),
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view/`,
      };
    },
    name: "pharmacy/user",

    created() {
      this.getData();
    },

    watch: {
        perPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        },
        currentPage: {
            deep: true,
            handler(val){
                this.getData();
            }
        }
    },

    methods: {
        async getData() {
            let params = {
                start: this.currentPage,
                limit: this.perPage,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch("thuocStore/getPagingParamsCategory", params ).then(res => {
                this.list = res.data
                console.log("DATA: ", this.list);
                this.totalRows = res.data.totalRows
                // this.numberOfElement = res.data.data.length
            });
        },
    }
};
</script>

<style>

.img-thuoc{
  width: 100%;
  height: 220px;
  border-radius: 8px;
}

.img-thuoc img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.products-card .product-content .product-cart .cart-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
