<template>
  <div class="col-md-7 col-lg-9 col-xl-9">
    <div class="row">
      <div
          class="col-md-6 col-lg-4 col-xl-4"
          v-for="item in patientfavourite"
          :key="item.id"
      >
        <div class="profile-widget profile-doctor">
          <div class="doc-img">
            <router-link to="doctor-profile">
              <img
                  class="img-fluid"
                  alt="User Image"
                  :src="require(`@/assets/img/phuongthanh/doctor/${item.DoctorImg}`)"
              />
            </router-link>
            <a href="javascript:void(0)" class="fav-btn">
              <i class="far fa-bookmark"></i>
            </a>
          </div>
          <div class="pro-content">
            <h3 class="title">
              <router-link to="doctor-profile">{{ item.DoctorName }}</router-link>
              <i class="fas fa-check-circle verified ms-2"></i>
            </h3>
            <p class="speciality">{{ item.speciality }}</p>
            <div class="rating">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-0"></i>
              <i :class="item.class"></i>
              <span class="d-inline-block average-rating ms-1">{{
                  item.Views
                }}</span>
            </div>
            <ul class="available-info">
              <li><i class="fa-regular fa-hospital"></i> {{ item.Location }}</li>
              <li><i class="fa-regular fa-address-card"></i> {{ item.Available }}</li>
              <li>
                <i class="far fa-money-bill-alt"></i> {{ item.Price }}
                <i
                    class="fas fa-info-circle"
                    data-bs-toggle="tooltip"
                    title="Lorem Ipsum"
                ></i>
              </li>
            </ul>
            <div class="row row-sm">
              <div class="col-6">
                <router-link to="/ho-so-bac-si" class="btn view-btn"
                >Chi tiết</router-link
                >
              </div>
              <div class="col-6">
                <router-link to="booking" class="btn book-btn"
                >Đặt lịch</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center">
      <a href="javascript:;" class="btn book-btn1 mb-4">Xem thêm</a>
    </div>
  </div>
</template>
<script>
import patientfavourite from "@/assets/json/patientfavourites.json";
export default {
  data() {
    return {
      patientfavourite: patientfavourite,
      Select: ["Rating", "Popular", "Latest", "Free"],
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(){
      await  this.$store.dispatch("serviceStore/getAll").then((res) =>{
        this.list = res.data || [];
      })
    },

  }
};
</script>
