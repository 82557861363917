<template>
  <div class="main-Wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <pharmacybreadcrumb2 :title="title" />
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <Form
                    :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit" ref="form"
                >
                  <div class="row">
                    <div class="tt-end mext-2 col-md-12" style="display: flex; justify-content: flex-end;">
                      <b-button
                          type="submit"
                          style="background-color: #e9ab2e; border: none;"
                      >
                        Đăng bài viết
                      </b-button>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Trang tin</label>
                        <span style="color: red">&nbsp;*</span>
                        <Field name="menu" v-slot="{ field }">
                          <treeselect
                              value-format="object"
                              :class="{ 'is-invalid': errors.menu }"
                          >
                          </treeselect>
                          <div class="invalid-feedback">{{ errors.menu }}</div>
                        </Field>
                        <!-- <Field
                              as="treeselect"
                              name="menu"
                              :options="treeView"
                              :default-expand-level="1"
                              :normalizer="normalizer"
                              v-on:select="addCoQuanToModel"
                              v-model="model.menu"
                              value-format="object"
                              placeholder="Nhấp vào để chọn"
                              :class="{ 'is-invalid': errors.menu }"
                        />
                          <div class="invalid-feedback">{{ errors.menu }}</div> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Di động</label>
                        <!-- <span style="color: red">&nbsp;*</span> -->
                        <VueMultiselect
                            label="name"
                            placeholder="Nhấp vào để chọn"
                            selectLabel="Nhấn vào để chọn"
                            deselectLabel="Nhấn vào để xóa"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-2">
                        <label class="text-left mb-0">Dịch vụ</label>
                        <!-- <span style="color: red">&nbsp;*</span> -->
                        <VueMultiselect
                            label="name"
                            placeholder="Nhấp vào để chọn"
                            selectLabel="Nhấn vào để chọn"
                            deselectLabel="Nhấn vào để xóa"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="text-left">Tiêu đề bài viết</label>
                        <span style="color: red">&nbsp;*</span>
                        <input type="hidden" v-model="model.id"/>
                        <Field
                            v-model="model.name"
                            placeholder="Vui lòng nhập tên tiêu đề"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.name }"
                        />
                        <div class="invalid-feedback">{{ errors.name }}</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-check form-switch">
                        <label class="text-left mb-2">Xuất bản</label>
                        <input
                            v-model="model.isPublish"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" >
                        <div class="mb-2 ">
                          <label class="text-left mb-0">Mô tả</label>
                          <span style="color: red" >&nbsp;*</span>
                          <Field
                              as="textarea"
                              v-model="model.describe"
                              placeholder="Vui lòng nhập mô tả"
                              name="describe"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': errors.describe }"
                          />
                          <div class="invalid-feedback">{{ errors.describe }}</div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="col-md-12">
                          <div class="mb-2 ">
                            <label class="text-left mb-0">Ngày xuất bản</label>
                            <span style="color: red" >&nbsp;*</span>
                            <Field name="publicationDate" v-slot="{ field }">
                              <VueDatePicker
                                  v-bind="field"
                                  v-model="model.publicationDate"
                                  :enable-time-picker="false"
                                  :format="format"
                                  :locale="locale"
                                  auto-apply
                                  ONCLICK=""
                                  :class="{ 'is-invalid': errors.publicationDate }"
                              />
                            </Field>
                            <!-- <Field
                                name="publicationDate"
                                type="date"
                                v-model="model.publicationDate"
                                :enable-time-picker="false"
                                :format="format"
                                :locale="locale"
                                validate="true"
                                auto-apply
                                ONCLICK=""
                                class="form-control"
                                :class="{ 'is-invalid': errors.publicationDate }"
                              /> -->
                            <div class="invalid-feedback">{{ errors.publicationDate }}</div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="text-left">Nội dung bài viết</label>
                        <span style="color: red" >&nbsp;*</span>
                        <Field name="content" v-slot="{ field }">
                          <CKEditorCustom
                              v-bind="field"
                              v-model="model.content"
                              :class="{ 'is-invalid': errors.content }"
                          >
                          </CKEditorCustom>
                        </Field>
                        <div class="invalid-feedback">{{ errors.content }}</div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pharmacymodel />
  <pharmacydelete />
</template>
<script >
import 'vue-multiselect/dist/vue-multiselect.css';
import '@vuepic/vue-datepicker/dist/main.css'

import CKEditorCustom from "@/utils/view/CKEditorCustom.vue";
import { Form, Field } from "vee-validate";


export default {
  components: {
    CKEditorCustom,
    Form,
    Field,
  },
  data() {
    return {
      title: "CHI TIẾT BÀI VIẾT",


    };
  },
  name: "pharmacy/user",

  created() {

  },

  watch: {

  },

  setup() {

  },

  methods: {



  }
};
</script>

<style>
.vue-treeselect__control{
  padding-top: 6px;
  padding-bottom: 5px;
}
</style>
