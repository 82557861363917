<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-lg-8">
            <div class="card">
              <div class="card-body">
                <!-- Checkout Form -->
                <form @submit.prevent="submitForm">
                  <!-- Personal Information -->
                  <div class="info-widget">
                    <h4 class="card-title">Thông tin người đặt lịch</h4>
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Họ và tên</label>
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Email</label>
                          <input class="form-control" type="email" />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Số điện thoại</label>
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="submit-section mt-4">
                        <b-button type="submit" class="btn btn-primary submit-btn"
                        >Xác nhận</b-button
                        >
                      </div>
                    </div>
                  </div>

                </form>
                <!-- /Checkout Form -->
              </div>
            </div>
          </div>
          <div class="col-md-5 col-lg-4 theiaStickySidebar">
            <div class="stickysidebar">
              <!-- Booking Summary -->
              <div class="card booking-card">
                <div class="card-header">
                  <h4 class="card-title">THÔNG TIN ĐẶT LỊCH</h4>
                </div>
                <div class="card-body">
                  <!-- Booking Doctor Info -->
                  <div class="booking-doc-info">
                    <router-link to="doctor-profile" class="booking-doc-img">
                      <img
                        src="../../../assets/img/phuongthanh/doctor/BS_Thanh.jpg"
                        alt="User Image"
                      />
                    </router-link>
                    <div class="booking-info">
                      <h4>
                        <router-link to="doctor-profile">BSCKI. Phạm Kim Thành</router-link>
                      </h4>
                      <div class="rating">
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled ms-1"></i>
                        <i class="fas fa-star filled ms-1"></i>
                        <i class="fas fa-star filled ms-1"></i>
                        <i class="fas fa-star filled ms-1"></i>
                        <span class="d-inline-block average-rating ms-1">35</span>
                      </div>
                      <div class="clinic-details">
                        <p class="doc-location">
                          <i class="fas fa-map-marker-alt"></i> Răng sứ thẩm mỹ
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Booking Doctor Info -->
                  <div class="booking-summary">
                    <div class="booking-item-wrap">
                      <ul class="booking-date">
                        <li>Ngày :<span> 30/06/2024 </span></li>
                        <li>Thời gian :<span> 10:00 AM</span></li>
                      </ul>
                      <ul class="booking-fee">
                        <li>Consulting Fee <span>$100</span></li>
                        <li>Booking Fee <span>$10</span></li>
                        <li>Video Call <span>$50</span></li>
                      </ul>
                      <div class="booking-total">
                        <ul class="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span class="total-cost">$160</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Booking Summary -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <indexfivefooter></indexfivefooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Thông tin đặt lịch",
      text: "Trang chủ",
      text1: "Thông tin đặt lịch",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/booking-success");
    },
  },
};
</script>
