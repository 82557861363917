<template>
  <!-- Service Section -->
  <section class="service-section section-list-service">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-one section-header-slider mb-2">
            <h2 class="section-title"><span>Dịch vụ</span> của chúng tôi</h2>
          </div>
        </div>
      </div>
      <div class="listing-service">
        <div class="row">
          <div class="col-xl-4 col-md-6 col-12" v-for="(item, index) in list" :key="index">
            <router-link
                :to="{
                  path: `/dich-vu/${item.id}`,
                }"
            >
              <div class="service-item">
                <div class="service-thumb">
                  <a href="">
                    <img width="300" height="208" :src="item.fileImage.urlFile" class="attachment-medium img-dichvu size-medium wp-post-image" alt="Ảnh" decoding="async" fetchpriority="high" srcset="" sizes="100vw">
                  </a>
                  <div class="service-icon">
                    <img src="@/assets/img/phuongthanh/implant.png" alt="">
                  </div>
                </div>
                <div class="service-content">
                  <div class="service-title">
                    <h3>
                      <a href="" data-wpel-link="internal">
                        {{ item.name}}
                      </a>
                    </h3>
                  </div>
                  <div class="service-excerpt">
                    <!-- <p>Giải pháp tiên tiến cho người mất răng</p> -->
                    <p>{{ item.describe }}</p>
                  </div>
                  <div class="service-button">
                    <a href="" data-wpel-link="internal">Tìm hiểu thêm</a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indexFiveService from "@/assets/json/indexfiveservice.json";
export default {
  data() {
    return {
      indexFiveService: indexFiveService,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>
.img-service{
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

@media (max-width: 576px) {
  .img-service{
    height: 300px;
  }
}
</style>
