<template>
    <section class="app-section app-sec-twelve pharmacy-app-sec">
      <div class="container">
        <div class="app-twelve border-0">
          <div class="app-bg">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                <div class="mobile-img" style="text-align: end;">
                  <img src="@/assets/img/phuongthanh/mobile-img.png" class="img-fluid" alt="img">
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="app-content">
                  <div class="app-header aos aos-init aos-animate" data-aos="fade-up">
                    <h5>Vì sức khỏe của bạn.</h5>
                    <h2>Tải ứng dụng Nha khoa Phương thành ngay hôm nay!</h2>
                  </div>
                  <div class="app-scan aos aos-init aos-animate" data-aos="fade-up">
                    <p>Quét mã QR để tải ứng dụng!</p>
                    <img src="@/assets/img/phuongthanh/scan-img.png" alt="scan-image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /FAQ Section -->
  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import Flickity from 'vue-flickity';
  import {Carousel, Pagination, Slide} from "vue3-carousel";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
      Flickity,
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },
        flickityOptions: {
          initialIndex: 0,
          initialSlides: 4,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          autoPlay: 4000
  
          // any options from Flickity can be used
        },
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
    computed: {
    },
    methods: {
      next() {
        this.$refs.flickity.next();
      },
  
      previous() {
        this.$refs.flickity.previous();
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>
  
  <style>
  .list-card-nk{
    padding: 10px;
  }
  .flickity-viewport{
    height: 100% !important;
  }
  
  .img-library{
    height: 300px;
  }
  
  .img-library img{
    height: 300px;
    border-radius: 10px;
  }
  .card-img{
      height: 300px;
      padding: 10px 10px 0 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .card-img div img{
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .card-img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  
  .box-text{
      padding: 0px 10px
  }
  
  .box-info{
    height: 130px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .card-name-sen{
    position: absolute;
    background-color: #d4a04c;
    padding: 5px;
    color: #fff;
    margin: 0;
    top: 269px;
    left: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: bold;
    font-size: clamp(0.8125rem, 0.7679rem + 0.1429vw, 0.875rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .decs-cate-sen{
      font-size: 14px;
      text-align: justify;
      color: #000;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
  
  .btn-detail{
      border: 2px solid #d4a04c !important;
      background-color:#fff !important;
      color: #d4a04c !important;
      border: none;
      border-radius: 5px;
      font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem) !important;
  }
  
  .btn-detail:hover{
      background-color: #d4a04c !important;
      color: #fff !important;
  }
  
  .card-price{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-price span{
    background-color: #CCFBF1;
    color: #14B8A6;
    font-weight: bold;
    padding: 5px;
    font-size: clamp(0.625rem, 0.4911rem + 0.4286vw, 0.8125rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  </style>
  
  