<template>
    <section class="book-appointment-section aos" data-aos="fade-up">
      <div class="container">
        <div  v-for="(value) in this.list" :key="value.id">
          <div class="row mb-3">
            <div class="col-md-12" style="display: flex; justify-content: space-between; align-items: center;">
              <div style="font-size: 28px; text-transform: uppercase; font-weight: bold; color: #be8b43;">{{value.name}}</div>
              <div>
                <router-link
                    :to="{
                        path: `/ban-tin/${value.newsList[0].menu.id}`,
                    }"
                >
                  <b-button
                      class="btn-chitiet"
                      v-if ="value.newsList"

                  >
                    Xem tất cả
                  </b-button>
                </router-link>


              </div>
            </div>
          </div>

          <div class="row" v-if="value.newsList.length > 3">
            <div class="col-md-6 pb-3">
              <div class="card-news" >
                <div>
                  <router-link
                      :to="{
                        path: `/ban-tin/${value.newsList[0].id}`,
                    }"
                  >
                    <div class="img-news mb-3">
                      <img
                          v-if="value.newsList[0].fileImage"
                          class="img-fluid"
                          :src="value.newsList[0].fileImage.urlFile"
                          alt="Post Image"
                      />
                    </div>
                    <div class="content-news">
                      <div class="title-news mb-2">
                        <p style="font-size: 18px; font-family: 'Nunito Bold'; color: #000;">
                          {{ value.newsList[0].name }}
                        </p>
                      </div>
                      <div class="decs-news mb-3">
                        {{ value.newsList[0].describe }}
                      </div>
                      <b-button
                          class="btn-chitiet"
                          :to="{
                        path: `/ban-tin/${value.newsList[0].id}`,
                    }"
                      >
                        Xem thêm
                      </b-button>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-12 card-news" v-for="(item, index) in value.newsList.slice(1, 4)" :key="index.id" style="padding: 0 10px 10px;">
                <div class="row" >
                  <div class="col-md-6">
                    <router-link
                      :to="{
                        path: `/ban-tin/${item.id}`,
                    }"
                    >
                      <div class="">
                        <div class="img-news">
                          <img
                              v-if="item.fileImage"
                              class="img-fluid"
                              :src="item.fileImage.urlFile"
                              alt="Post Image"
                          />
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-md-6 card-news-sub">
                    <div class="content-news">
                      <router-link
                        :to="{
                          path: `/ban-tin/${item.id}`,
                      }"
                      >
                        <div class="title-news mb-2">
                          <p>
                            {{ item.name }}
                          </p>
                        </div>
                        <div class="decs-news">
                          {{ item.describe }}
                        </div>
                        <div style="float: right; margin-top: 10px;">
                          <b-button
                              class="btn-chitiet"
                              style="font-size: 14px;"
                          >
                            Xem thêm
                          </b-button>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </template>
  <script>
  import AOS from "aos";
  import "aos/dist/aos.css";

  import Flickity from 'vue-flickity';
  import {Carousel, Pagination, Slide} from "vue3-carousel";
  import 'vue3-carousel/dist/carousel.css'
  export default {
    components: {
      Flickity,
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {

        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          // 700px and up
          576: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          768: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          // 1024 and up
          992: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
        },
        flickityOptions: {
          initialIndex: 0,
          initialSlides: 4,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          autoPlay: 4000

          // any options from Flickity can be used
        },
        list: [],
        first: {},
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
      this.getData();
    },
    computed: {
    },
    methods: {
      async getData() {
        let params = {
            start: 0,
            limit: 4,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("newsStore/getPagingFeaturedNews", params ).then(res => {
          if (res != null && res.code ===0)
          {
            this.list = res.data;
          }

        });
      },

      next() {
        this.$refs.flickity.next();
      },

      previous() {
        this.$refs.flickity.previous();
      },
      toggleFavorite(item) {
        item.favorite = !item.favorite;
      },
    },
  };
  </script>

  <style>
  .list-card-nk{
    padding: 10px;
  }
  .flickity-viewport{
    height: 100% !important;
  }

  .img-library{
    height: 300px;
  }

  .img-library img{
    height: 300px;
    border-radius: 10px;
  }
  .card-img{
      height: 300px;
      padding: 10px 10px 0 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }

  .card-img div img{
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }

  .card-img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }

  .box-text{
      padding: 0px 10px
  }

  .box-info{
    height: 130px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .card-name-sen{
    position: absolute;
    background-color: #d4a04c;
    padding: 5px;
    color: #fff;
    margin: 0;
    top: 269px;
    left: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: bold;
    font-size: clamp(0.8125rem, 0.7679rem + 0.1429vw, 0.875rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .decs-cate-sen{
      font-size: 14px;
      text-align: justify;
      color: #000;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }

  .btn-detail{
      border: 2px solid #d4a04c !important;
      background-color:#fff !important;
      color: #d4a04c !important;
      border: none;
      border-radius: 5px;
      font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem) !important;
  }

  .btn-detail:hover{
      background-color: #d4a04c !important;
      color: #fff !important;
  }

  .card-price{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-price span{
    background-color: #CCFBF1;
    color: #14B8A6;
    font-weight: bold;
    padding: 5px;
    font-size: clamp(0.625rem, 0.4911rem + 0.4286vw, 0.8125rem);
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  </style>

