const  getJson = (item) => {
  return {
    id: item.id,
    name: item.name,
    path: item.path,
    icon: item.icon,
    parentId: item.parentId,
    sort: item.sort,
    isMenuCitizen : item.isMenuCitizen ,
    isFeaturedNews : item.isFeaturedNews,
    isMenuNew : item.isMenuNew ,
    count: item.count,

  };
};

const sendJson = (item) => {
  return {
    id: item.id,
    name: item.name,
    path: item.path,
    icon: item.icon,
    parentId: item.parentId,
    sort: item.sort,
    isMenuCitizen : item.isMenuCitizen ,
    isMenuFooter : item.isMenuFooter ,
    isMenuNew : item.isMenuNew ,
    isFeaturedNews : item.isFeaturedNews,
    label: item.label,
    count: item.count,
  };
};

const baseJson = () => {
  return {
    id: null,
    name: null,
    path: null,
    icon: null,
    parentId: null,
    sort: 0,
    isMenuCitizen : false,
    isMenuNew : false,
    isFeaturedNews : false,
    count: 0,
  };
};

export const menuCongDanModel = {
  sendJson , getJson, baseJson,
};
